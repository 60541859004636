* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins';
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins/Poppins-Light.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}