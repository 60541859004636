@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400;1,500&display=swap');

body {
  background: #eff1f7 !important;
  font-family: 'Poppins', sans-serif;
}
/* .bodyClassContractor {
  background: #F8F5FF !important;
  font-family: 'Poppins', sans-serif;
} */

/* .bodyClassHomeOwner {
  background: #EFF1F7 !important;
  font-family: 'Poppins', sans-serif;
} */

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 64px);
}

.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}

.layout-container {
  background: #fff;
  padding: 18px;
}

.layout-container-quote {
  border: 1px solid #e4daff;
  margin-top: 20px;
  padding: 20px 20px 40px 20px;
  border-radius: 10px;
}

.layout-container-quote .css-8atqhb .css-19kzrtu {
  padding: 0px !important;
}

.coming-soon {
  width: 100vw;
  height: 100vh;
}

/* Auth Screen */
.public_logo {
  position: absolute;
  right: 24px;
  top: 24px;
}

.public_logo_vibras {
  display: block;
  margin: 0 auto;
}

.hello_text {
  text-align: center;
  font-weight: 500;
  font-size: 24px;
  padding: 3% 0px;
}

.google_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  background: #fff;
  padding: 12px;
  margin: 0px 15% 15px 15%;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
}

.google_btn > img {
  height: 20px;
  width: 20px;
}

.invite_or_Option {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #a5a6af;
  gap: 5px;
}

.invite_or_Option .horizontal-line {
  width: 25%;
}

.or_Option {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #a5a6af;
  gap: 5px;
  padding-bottom: 3%;
}

.or_Option .horizontal-line {
  width: 25%;
}

.FieldName {
  padding-bottom: 5px;
  color: #a5a6af;
}

.emailFieldName {
  padding-bottom: 5px;
  color: #4b4d5f;
}

.emailFieldName > span {
  margin-left: 8px;
  color: #9447f8;
}

.authBtn {
  border-radius: 20px !important;
  text-transform: capitalize !important;
  font-size: 18px !important;
  background-image: linear-gradient(to right, #a6afff, #bca5ff) !important;
  padding: 12px 22px !important;
}

.register-form
  .MuiGrid-container
  .MuiGrid-item
  > form
  > .MuiGrid-container
  .MuiGrid-item
  .MuiFormControl-root
  .MuiInputBase-root {
  background: #fff;
  border-color: #e0e0e0 !important;
  border-radius: 20px;
}

.register-form
  .MuiGrid-container
  .MuiGrid-item
  > form
  > .MuiGrid-container
  .MuiGrid-item
  .MuiFormControl-root
  .MuiInputBase-root
  .MuiInputAdornment-root
  > svg {
  fill: #6b77e1;
}

.register-form
  .MuiGrid-container
  .MuiGrid-item
  > form
  > .MuiGrid-container
  .MuiGrid-item
  .MuiFormControl-root
  .MuiInputBase-root
  > fieldset {
  border-color: #e0e0e0 !important;
  border-radius: 20px;
}

.register-form
  .MuiGrid-container
  .MuiGrid-item
  > form
  > .MuiGrid-container
  .MuiGrid-item
  .MuiFormControl-root
  .MuiInputBase-root
  > input {
  background-color: #fff;
  border-color: #e0e0e0 !important;
  border-radius: 20px;
}

.invitePhoneNumber .react-tel-input .flag-dropdown {
  border-radius: 10px 0px 0px 10px !important;
}

.flag-dropdown {
  border-radius: 20px 0 0 20px !important;
  background-color: #fff !important;
  border-color: #e0e0e0 !important;
}

.react-tel-input .selected-flag {
  width: 40px !important;
  border-radius: 20px !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  border-color: #e0e0e0 !important;
  border-radius: 20px !important;
}

/* --login */

.login-form
  .MuiGrid-container
  .MuiGrid-item
  > form
  > .MuiGrid-container
  .MuiGrid-item
  .MuiFormControl-root
  .MuiInputBase-root {
  background: #fff;
  border-color: #e0e0e0 !important;
  border-radius: 20px !important;
}

.login-form
  .MuiGrid-container
  .MuiGrid-item
  > form
  > .MuiGrid-container
  .MuiGrid-item
  .MuiFormControl-root
  .MuiInputBase-root
  .MuiInputAdornment-root
  > svg {
  fill: #6b77e1;
}

.login-form
  .MuiGrid-container
  .MuiGrid-item
  > form
  > .MuiGrid-container
  .MuiGrid-item
  .MuiFormControl-root
  .MuiInputBase-root
  > fieldset {
  border-color: #e0e0e0 !important;
  border-radius: 20px !important;
}

.login-form
  .MuiGrid-container
  .MuiGrid-item
  > form
  > .MuiGrid-container
  .MuiGrid-item
  .MuiFormControl-root
  .MuiInputBase-root
  > input {
  background-color: #fff;
  border-color: #e0e0e0 !important;
  border-radius: 20px !important;
}

.register-img,
.login-img {
  width: 100%;
  object-fit: cover;
  height: 100vh;
}

.register-container,
.login-container {
  padding: 48px;
  height: 100vh;
  position: relative;
}

.register-form,
.login-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.profile-avatar {
  background: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  /* border: 1px solid #999; */
}

/* Users Screen */
.modal-form {
  padding-bottom: 4px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
}

.user-label {
  font-weight: 500;
  color: #121212;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px !important;
}

.user-label-title {
  font-weight: 600;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  font-size: 20px;
}

.user-physical-address {
  margin-bottom: 18px;
}

.drag-drop-thumbnail-small {
  object-fit: contain;
  padding: 2px;
  width: 42px;
  height: 42px;
}

.drag-drop-thumbnail {
  object-fit: cover;
  padding: 4px;
  width: 100%;
  height: 200px;
  max-width: 350px;
  display: block;
}

/* App Settings */
.custom-tag-input .MuiInputBase-root {
  padding-right: 14px;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 8px;
}

.notification_set_setting .css-dmmspl-MuiFormGroup-root > label {
  width: 45%;
}

.custom-tag-input
  .MuiInputBase-root
  .MuiButtonBase-root.MuiChip-root.MuiChip-filled {
  font-size: 14px;
}

.custom-tag-input input {
  width: auto;
  display: inline-block;
  vertical-align: middle;
  height: 30px;
  padding: 5px;
  position: relative;
  top: 2px;
  max-width: 181px;
  font-size: 15px;
}

.portal-logo {
  width: 200px;
  height: 40px;
}

.company-logo {
  width: 100%;
  height: 40px;
  object-fit: contain;
  cursor: pointer;
}

.attachment-img {
  width: 100%;
  height: 40px;
  object-fit: contain;
}

.client-hub-img {
  width: 100%;
  height: 200px;
  object-fit: contain;
}

.toast-success {
  color: #4a9e5c;
  min-height: 50px;
  border-radius: 10px;
  border: 1px solid #a5a6af;
  background: #ffffff;
  font-weight: 600;
}

.toast-error {
  background: #ffffff;
  color: #e80606;
  min-height: 50px;
  border-radius: 10px;
  border: 1px solid #a5a6af;
}

.toast-warning {
  background: #ffffff;
  color: #e80606;
  min-height: 50px;
  border-radius: 10px;
  border: 1px solid #a5a6af;
}

.toast-info {
  background: #ffffff;
  color: #6b77e1;
  min-height: 50px;
  border-radius: 10px;
  font-weight: 600;
  border: 1px solid #a5a6af;
}

/* Products */
.product-image {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

/* Products / Notes */
.allNotes_list > div > ul > li {
  border: 1px solid #e4dbfc;
  background: #e4dbfc;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  margin-top: 5px;
}

.allNotes_list > div > ul > li > div > div {
  background-color: #754be2;
}

/* Proposals */
.proposal-intro {
  display: flex;
  justify-content: space-between;
  padding: 8px 0px 8px 0px;
}

.proposal-item-header {
  padding: 0px 16px 16px 16px;
}

.proposal-item-container {
  background: #fff;
  padding-top: 16px;
  padding-bottom: 16px;
}

.proposal-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.proposal-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.proposal-card {
  position: relative;
  background: white;
  padding: 30px 30px 16px 16px;
  width: 100%;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: all 0.3s ease-in-out;
}

.template-card {
  position: relative;
  background: white;
  padding: 40px 0px 16px 16px;
  width: 100%;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: all 0.3s ease-in-out;
}

.proposal-temp {
  position: relative;
  background: white;
  padding: 20px;
  width: 200px;
  height: 124px;
  cursor: pointer;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  transition: all 0.3s ease-in-out;
}

.proposal-temp-value {
  color: gray;
  white-space: nowrap;
  text-transform: capitalize;
  max-width: 156px;
  flex-grow: 1;
}

.view-accordion {
  width: '100%';
  margin-top: 16px !important;
  margin-left: 16px !important;
  border: 1px solid #ccc;
  border-radius: 5px !important;
}

.template-card:hover {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(1.05);
}

.proposalName_with_arrowBackIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.proposal-title {
  color: #000;
  text-transform: capitalize;
}

.proposal-value {
  color: #555;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  max-width: 169px;
  flex-grow: 1;
  width: 100%;
  font-size: 15px;
}

.proposal-name {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin-right: 25px;
}

.proposal-icons {
  position: absolute;
  right: 9px;
  top: 7px;
}

.proposal-icons button.MuiButtonBase-root {
  padding: 0;
}

.proposal-icons button.MuiButtonBase-root svg {
  fill: #000;
}

.send_prop {
  background: #b7e6b7;
  color: #000;
  padding: 5px;
  width: 100%;
  text-transform: capitalize;
  border-radius: 8px;
  margin-left: 10px;
  font-weight: 600;
}

.progress_prop {
  background: #fed8b1;
  color: #000;
  padding: 5px;
  width: 100%;
  text-transform: capitalize;
  border-radius: 8px;
  margin-left: 10px;
}

.progress_prop p,
.send_prop p {
  color: #000 !important;
  font-size: 13px;
  letter-spacing: 0;
  font-weight: 600;
  line-height: 1.4;
}

.approved_prop {
  background: #7349e6;
  color: #ffffff;
  padding: 4px;
  width: 100%;
  text-transform: capitalize;
  border-radius: 8px;
  margin-left: 10px;
}

.approved_prop p {
  font-size: 13px;
  letter-spacing: 0;
  font-weight: 600;
  line-height: 1.4;
}

.rejected_prop {
  background: #ff887c;
  color: #ffffff;
  padding: 4px;
  width: 100%;
  text-transform: capitalize;
  border-radius: 8px;
  margin-left: 10px;
}

.rejected_prop p {
  font-size: 13px;
  letter-spacing: 0;
  font-weight: 600;
  line-height: 1.4;
}

.proposal-accepted-card {
  position: relative;
  background: #f4e5cb;
  padding: 30px 30px 23px 16px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.client-accepted-card,
.client-sent-card,
.client-rejected-card,
.accept-new-card {
  border-radius: 20px;
  width: auto;
  margin: 10px 3px;
  padding: 16px;
  border: 1px solid transparent;
  background: #fff !important;
  box-shadow: 0px 0px 20px 0px rgba(208, 208, 208, 0.25);
}

.client-accepted-card:hover,
.client-sent-card:hover,
.client-rejected-card:hover,
.accept-new-card:hover {
  background: #fff !important;
  border-color: #6b77e1;
  box-shadow: 0 8px 20px rgba(127, 82, 251, 0.25) !important;
  cursor: pointer;
}

.proposal-sent-card {
  position: relative;
  background: #fddce5;
  padding: 30px 30px 23px 16px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.active_item {
  background: #6f3bffc9 !important;
  color: #ffffff !important;
}

img.prop-pdf-icon {
  margin: 0 5px 0 0;
}

/* proposal-card */

.dummy-proposal-img,
.proposal-img {
  /* height: 202px; */
  /* width: 186px; */
  object-fit: cover;
  margin-top: 0;
  vertical-align: middle;
  height: 100%;
  width: 100%;
  border-radius: 8px;
}

.mar-img-cs {
  /* height: 100%; */
  max-width: 185px;
  min-width: 185px;
  min-height: 212px;
  max-height: 212px;
}

.pro_right_cont_cls_des {
  padding-left: 20px;
  padding-top: 25px;
  margin-top: 0 !important;
}

.accpt_card_pro_right_cont {
  padding-left: 20px;
  padding-top: 25px;
  margin: 0 0 0 10px;
}

.view_accpt_status {
  padding: 8px;
  width: 110px;
  text-align: center;
  margin: 0 auto;
}

.template-img {
  object-fit: cover;
  margin-top: 0;
  vertical-align: middle;
  height: 100%;
  width: 100%;
  border-radius: 8px;
}

.temp-img {
  height: 100px;
  width: 100px;
  object-fit: cover;
  margin-top: 7px;
}

.dummy-img {
  height: 70px;
  width: 70px;
  object-fit: cover;
  margin-top: 7px;
}

.from-now {
  color: #121212 !important;
  font-size: 13px;
  text-align: left;
  font-weight: 500;
  margin-bottom: 10px;
}

/* template-menu */
.css-2u02zd-MuiListItem-root {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

/* Documents */
.document-card {
  position: relative;
  background: white;
  padding: 8px;
  width: 100%;
  max-width: 245px;
  height: 200px;
  cursor: pointer;
  border: 1px solid #ddd;
  transition: all 0.3s ease-in-out;
}

.pdf_card {
  position: relative;
  background: white;
  padding: 8px;
  width: 100%;
  height: 200px;
  cursor: pointer;
  border: 1px solid #ddd;
  transition: all 0.3s ease-in-out;
}

.document-card:hover {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(1.05);
}

.document-img {
  width: 100%;
  max-width: 160px;
  object-fit: cover;
  margin-top: 11px;
}

.document-icons {
  position: absolute;
  right: 0px;
  top: 0px;
}

.document-value {
  color: gray;
  white-space: nowrap;
  overflow: hidden;
  word-break: break-word;
  text-transform: capitalize;
  max-width: 185px;
}

.setting_modal {
  position: relative;
  padding: 15px !important;
  width: 100%;
  border: 1px solid #e4dbfd;
  border-radius: 10px;
}

.setting_modal label.MuiFormControlLabel-root {
  margin-right: 0;
  margin-left: 0;
}

.setting_modal label {
  font-weight: 500;
  font-size: 15px;
  color: #121212;
}

.setting_modal label.MuiFormControlLabel-root span {
  padding: 0;
}
.sett_mdl_head {
  font-weight: 600;
}

.ql-container.ql-snow {
  height: 272px;
}

.add_border {
  border: 1px solid rgb(175, 7, 34);
  padding: 10px;
}

.divLoadingTab {
  height: 30%;
  width: 30%;
}

/*#sort, #filter{
  top: calc(10% - 0.5rem) !important;
}
*/
.sectionContent {
  font-weight: bold;
  font-size: 15px;
  margin: 0 0 0 5px;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.btn_addSection.css-ev51rv-MuiButtonBase-root-MuiButton-root {
  margin-top: 20px;
}

.add-quote {
  height: 100%;
  margin-left: 8px;
  max-height: 30px;
  min-width: 36px !important;
}

/* ACCPET-MY-PROPOSAL */

.list-proposal-right-panel {
  padding-top: 16px;
  margin: 16px;
  height: calc(100vh - 80px);
  overflow-y: scroll;
  padding-left: 0px;
  margin-right: 0px;
}

.accept-card-img {
  object-fit: cover;
  margin-top: 0;
  vertical-align: middle;
  height: 100%;
  width: 100%;
  border-radius: 8px;
}

.client_note {
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  max-width: 180px;
}

.list-client-note {
  border: 1px solid #e4dbfd;
  margin: 15px 0 15px 0px;
  border-radius: 10px;
  /* padding: 17px; */
}

.list-client-note .client-user-details {
  background: #e4dbfd;
  padding: 0 5px 10px 13px;
  border-radius: 10px;
  margin: 15px 0 15px 0;
}

.note-attachment {
  padding: 0 0 10px 20px;
  height: 100%;
  min-height: 117px;
}

/* client-details -> ClientSections */

svg.client-navicon {
  font-size: 30px;
  margin: 0 16px 0 0;
  width: 55px;
  height: 55px;
  padding: 8px;
  border-radius: 50px;
}

svg.edit-client-icon {
  margin: 0 16px 0 0;
  width: 34px;
  height: 34px;
  padding: 6px;
  border-radius: 50px;
}

svg.property-navicon {
  font-size: 29px;
  margin: 0 16px 0 0;
  width: 35px;
  height: 35px;
  padding: 8px;
  border-radius: 50px;
}

.tag-card {
  position: relative;
  background: #7349e6;
  padding: 0px 33px 5px 8px;
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: all 0.3s ease-in-out;
}

.tag-card:hover {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(1.05);
  /* opacity: 0.8; */
  background: #855ef1;
}

.tag-card svg {
  width: 20px !important;
  height: 20px !important;
}

.tag-icons {
  position: absolute;
  right: 0px;
  top: 0px;
}

.tag-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accept-card-value {
  color: gray;
}

.tag-value {
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  max-width: 149px;
  flex-grow: 1;
  font-size: 13px !important;
  font-weight: 400;
}

.accept-card-name {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  max-width: 146px;
  flex-grow: 1;
  width: 100%;
}

p.sale_status {
  background: antiquewhite;
  padding: 14px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 10px;
  margin: 10px;
  text-transform: capitalize;
}

p.left_data {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  max-width: 260px;
  flex-grow: 1;
  font-size: 18px;
  font-weight: 600;
}

p.email_data {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  flex-grow: 1 !important;
  text-transform: lowercase;
}

p.right_data {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  max-width: 140px;
  flex-grow: 1;
  font-size: 18px;
  font-weight: 600;
}

.lead_proposal {
  display: flex;
  margin: 10px 0 40px 0;
  background: #7349e6;
  cursor: pointer;
  color: #ffffff;
  width: 100%;
  max-width: 132px;
  overflow: hidden;
  border-radius: 6px;
  padding: 5px 14px 5px 5px;
  justify-content: center;
}

.lead_proposal p {
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex-grow: 1;
}

.hide-tab {
  display: none !important;
}

.view_balance {
  padding: 10px;
  background: #eee;
  margin: 10px 0 0 0;
  display: flex;
  justify-content: space-between;
}

p.card_status {
  margin-left: 8px;
  font-size: 13px;
  background: #eee;
  padding-right: 8px;
  padding-left: 8px;
  padding-bottom: 0px;
  height: 19px;
}

.card_details {
  width: 100%;
  height: 100%;
  max-width: 500px;
  display: flex;
}

.card_img {
  width: 100%;
  max-width: 400px;
  display: flex;
  margin: 20px 0 0 0;
}

.expiry_details {
  width: 100%;
  max-width: 100px;
  padding: 18px 10px 0 0;
  text-align: end;
}

.work_status {
  color: #832b15;
  width: 130px;
  background: #f7e3de;
  text-align: center;
  border-radius: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 3px;
  margin: 2px 0 0 0;
  text-transform: capitalize;
}

.quote_status {
  color: #28705f;
  width: 100px;
  background: #e2f3ef;
  text-align: center;
  border-radius: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 6px;
  margin: 2px 0 0 0;
  text-transform: capitalize;
}

.schedule_section {
  cursor: pointer;
}

span.schedule_action {
  color: #965d16;
  background-color: #fdefde;
  margin: 0 0 0 10px;
  padding: 5px;
  border-radius: 8px;
}

.input_box {
  margin: 33px 10px 10px 10px;
}

/* Map.css */

.map-container {
  width: 100%;
  height: 400px;
  border: 1px solid #ddd;
}

.map_btn {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  color: #7349e6;
  max-width: 273px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0px 10px 0 0;
}

p.overview_txt {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  max-width: 190px;
}

.email_txt {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 340px;
}

.name_txt {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 220px;
  text-transform: capitalize;
}

.email_btn {
  width: 100%;
  max-width: 140px;
  height: 100%;
  margin-top: 8px !important;
}

.file-txt {
  margin-left: 16px !important;
  margin-top: 16px !important;
  font-weight: 600;
  text-transform: lowercase;
  width: 100%;
  max-width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-btn :hover {
  transition: transform 0.6s;
  transform: scale(1.1);
  color: rgb(175, 7, 34);
}

.client_tax_section_select {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.client_tax_section_select .MuiBox-root {
  width: 80%;
}

.client_tax_section_select .MuiBox-root > div > div {
  height: 55px;
}

.typ-clnt-deals .client_tax_section_select .MuiButton-root {
  padding: 14px !important;
  border-radius: 10px !important;
}
/* client-details -> ClientSections */

/* appointments */

.fc-toolbar .fc-prev-button,
.fc-toolbar .fc-next-button {
  background-color: rgb(214 244 255) !important;
  margin: 0 4px 0 0;
  color: #7349e6 !important;
  border: none;
}

.fc-toolbar .fc-prev-button:hover,
.fc-toolbar .fc-next-button:hover {
  background-color: #855ef1 !important;
}

.fc-timeGridThreeDay-view .fc-event {
  background-color: transparent !important;
  width: 100%;
  max-width: 120px;
}

.fc-dayGridMonth-view .fc-event:hover {
  background-color: transparent !important;
}

.fc-timeGridThreeDay-view .fc-v-event {
  border: none;
  box-shadow: none;
}

.MuiAccordion-rounded.Mui-expanded.MuiAccordion-gutters.appt-filtr-main {
  box-shadow: rgb(153 209 229) 0px 5px 15px;
  border-radius: 10px;
  /* padding: 5px;  */
}

.MuiAccordion-rounded.MuiAccordion-gutters.appt-filtr-main {
  box-shadow: rgb(153 209 229) 0px 5px 15px;
  border-radius: 10px;
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 48px !important;
}

.google-board {
  box-shadow: 0 8px 20px rgba(127, 82, 251, 0.25);
  border-radius: 10px;
  padding: 18px;
  margin: 18px 0 0 0;
}

/* .fc .fc-button-group>.fc-button {
  flex: 1 1 auto;
  position: relative;
  background: rgb(214 244 255);
  margin: 0 4px 0 0;
  color: rgb(15, 102, 133);
  border: none;
} */

.calender-event {
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  max-width: 150px;
  margin: 0 10px;
  padding: 7px;
  border-radius: 6px;
  border-top: 2px solid #607d8b;
}

a.fc-daygrid-more-link.fc-more-link {
  margin: 9px;
}

.appt-title {
  text-transform: capitalize;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.fc .fc-bg-event {
  opacity: 1;
}

.modal-section {
  padding: 15px;
  border: 1px solid #e4dbfd;
  border-radius: 0px 0px 5px 5px;
}

.view-details {
  background: #e4dbfd;
  border: 1px solid #e4dbfd;
  border-radius: 5px 5px 0px 0px;
  width: 100%;
  margin: 16px 0 0 0;
  padding: 16px;
}

.edit-appt-details {
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  max-width: 600px;
  line-height: 2 !important;
  white-space: nowrap;
}

a.fc-event.fc-daygrid-event.fc-daygrid-dot-event:hover {
  background: none;
}

.fc .fc-popover {
  z-index: 9;
}

.fc-timegrid-more-link {
  width: 34px;
  display: flex;
  justify-content: center;
  height: 100%;
  min-height: 30px;
  align-items: center;
}

a.fc-event.my-event.fc-timegrid-event.fc-v-event {
  background: transparent !important;
}

.appt-req-header {
  text-align: center;
  /* border-bottom: 1px solid #ccc; */
  padding: 10px 0;
  position: fixed;
  top: 0;
  z-index: 9;
  background: #fff;
  width: 100%;
}

.appt-req-logo {
  width: 150px;
  cursor: pointer;
}

.appt-req-form {
  border: 1px solid #e4dbfd !important;
  border-radius: 20px;
  box-shadow: 0 8px 20px rgba(25, 155, 203, 0.25);
  background: #fff;
  padding: 25px !important;
  margin: 80px auto;
  width: calc(100% - 500px);
}

.appt-form-req-header {
  border-bottom: 1px solid #e4dbfd;
  padding: 0 0 15px 0;
  margin: 0 0 15px 0;
}

.appt-form-req-header p {
  font-size: 18px;
  font-weight: 600;
}

.appt-form-user-label {
  font-weight: 600;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  font-size: 16px;
}

button.side-tab-label {
  display: flex;
  justify-content: flex-start;
  margin: 0 0 0px 30px;
}

.client-view-appt-form {
  border: 1px solid #ddd !important;
  border-radius: 20px;
  background: #fff;
  padding: 25px !important;
  margin: 24px 16px;
  /* width: calc(100% - 24px); */
  overflow-y: scroll;
}

.view-appt-req-txt {
  display: flex;
  align-content: center;
  justify-content: center;
  font-size: 20;
}

.view-appt-req-txt p {
  font-size: 20px;
  font-weight: 600;
}

.appt-reqs-data {
  box-shadow: 0 8px 20px rgba(127, 82, 251, 0.25);
  border-radius: 10px;
  padding: 18px;
  margin: 5px 0 0 0;
  background: #fff;
}

.appt-reqs-count {
  background: #7349e6;
  color: #ffffff;
  height: 27px;
  width: 27px;
  display: flex;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 7px;
}

.appt-reqs-count p {
  font-size: 14px;
  font-weight: 500;
}

.appt-req-btn {
  box-shadow: 0 4px 10px rgba(127, 82, 251, 0.25);
  border-radius: 10px;
  align-items: center;
  border: 1px solid #e4dbfd !important;
}

.appt-reqs-data > .MuiBox-root {
  margin-bottom: 18px;
}

.appt-reqs-data button {
  min-width: 56px !important;
  padding: 10px !important;
  border-radius: 5px 0px 0px 5px !important;
  width: auto !important;
}

.appt-reqs-data button svg {
  width: 35px;
  height: 35px;
}

.appt-req-btn .MuiBox-root p.MuiTypography-root.req-head-title:first-child {
  text-align: left;
  font-weight: 600;
}

.appt-req-btn .MuiBox-root p.MuiTypography-root.req-head-title:last-child {
  text-align: left;
}

p.appt-req-user {
  font-weight: 600;
  text-transform: capitalize;
  margin: 0 0 0 4px;
}

.appt-req-btn .MuiBox-root {
  text-align: left;
  margin-top: 0;
  padding-right: 0;
}

.req-appt-app-header {
  width: 100%;
  max-width: 300px;
}

.edit-appt-app-header {
  width: 100%;
  max-width: 200px;
}

.appt-req-mssg-head {
  padding: 16px;
  border: 1px solid #e4dbfd;
  border-radius: 8px;
}

p.req-head-title {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  max-width: 125px;
  text-align: center;
}

.appt-filtr-head {
  background: #7349e6;
  color: #ffffff;
  padding: 10px;
  border-radius: 10px;
}

.res_stepperContainer {
  overflow-y: auto !important;
  overflow-x: auto !important;
  padding-bottom: 10px;
}
.sales_list_tb::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}
.register__rightPanel > div {
  height: 90% !important;
}
.register__rightPanel > div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
  color: #fff;
}
.register__rightPanel {
  height: 100% !important;
  overflow-y: auto;
  padding-inline: 8px;
}
.register__rightPanel > div {
  overflow: unset !important;
}
.register__rightPanel::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}
.register__rightPanel {
  height: 90% !important;
}
.register__rightPanel::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #6b78e1;
}
.client-accepted-card .accept-card-value,
.client-sent-card .accept-card-value {
  max-width: 100%;
  white-space: normal;
}
.overflowEllipse__twoLines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
.accept-new-card .accpt_card_pro_right_cont .accept-card-name {
  max-width: 100%;
  white-space: normal;
}
.accept-new-card > div > .accpt_card_pro_right_cont,
.client-accepted-card > div > .accpt_card_pro_right_cont,
.client-sent-card > div > .accpt_card_pro_right_cont,
.client-rejected-card > div > .accpt_card_pro_right_cont {
  max-width: calc(100% - 185px);
}
.invoice-list-card .MuiGrid-item > div > p {
  max-width: 100%;
  white-space: normal;
}

.sales_list_tb .accpt_prop_descp__box .accpt_prop_descp,
.myProposal__tableWithoutAction .accpt_prop_descp__box .accpt_prop_descp {
  white-space: normal;
  display: inline;
}

.sales_list_tb .accpt_prop_descp__box .hw-readMore,
.myProposal__tableWithoutAction .accpt_prop_descp__box .hw-readMore {
  padding-inline: 8px;
}

.accpt_card_pro_right_cont {
  padding-right: 16px;
}
.myProposal__tableWithoutAction table tr > td div {
  white-space: normal !important;
}
.myProposal__tableWithoutAction table tr > td:first-child{
  min-width: 50px !important;
}
.myProposal__tableWithoutAction table tr > td:not(:first-child){
  min-width: 100px !important;
}
.myProposal__tableWithoutAction table tr > td:nth-child(2),
.myProposal__tableWithoutAction table tr > td:nth-child(3) {
  min-width: 200px !important;
}

/* appointments */

/* Responsive CSS */
@media only screen and (max-width: 1600px) and (min-width: 1025px) {
  /* Auth Screens */
  .basic-full-screen .left-col {
    background: #6e3afe;
  }

  /* .basic-full-screen .left-col img {
    object-fit: contain !important;
  } */
}

@media only screen and (max-width: 1024px) {
  /* Auth Screens */
  .public_logo {
    position: absolute;
    right: 0;
    top: 24px;
    max-width: 150px;
    left: 0;
    margin: auto;
  }
  .register-container,
  .login-container {
    height: auto;
    padding: 90px 20px 40px 20px;
  }
}

@media only screen and (max-width: 992px) {
  .layout-container {
    background: #fff;
    padding: 12px;
  }
}

/* Accept Proposal */
.accept-proposal-header {
  text-align: center;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
  position: fixed;
  top: 0;
  z-index: 9;
  background: #fff;
  width: 100%;
}

.accpt_propsal_no_data {
  font-weight: 600 !important;
  font-size: 17px !important;
  color: #000 !important;
}

.accept-proposal-logo {
  width: 150px;
  cursor: pointer;
}

.accept-proposal-container {
  margin-top: 64px;
  height: calc(100vh - 64px);
}

.accept-proposal-sidenav {
  padding: 8px;
  height: calc(100vh - 80px);
  border-right: 1px solid #eee;
  overflow: hidden;
}

.accept-proposal-right-panel {
  padding: 8px;
  height: calc(100vh - 80px);
  overflow-y: scroll;
}

.acpt-card {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 24px;
  margin: 24px 0px;
}

.acpt-optn {
  background: #eee;
  border-radius: 6px;
  padding: 8px;
  font-size: 16px;
  cursor: pointer;
}

.acpt-logo-cnt {
  margin: 16px 0px;
}

.acpt-logo {
  /* width: 150px; */
  object-fit: cover;
  /* padding: 4px; */
  width: 100%;
  height: 128px;
  max-width: 136px;
  display: block;
  margin: auto;
  border-radius: 16px;
}

.acpt-prim {
  /* width: 150px; */
  object-fit: cover;
  /* padding: 4px; */
  width: 100%;
  height: 250px;
  max-width: 300px;
  display: block;
  margin: auto;
  border-radius: 16px;
}

.acpt-label {
  font-size: 20px;
  text-align: left;
  font-weight: 500;
  /* text-transform: capitalize; */
}

.acpt-title {
  /* margin: 16px 0px;
  text-transform: capitalize; */
  width: 100%;
}

.title-acpt {
  margin: 16px 0px;
  /* text-transform: capitalize; */
  width: 100%;
  border: 1px solid #ddd;
}

.acpt-txt {
  padding: 13px;
  margin: 0 0 0 15px;
}

.acpt-contact {
  background: #eee;
  border-radius: 5px;
  font-size: 18px;
  color: 121212;
  font-weight: 500;
  padding: 16px;
}

.acpt-header {
  display: flex;
  justify-content: space-between;
}

.acpt-header-title {
  font-size: 18px;
  border-bottom: 2px solid #eee;
  color: gray;
  /* margin-bottom: 8px; */
  text-align: center;
  margin-left: 41px;
}

.signature-canvas {
  border: 1px solid #eee;
  width: 100%;
  height: 400px;
  background: #eee;
}

.acpt-agree {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
  margin: 16px 0px;
}

/* Global */
.no-data-found {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  margin: 24px;
  color: gray;
}

.d-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-flex.img-Annot {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}

.d-flex-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.d-flex-label {
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-flex-3 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.d-flex-4 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  max-width: 700px;
}

.d-flex-5 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 700px;
}

.c-pointer {
  cursor: pointer;
}

.appt-close {
  background: #ffffff !important;
  color: #7349e6 !important;
  border-radius: 10px !important;
  padding: 4px;
  height: 36px !important;
  width: 100% !important;
  max-width: 36px;
}

.menu-appt {
  color: #555555 !important;
  border: 1px solid #e4dbfd !important;
  background: #ffffff !important;
}

.contained-btn {
  color: #ffffff !important;
  border: 1px solid #7349e6 !important;
  background: #7349e6 !important;
}

.outlined-btn {
  color: #555555 !important;
  border: 1px solid #f1edfa !important;
  background: #f1edfa !important;
}

.deal_title {
  font-size: 17px;
  color: #7349e6;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  max-width: 250px;
}

.deal-subtitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  max-width: 250px;
}

.error {
  font-size: 12px;
  color: #d32f2f;
  margin: 3px 14px 0px;
}

.main-stepper {
  margin: 30px 0 0 0;
}

.left-stepper {
  float: left;
  clear: left;
  margin: 0 0 45px 0;
}

.right-stepper {
  float: right;
  clear: right;
  margin: 0 0 45px 0;
}

.stepper {
  max-width: 1200px;
  width: 100%;
  /* margin: 0 auto; */
}

.step-txt {
  width: 100%;
  border-radius: 100%;
}

/* Sales Pipeline */
.sales-view-active {
  color: #fff;
  background-color: #000;
}

::-webkit-scrollbar {
  width: 0em;
}

::-webkit-scrollbar-thumb {
  background-color: #c9c1c1;
  border-radius: 0.2em;
}

::-moz-scrollbar {
  width: 0em;
}

::-moz-scrollbar-thumb {
  background-color: #c9c1c1;
  border-radius: 0.2em;
}

.scroll-board {
  overflow-y: scroll;
  height: calc(100vh - 250px);
  background: none;
  margin: 4px;
}

.page-container {
  overflow-x: scroll;
  width: calc(100vw - 300px);
  height: calc(100vh - 210px);
}

.text-ellipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.close-icon {
  position: absolute;
  right: -8px;
  top: -8px;
  border: 1px solid #ddd;
  border-radius: 50px;
  display: flex;
  background: rgb(233, 117, 117);
  color: #fff;
  padding: 4px;
  cursor: pointer;
  z-index: 1;
}

.close-icon:hover {
  background: #000;
  color: white;
}

.drawer-header {
  position: fixed;
  padding: 16px;
  top: 0;
  width: 800px;
  z-index: 10;
}

.drawer-footer {
  position: fixed;
  padding: 16px;
  bottom: 0;
  width: 800px;
  z-index: 10;
}

.selectContent_containter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;
}

.contentBox {
  border: 1px solid #e4dbfc;
  padding: 5px 12px;
  border-radius: 10px;
  width: 95%;
}

.error_timePicker.MuiBox-root
  .MuiFormControl-root.MuiFormControl-fullWidth
  .Mui-error.MuiInputBase-fullWidth {
  border: 1px solid red;
}

.quickActions_proposals {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

/*---------------jobs-pipeline-css----*/

.proposalsJobs_info {
  line-height: 2.1;
}

.project_status {
  flex-direction: column;
}

.project_status_edit {
  width: 100%;
  gap: 15px;
  margin-bottom: 1.25rem;
}

.project_status_edit img.sc_icon {
  width: 39px;
  max-height: 39px;
}

.project_status_edit svg {
  width: 24px;
  height: 24px;
  fill: #5996ce;
}

.job-status_cls .text-ellipse {
  font-size: 17px;
  color: #000;
  font-weight: 600;
  width: 100% !important;
  line-height: 1.5;
  margin-bottom: 4px;
}

.job_cm_st {
  font-size: 14px !important;
  color: #555555;
  font-weight: 500;
  letter-spacing: 0;
}

.job-status_cls {
  margin: 12px !important;
  padding: 20px !important;
  border-radius: 25px !important;
  background: #dff0ff !important;
  margin-bottom: 5px !important;
  box-shadow: 0 11px 14px rgba(25, 155, 203, 0.25);
  position: relative;
  z-index: 1;
  padding-bottom: 30px !important;
}

.job-status_cls .MuiBox-root {
  width: 100%;
  position: relative;
}

.recurr_status {
  padding: 8px;
  border: 1px solid #e4daff;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}

.tax_delete_info {
  display: none;
}

.job_type_duration {
  border: 1px solid #e4daff;
  border-radius: 10px;
  padding: 10px;
  display: flex;
}

.recurr_type_job {
  border: 1px solid #e4daff;
  border-radius: 10px;
  padding: 10px;
}

button.repeat_week {
  border-radius: 0 !important;
  margin: 0 1px 0 0;
  border: 1px solid #e4daff;
}

.job_visit_grid {
  display: flex;
  justify-content: space-between;
  border: 1px solid #e4daff;
  padding: 10px;
  border-radius: 10px;
}

.custom_freq_interval {
  border: 1px solid #e4daff;
  border-radius: 10px;
  margin: 32px 0 0px 0px;
  padding: 16px;
  margin: 17px 0 0 17px;
}

.MuiBox-root .MuiBox-root:nth-of-type(2) .job-status_cls {
  background: #fef3e1 !important;
  box-shadow: 0 11px 14px rgba(197, 143, 54, 0.25);
}

.MuiBox-root .MuiBox-root:nth-of-type(2) .job-status_cls:before {
  background: #fffcf8 !important;
  box-shadow: 0 11px 14px rgba(197, 143, 54, 0.25);
}

.MuiBox-root .MuiBox-root:nth-of-type(3) .job-status_cls {
  background: #ddf8dd !important;
  box-shadow: 0 11px 14px rgba(82, 158, 79, 0.25);
}

.MuiBox-root .MuiBox-root:nth-of-type(3) .job-status_cls:before {
  background: #fafffa !important;
  box-shadow: 0 11px 14px rgba(82, 158, 79, 0.25);
}

.MuiBox-root .MuiBox-root:nth-of-type(4) .job-status_cls {
  background: #efe8ff !important;
  box-shadow: 0 11px 14px rgba(152, 129, 205, 0.25);
}

.MuiBox-root .MuiBox-root:nth-of-type(4) .job-status_cls:before {
  background: #faf8ff !important;
  box-shadow: 0 11px 14px rgba(152, 129, 205, 0.25);
}

.job-status_cls:before {
  content: '';
  display: block;
  width: 100%;
  /* height: 100%; */
  position: absolute;
  top: 0;
  left: 0;
  background: #f9fcff;
  z-index: 0;
  border-radius: 25px;
  bottom: 10px;
  box-shadow: 0 11px 14px rgba(25, 155, 203, 0.25);
}

.MuiBox-root .MuiBox-root:nth-of-type(2) .project_status_edit svg {
  fill: #efb556;
}

.MuiBox-root .MuiBox-root:nth-of-type(3) .project_status_edit svg {
  fill: #74cb70;
}

.MuiBox-root .MuiBox-root:nth-of-type(4) .project_status_edit svg {
  fill: #a484ed;
}

main {
  background: #eff1f7 !important;
}

.job-status_cls .MuiBox-root {
  width: 100%;
}

.total_get_cs {
  background: #fff;
  padding: 15px 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 13px rgba(127, 82, 251, 0.15),
    rgba(127, 82, 251, 0) 0px 0px 10px inset;
  margin-bottom: 30px !important;
  border: 1px solid #e4dbfd;
}

.cls-cnt {
  gap: 35px;
  align-items: center;
}

.tile_tt {
  font-size: 16px !important;
  font-weight: 600;
  color: #000;
}

.total_tt {
  font-size: 15px !important;
  color: #555555 !important;
  font-weight: 500 !important;
  padding-top: 2px;
}

.box-pjt-cs .box-pjt-cs-inner {
  float: left;
  /* width: 20%; */
}

.box-pjt-cs {
  flex-wrap: wrap;
}

.box-pjt-cs .box-pjt-cs-inner .scroll-board .droppable {
  width: 100% !important;
  /* padding: 15px !important; */
}

.box-pjt-cs .box-pjt-cs-inner .scroll-board .droppable > .MuiBox-root {
  border-radius: 20px !important;
  box-shadow: 0 5px 10px rgba(25, 155, 203, 0.1) !important;
  padding: 15px !important;
}

.process_sts_cs .close-icon.box_drg {
  position: relative !important;
  right: 0 !important;
  top: 0 !important;
  /* padding: 0; */
  background: none !important;
  border: none !important;
}

.process_sts_cs > .MuiBox-root {
  text-transform: uppercase;
  color: #000 !important;
  font-size: 13px;
  letter-spacing: 0.5px;
}

.cols_sts_bdy > .MuiBox-root {
  width: 100%;
}

.cols_sts_bdy {
  width: 100%;
}

.deal_count_in {
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
  margin-top: 0.25rem;
}

.de_job_en {
  font-size: 15px !important;
  color: #555 !important;
}

.de_job_prc {
  font-size: 15px;
}

.cols_sts_bdy .lead_proposal {
  background: none !important;
  margin-bottom: 20px;
}

.cols_sts_bdy .lead_proposal {
  background: none !important;
  margin-bottom: 0;
  color: #7349e6;
  padding: 0;
  margin-top: 0;
}

.cols_sts_bdy .lead_proposal p {
  text-decoration: underline;
  color: #7349e6 !important;
  font-weight: 500;
  font-size: 15px;
}

.jb_st_dt {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  margin: 20px 0 5px 0;
}

.time_get_cs {
  font-size: 14px;
  color: #555555 !important;
  align-items: center;
}

.Db_hd {
  border-right: 1px solid #7349e6 !important;
}

.Db_hd + hr.MuiDivider-root {
  border-color: #fff;
}

.Db_hd_homeOwner + hr.MuiDivider-root {
  border-color: #fff;
}

.side-bar_main li span,
.tgle-bar_main li span {
  color: #fff;
  width: 100%;
  max-width: 146px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 17px;
  font-weight: 500;
}

.side-bar_main li.list-active {
  background: none !important;
}

.side-bar_main li.list-active,
.tgle-bar_main li.list-active {
  background: none !important;
  position: relative;
}
.side-bar_main li.list-active span,
.tgle-bar_main li.list-active span {
  color: #000;
  font-weight: 600;
}
.MuiDrawer-paper {
  border-right: inherit !important;
}

.side-bar_main {
  padding-left: 15px !important;
}

.tgle-bar_main {
  padding-left: 0px !important;
}

.side-bar_main li > .MuiButtonBase-root.MuiListItemButton-root,
.tgle-bar_main li > .MuiButtonBase-root.MuiListItemButton-root {
  padding-top: 12px;
  padding-bottom: 14px;
  position: relative;
  background: none !important;
}

.side-bar_main li.list-active > .MuiButtonBase-root.MuiListItemButton-root,
.tgle-bar_main li.list-active > .MuiButtonBase-root.MuiListItemButton-root {
  background: #f8f5ff !important;
  border-radius: 30px 0 0px 30px;
}

.side-bar_main li,
.tgle-bar_main li {
  position: relative;
}

.side-bar_main li > .MuiButtonBase-root.MuiListItemButton-root:before,
.tgle-bar_main li > .MuiButtonBase-root.MuiListItemButton-root:before {
  content: '';
  display: block;
  width: 30px;
  height: 30px;
  position: absolute;
  top: -30px;
  right: 0;
  background: #7349e6;
  border-radius: 0 0 30px 0;
  z-index: 0;
  opacity: 0;
  visibility: hidden;
}

.side-bar_main li:before,
.tgle-bar_main li:before {
  content: '';
  display: block;
  width: 30px;
  height: 30px;
  position: absolute;
  top: -25px;
  right: 0;
  background: #f8f5ff;
  border-radius: 0;
  opacity: 0;
  visibility: hidden;
}

.side-bar_main li:after,
.tgle-bar_main li:after {
  content: '';
  display: block;
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: -25px;
  right: 0;
  background: #f8f5ff;
  border-radius: 0;
  opacity: 0;
  visibility: hidden;
}

.side-bar_main li > .MuiButtonBase-root.MuiListItemButton-root:after,
.tgle-bar_main li > .MuiButtonBase-root.MuiListItemButton-root:after {
  content: '';
  display: block;
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: -30px;
  right: 0;
  background: #7349e6;
  border-radius: 0 30px 0px 0;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}

.side-bar_main
  li.list-active
  > .MuiButtonBase-root.MuiListItemButton-root:before,
.side-bar_main
  li.list-active
  > .MuiButtonBase-root.MuiListItemButton-root:after,
.tgle-bar_main
  li.list-active
  > .MuiButtonBase-root.MuiListItemButton-root:before,
.tgle-bar_main
  li.list-active
  > .MuiButtonBase-root.MuiListItemButton-root:after {
  opacity: 1;
  visibility: visible;
}

.side-bar_main li.list-active:before,
.side-bar_main li.list-active:after,
.tgle-bar_main li.list-active:before,
.tgle-bar_main li.list-active:after {
  opacity: 1;
  visibility: visible;
}

.side-bar_main,
.tgle-bar_main {
  padding-top: 30px !important;
}

/* HOME OWNER SIDE BAR */

.side-bar_main_homeOwner li.list-active span,
.tgle-bar_main_homeOwner li.list-active span {
  color: #fff;
  width: 100%;
  max-width: 146px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 500;
}

.side-bar_main_homeOwner li.non-active span,
.tgle-bar_main_homeOwner li.non-active span {
  color: #000;
  width: 100%;
  max-width: 146px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 500;
}

.tgle-bar_main_homeOwner {
  padding: 0px 12px !important;
  height: 100vh;
}

.side-bar_main_homeOwner li.list-active {
  background: none !important;
}

.side-bar_main_homeOwner li.list-active,
.tgle-bar_main_homeOwner li.list-active {
  background: none !important;
  position: relative;
}

/* .side-bar_main_homeOwner li.list-active span,
.tgle-bar_main_homeOwner li.list-active span {
  color: #000;
  font-weight: 600;
} */

.MuiDrawer-paper {
  border-right: inherit !important;
}

.side-bar_main_homeOwner {
  padding-left: 15px !important;
  padding-right: 15px !important;
  height: 100vh;
}

.tgle-bar_main {
  padding-left: 0px !important;
}

.side-bar_main_homeOwner li > .MuiButtonBase-root.MuiListItemButton-root,
.tgle-bar_main_homeOwner li > .MuiButtonBase-root.MuiListItemButton-root {
  position: relative;
  background: none !important;
}

.side-bar_main_homeOwner li.non-active img,
.tgle-bar_main_homeOwner li.non-active img {
  width: 100%;
  max-width: 20px;
  filter: brightness(0);
}

.side-bar_main_homeOwner li.list-active img,
.tgle-bar_main_homeOwner li.list-active img {
  width: 100%;
  max-width: 20px;
  filter: invert(1);
}

.side-bar_main_homeOwner
  li.list-active
  > .MuiButtonBase-root.MuiListItemButton-root,
.tgle-bar_main_homeOwner
  li.list-active
  > .MuiButtonBase-root.MuiListItemButton-root {
  background-image: linear-gradient(to right, #a6afff, #bca5ff) !important;
  border-radius: 10px;
}

.side-bar_main_homeOwner li,
.tgle-bar_main_homeOwner li {
  position: relative;
}

/*----user-apge-css----*/

.layout-container.user_main_container {
  background: none;
}

.serach_reate_area .MuiInputBase-root {
  border: 1px solid #e4dbfd !important;
  box-shadow: 0 6px 16px rgba(127, 82, 251, 0.15);
  border-radius: 10px !important;
  width: 100%;
  max-width: 600px;
}

.serach_reate_area .MuiInputBase-root input[type='text'] {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  padding-right: 14px !important;
  font-size: 15px;
  font-weight: 500;
  color: #000;
}

.serach_reate_area svg {
  fill: #818181;
}

.ct-user button.MuiButtonBase-root {
  background: #7349e6;
  box-shadow: none !important;
  font-size: 15px;
  text-transform: capitalize !important;
  font-weight: 500;
  padding: 9px 18px !important;
  border-radius: 8px !important;
}

.ct-user button.MuiButtonBase-root:hover {
  background: #855ef1;
}

/*---leads-apge-css---*/

.tt_add_bttm .MuiGrid-root.MuiGrid-container {
  align-items: center;
}

.tt_add_bttm button.MuiButtonBase-root {
  box-shadow: none;
  text-transform: capitalize;
  border-radius: 8px;
}

.tt_add_bttm button.MuiButtonBase-root + button.MuiButtonBase-root {
  margin-right: 0 !important;
}

.layout-container.user_main_container .MuiBox-root {
  background: none !important;
}

.layout-container.user_main_container .pagination {
  margin-left: 0;
}

.pagination div {
  border-radius: 7px;
  font-weight: 500;
  font-size: 15px;
  border: 1px solid #7349e6;
}

.pagination {
  justify-content: center !important;
}

.layout-container {
  background: none;
}

/*---------------client-page-css----*/

button.MuiButtonBase-root {
  box-shadow: none !important;
}

.clinet_search_bx {
  border: 1px solid #e0e0e0 !important;
  border-radius: 10px !important;
  width: 100%;
  background: #ffffff;
  padding: 3px;
}

.top_bar_lyt_cs {
  align-items: center;
}

.top_bar_lyt_cs button.MuiButtonBase-root {
  background: #7349e6;
  box-shadow: none !important;
  font-size: 16px;
  text-transform: capitalize !important;
  font-weight: 400;
  padding: 9px 22px;
  border-radius: 6px !important;
}

.top_bar_lyt_cs button.MuiButtonBase-root:hover {
  background: #855ef1;
}

.ct-user button.MuiButtonBase-root {
  font-weight: 400;
}

.top_bar_lyt_cs .MuiBox-root div#actions {
  border: none;
  padding-top: 11px;
  padding-bottom: 11px;
}

.top_bar_lyt_cs .MuiBox-root label[data-shrink='false'] {
  color: #7349e6;
  transform: translate(14px, 11px) scale(1);
  font-size: 16px;
  font-weight: 400;
}

.top_bar_lyt_cs .MuiBox-root .MuiFormControl-root .MuiInputBase-root {
  background: #fff;
}

.top_bar_lyt_cs .MuiBox-root .MuiFormControl-root .MuiInputBase-root fieldset {
  border-color: #7349e6 !important;
}

.top_bar_lyt_cs .MuiBox-root .MuiFormControl-root .MuiInputBase-root svg {
  fill: #7349e6 !important;
}

.bx-with-bg {
  width: 100%;
  background: #fff;
  padding: 20px;
  box-shadow: 0 4px 14px rgba(127, 82, 251, 0.15);
  border-radius: 10px;
}

.bx-with-bg .MuiGrid-item.MuiGrid-grid-md-8 .MuiInputBase-root {
  border: 1px solid #e4dbfd !important;
  box-shadow: 0 4px 14px rgba(127, 82, 251, 0.15);
  border-radius: 10px !important;
  width: 100%;
  max-width: 600px;
  /* background: #f00; */
}

.bx-with-bg
  .MuiGrid-item.MuiGrid-grid-md-8
  .MuiInputBase-root
  input[type='text'] {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  padding-right: 14px !important;
  font-size: 15px;
  font-weight: 500;
  color: #000;
}

.client_data-tble table tr td span {
  font-weight: 500 !important;
  color: #555555;
}

.bx-with-bg .MuiFormControl-root label {
  color: #7349e6 !important;
  font-weight: 500;
}

.bx-with-bg .MuiFormControl-root svg {
  fill: #7349e6 !important;
}

.bx-with-bg .MuiFormControl-root fieldset {
  border-color: #7349e6 !important;
}

.bx-with-bg .MuiFormControl-root #sort,
.bx-with-bg .MuiFormControl-root #filter {
  font-weight: 400;
  color: #7349e6 !important;
  font-size: 14px;
}

/*---client-detail-page-csss---*/

.client_tax_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.client_tax_section .MuiBox-root {
  width: 75%;
}

.client_tax_section .MuiBox-root > div > div {
  height: 55px;
}

.client-dts-cs {
  padding: 18px 18px 0 18px;
  background: #fff;
  border: 1px solid #e4dbfd !important;
  border-radius: 20px;
  box-shadow: 0 8px 20px #7f52fb40;
}

.dummy_client_img img {
  height: 140px;
  width: 140px;
}

.client-dts-cs .MuiGrid-root.MuiGrid-container {
  align-items: center;
}

.client-nm-im p {
  font-size: 26px !important;
}

.client-nm-im svg.client-navicon {
  width: 45px;
  height: 45px;
  /* background: #FFEDCC; */
  /* fill: #000; */
}

.client-nm-im,
.client-ntf-top {
  align-items: center;
}

.client-ntf-top button.MuiButtonBase-root.btn-mail-client {
  border-radius: 8px;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 400;
  /* padding: 8px 20px; */
}

button.client_btn_edit {
  border-radius: 8px;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 400;
}

.client-ntf-top button.MuiButtonBase-root.btn-mail-client svg,
.client-ntf-top button.MuiButtonBase-root.MuiButton-root.client_btn_edit svg {
  width: 22px;
  height: 22px;
}

.client-ntf-top div[role='group'] button {
  background: #fff;
  height: 45px;
  text-transform: capitalize;
  font-size: 15px;
}

.client-ntf-top div[role='group'] button button {
  height: auto;
  background: none;
}

.ri_box_cols_client {
  background: #fff;
  border: 1px solid #e4dbfd !important;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 8px 20px rgba(127, 82, 251, 0.25);
  margin-bottom: 22px;
  margin-left: 10px;
}

.cl_in-new .MuiBox-root {
  font-size: 15px;
  color: #555555;
  font-weight: 500;
}

.cl_in-new .MuiBox-root svg {
  fill: #7349e6 !important;
  width: 22px;
  height: 22px;
}

.cl_tgas .MuiFormControl-root fieldset {
  border: 1px solid #e4dbfd !important;
  border-right: none !important;
  border-radius: 8px 0 0px 8px !important;
}

.cl_tgas .MuiFormControl-root label[data-shrink='false'] {
  font-size: 15px;
  color: #555555;
  font-weight: 500;
  -webkit-transform: translate(14px, 11px) scale(1);
  -moz-transform: translate(14px, 11px) scale(1);
  -ms-transform: translate(14px, 11px) scale(1);
  transform: translate(14px, 11px) scale(1);
}

.cl_tgas .MuiFormControl-root input[type='text'] {
  font-size: 15px;
  color: #555;
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 10px;
}

.cl_tgas button.MuiButtonBase-root {
  margin: 0;
  border-radius: 0 10px 10px 0 !important;
  padding: 10px 20px 9px 15px !important;
  width: auto;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 15px;
  box-shadow: 0 6px 16px rgba(127, 82, 251, 0.15) !important;
  height: 42px !important;
}

.tag-icons button.MuiButtonBase-root {
  margin: 0;
  border-radius: 0 10px 10px 0 !important;
  padding: 6px 6px 6px 6px !important;
  width: auto;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 15px;
  height: 33px !important;
}

.cl_tgas button.MuiButtonBase-root svg {
  width: 22px;
  height: 22px;
  margin-right: 3px;
}

.cl_tgas .MuiInputBase-root {
  border-radius: 10px 0 0px 10px !important;
  box-shadow: 0 6px 16px rgba(127, 82, 251, 0.15);
}

.bt_ty_tx {
  padding: 5px 0px 0 20px;
  width: 100%;
}

.bt_ty_tx p {
  font-size: 14px;
  color: #121212;
  font-weight: 500;
}

.billing_his_client .MuiBox-root .MuiFormControl-root {
  font-size: 14px;
  color: #121212;
  font-weight: 500;
}

.billing_his_client .MuiBox-root {
  border-radius: 8px !important;
  align-items: center;
  gap: 10px;
}

.billing_his_client
  .MuiBox-root
  .MuiFormControl-root
  label[data-shrink='false'] {
  font-size: 15px;
  font-weight: 400;
  color: #fff;
}

.billing_his_client .MuiBox-root .MuiFormControl-root div[role='button'] {
  background: #7349e6;
  border-radius: 8px;
}

.billing_his_client .MuiBox-root .MuiFormControl-root fieldset {
  border-color: #7349e6;
}

.billing_his_client .MuiBox-root .MuiInputBase-root svg {
  fill: #fff !important;
}

.MuiGrid-grid-md-12.date_pym_new {
  border: 1px solid #e4dbfd;
  padding: 15px;
  margin: 7px 0px 7px 15px;
  border-radius: 10px;
}

.billing_his_client .MuiBox-root.bt-sp {
  margin-bottom: 15px;
  border-radius: 0 !important;
  margin-top: 5px;
}

.billing_his_client .date_pym_cls.MuiBox-root {
  border-radius: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0px !important;
  font-size: 15px;
  color: #121212;
  font-weight: 500;
}

.billing_his_client .date_pym_cls.MuiBox-root svg {
  fill: #7349e6 !important;
}

.payment_oder_ns {
  padding-left: 35px;
}

.payment_oder_ns.MuiBox-root .MuiTypography-root {
  color: #555;
  font-size: 14px;
  font-weight: 400;
}

.payment_oder_ns.MuiBox-root .MuiTypography-root:last-child {
  background: #f0eaff;
  border-radius: 5px;
  color: #121212;
  font-weight: 500;
  padding: 3px 10px;
  line-height: 1.4;
}

.billing_his_client .MuiBox-root.view_balance .MuiTypography-root {
  font-size: 17px;
  font-weight: 500;
  color: #121212;
}

.billing_his_client .MuiBox-root.view_balance {
  margin-top: 0;
  background: #e4dbfd !important;
  border: 1px solid #e4dbfd;
  padding-left: 15px;
  padding-right: 15px;
}

.MuiTypography-root.card_text {
  color: #555555;
  font-weight: 500;
  font-size: 14px;
  align-items: center;
}

.MuiTypography-root.card_text p.card_status {
  color: #121212;
  font-weight: 500;
  background: #e1f6ff;
  border-radius: 5px;
  padding: 5px 8px;
  height: auto;
  line-height: 1;
}

.card_ns {
  font-weight: 500;
  color: #555 !important;
  font-size: 15px;
  padding-left: 5px;
}

.expiry_details.MuiBox-root {
  gap: 4px;
  display: flex;
  justify-content: flex-end;
}

.expiry_details.MuiBox-root p {
  font-size: 14px;
  font-weight: 500;
  color: #555555;
}

.ri_box_cols_client .card_details {
  align-items: center;
}

.ri_box_cols_client {
  padding: 20px !important;
}
.ri_box_cols_client form fieldset {
  border: 1px solid #e4dbfd !important;
}

.ri_box_cols_client form label {
  color: #555555;
  font-weight: 500;
  font-size: 15px;
}

.ri_box_cols_client form .MuiInputBase-root.MuiInputBase-formControl {
  border-radius: 10px !important;
}

.ri_box_cols_client form .MuiInputBase-root.MuiInputBase-formControl textarea {
  height: 100px !important;
  color: #555;
  font-weight: 500;
  font-size: 15px;
}

.sbn_clt.MuiBox-root button.MuiButtonBase-root {
  width: 100%;
  font-size: 16px;
  font-weight: normal;
  text-transform: capitalize;
  border-radius: 10px;
  height: 45px;
}

.ri_box_cols_client form label input[type='file'] + .MuiBox-root {
  display: block;
  height: auto;
  text-align: center;
  padding: 20px;
  border: 2px dashed #e4dbfd;
  border-radius: 10px;
}

.ri_box_cols_client form label input[type='file'] + .MuiBox-root p {
  font-size: 15px;
  margin-bottom: 10px;
  font-weight: 500;
  color: #121212;
}

.ri_box_cols_client
  form
  label
  input[type='file']
  + .MuiBox-root
  button.MuiButtonBase-root {
  background: #7349e6;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  text-transform: capitalize;
  padding: 8px 40px;
  border-radius: 10px;
}

.ri_box_cols_client
  form
  label
  input[type='file']
  + .MuiBox-root
  button.MuiButtonBase-root:hover {
  background-color: #855ef1;
  border-color: #855ef1;
}

.billing_his_client label + .MuiInputBase-root {
  border-radius: 8px !important;
}

.client_external_tabs {
  background: #fff;
  border: 1px solid #e4dbfd !important;
  border-radius: 20px;
  box-shadow: 0 8px 20px #7f52fb40;
  padding: 25px 5px 0 25px;
}

.clients_left_internal_notes {
  background: #fff;
  border: 1px solid #e4dbfd !important;
  border-radius: 20px;
  box-shadow: 0 8px 20px #7f52fb40;
  padding: 15px;
  margin: 15px 0 0 0;
}

.client_head_slct {
  height: 45px;
}

.client_pro_are_left {
  border: 1px solid #e4dbfd !important;
  border-radius: 20px;
  box-shadow: 0 8px 20px rgba(127, 82, 251, 0.25);
  background: #fff;
  padding: 20px !important;
  margin-bottom: 22px;
}

.prp-dt-tb table {
  border-spacing: 0;
  border: 1px solid #e4dbfd;
}

.prp-dt-tb table tr th {
  border: none;
  font-size: 16px;
  font-weight: 600;
  background: #e4dbfd;
  padding: 13px 15px;
  border-top: 1px solid #e4dbfd;
  border-bottom: 1px solid #e4dbfd;
  max-width: inherit;
}

.prp-dt-tb table tr td {
  border: none;
  padding: 13px 15px;
  font-size: 14px;
  color: #555555;
  font-weight: 500 !important;
  background: #fff;
  border-top: 1px solid #e4dbfd;
  border-bottom: 1px solid #e4dbfd;
  max-width: inherit;
}

.text_rate {
  font-size: 17px;
  color: #121212;
  font-weight: 600;
}

.text_rate_til {
  font-size: 15px;
  font-weight: 500;
  color: #555555;
}
table .map_btn {
  max-width: inherit;
  white-space: normal;
}

table .map_btn span {
  font-weight: 500 !important;
  color: #121212;
}

.client_pro_are_left .slect_view div[role='button'] {
  background: #7349e6;
}

.client_pro_are_left .slect_view svg {
  color: #fff;
}

.client_pro_are_left .slect_view label[data-shrink='false'] {
  color: #fff;
  font-size: 15px;
  -webkit-transform: translate(17px, 10px) scale(1);
  -moz-transform: translate(17px, 10px) scale(1);
  -ms-transform: translate(17px, 10px) scale(1);
  transform: translate(17px, 10px) scale(1);
}

.client_pro_are_left .slect_view div[role='button'] {
  background: #7349e6;
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: inherit;
  height: auto;
  color: #fff;
  border-radius: 10px;
}

.client_pro_are_left .slect_view fieldset {
  border-color: #7349e6 !important;
  border-radius: 10px;
}

.billing_his_client .MuiBox-root .MuiFormControl-root div[role='button'] {
  color: #fff;
}

.overview-client div[role='tabpanel'] {
  border: 1px solid #e4dbfd;
  border-radius: 0px 0px 10px 10px;
  border-top: none;
}

.overview-client .MuiTabs-scroller button {
  border: 1px solid transparent;
  border-radius: 10px;
  margin-right: 13px;
  color: #121212;
  font-weight: 600;
  background: #e4dbfd;
  /* padding-left: 20px; */
  /* padding-right: 20px; */
  min-width: 110px;
  margin-bottom: 10px;
}

.client_external_tabs .MuiTabs-scroller button {
  border: 1px solid transparent;
  border-radius: 10px;
  margin-right: 13px;
  color: #121212;
  font-weight: 600;
  background: #e4dbfd;
  /* padding-left: 20px; */
  /* padding-right: 20px; */
  min-width: 110px;
  margin-bottom: 10px;
}

.overview-client .MuiTabs-scroller span.MuiTabs-indicator {
  background: none;
}

.tabs_button_cs .MuiTabs-scroller {
  border-bottom: 1px solid #e4dbfd;
}

.overview-client .MuiTabs-scroller button.Mui-selected {
  border: 1px solid #7349e6;
  background: #7349e6;
  color: #fff;
}

.client_external_tabs .MuiTabs-scroller button.Mui-selected {
  border: 1px solid #7349e6;
  background: #7349e6;
  color: #fff;
}

.client_pro_are_left .MuiGrid-grid-md-4 .MuiBox-root button.MuiButtonBase-root {
  border-radius: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 400;
}

.client_pro_are_left.schedule-area .no-data-found {
  border: 1px solid #e4dbfd;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  min-height: 100px;
  border-radius: 0 0 10px 10px;
  font-weight: 500;
}

.client_pro_are_left .MuiGrid-grid-md-4 .MuiBox-root {
  margin-bottom: 0;
}

.deal-notes {
  background: #e4dbfd !important;
  border: 1px solid #e4dbfd !important;
  padding: 10px !important;
  border-radius: 10px !important;
  box-shadow: 0 11px 14px rgba(25, 155, 203, 0.25) !important;
}

/*--sales-pipline-page-design---*/

.cls-cnt.sales_pp_hdr {
  gap: 0;
}

.sales_pp_hdr img.sc_icon {
  margin: 0 15px;
}

.togg_btn_sh {
  margin-left: 30px;
  border: 1px solid #e4dbfd !important;
  border-radius: 10px;
}

.togg_btn_sh .c-pointer {
  border: none !important;
  border-radius: 8px;
  color: #555555;
  font-weight: normal;
  padding: 10px 20px !important;
  font-size: 15px;
}

.togg_btn_sh .c-pointer.sales-view-active {
  background: #7349e6 !important;
  color: #fff;
}

.total_get_cs {
  align-items: center;
}

button.btn_cs_crl {
  border-radius: 10px;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: normal;
  padding: 10px 18px;
  line-height: 1.5;
  margin-left: 8px;
  margin-right: 8px;
}

.nw_appit_btn_cs button.MuiButton-contained {
  padding: 10px 18px;
  border-radius: 10px;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: normal;
  box-shadow: 0px 6px 14px rgba(25, 155, 203, 0.15) !important;
}

.sales_list_tb {
  padding-left: 15px;
  padding-right: 2px;
  background: none;
  margin-top: 10px;
}

.box-pjt-cs-inner_flow {
  flex: 0 0 calc(320px + 14px);
  margin-left: 0px;
  margin-right: 0px;
  max-width: calc(320px + 14px);
}

.box-pjt-cs-more .scroll-board .droppable {
  width: 100% !important;
}

.MuiBox-root .MuiBox-root:nth-of-type(5) .job-status_cls {
  background: #fdd0d6 !important;
  box-shadow: 0 11px 14px rgba(194, 106, 118, 0.25);
}

.MuiBox-root .MuiBox-root:nth-of-type(5) .job-status_cls:before {
  background: #faf8ff !important;
  box-shadow: 0 11px 14px rgba(194, 106, 118, 0.25);
}

.MuiBox-root .MuiBox-root:nth-of-type(5) .project_status_edit svg {
  fill: #ff7387;
}

.box-pjt-cs-inner_flow .scroll-board .droppable > .MuiBox-root {
  border-radius: 20px !important;
  box-shadow: 0 5px 10px rgba(25, 155, 203, 0.1) !important;
  padding: 15px !important;
  margin-bottom: 20px !important;
}

.deal_title {
  color: #000;
}

.de_job_en p {
  font-size: 15px !important;
  color: #555 !important;
}

.drawer-header {
  background: #e4dbfd;
}

.drawer-header svg[data-testid] {
  background: #ffffff !important;
  width: 30px !important;
  height: 30px !important;
  padding: 4px;
  border-radius: 5px !important;
  fill: #7349e6 !important;
}

.drawer-content .user-label {
  font-size: 15px;
  font-weight: 500;
}

.drawer-content .MuiFormControl-root label[data-shrink='false'] {
  font-size: 15px;
  color: #555555;
  font-weight: 400;
}

.drawer-content input[type='text'],
.drawer-content input[type='email'],
.drawer-content input[type='number'] {
  /* border: 1px solid; */
  color: #000;
  font-size: 15px;
}

.drawer-footer {
  background: #fff;
  border-top: 1px solid #e4dbfd;
}

.drawer-footer button {
  text-transform: capitalize;
  font-size: 16px;
  border-radius: 8px;
  font-weight: normal;
}

.drawer-content .MuiFormControl-root fieldset {
  border-color: #e4dbfd !important;
}

.drawer-content {
  margin: 80px 16px 100px 16px;
  border: 1px solid #e4dbfd;
  padding: 20px;
  border-radius: 10px;
}

.drawer-content
  .MuiFormControl-root
  button.MuiIconButton-root.MuiIconButton-edgeEnd
  svg {
  fill: #7349e6;
}

.time-duration_drwer .MuiBox-root {
  margin: 0px -8px;
}

.time-duration_drwer {
  margin-top: 10px;
}

.time-duration_drwer .MuiBox-root button.MuiButtonBase-root {
  background: #e4dbfd;
  border: 1px solid #e4dbfd;
  color: #555;
  font-size: 15px;
  padding: 10px 10px;
  border-radius: 10px;
  margin-top: 6px;
  margin-bottom: 6px;
}

.time-duration_drwer
  .MuiBox-root
  button.MuiButtonBase-root.MuiButton-containedPrimary {
  background: #7349e6;
  color: #fff;
}

.drawer-content .MuiFormControl-root .MuiInputBase-formControl {
  border-radius: 10px;
}

/*-----center-popup-css-start----*/

.MuiDialog-root
  .MuiDialog-container
  .MuiPaper-rounded
  h2#customized-dialog-title {
  /* background: #e4dbfd; */
  font-weight: 500;
  font-size: 18px;
  /* border-radius: 10px 10px 0 0; */
  /* border: 1px solid #e4dbfd; */
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  color: #4b4d5f;
}

.MuiDialog-root .MuiDialog-container .MuiPaper-rounded {
  padding: 16px;
  border-radius: 20px;
}

.MuiDialog-root
  .MuiDialog-container
  .MuiPaper-rounded
  h2#customized-dialog-title
  button.MuiButtonBase-root {
  background: #ffffff !important;
  border-radius: 5px !important;
  width: 30px;
  height: 30px;
  right: 0px;
  top: 0px;
}

.MuiDialog-root
  .MuiDialog-container
  .MuiPaper-rounded
  h2#customized-dialog-title
  button.MuiButtonBase-root
  svg {
  fill: #9e9e9e !important;
}

.MuiDialog-root .MuiDialog-container .MuiPaper-rounded .MuiDialogContent-root {
  padding: 20px;
}

.MuiDialog-root
  .MuiDialog-container
  .MuiPaper-rounded
  .MuiDialogContent-root
  form
  .react-tel-input
  .form-control {
  border: 1px solid #7349e6;
  border-radius: 8px;
  padding-left: 55px;
}

.MuiDialog-root
  .MuiDialog-container
  .MuiPaper-rounded
  .MuiDialogContent-root
  form
  .react-tel-input {
  margin-bottom: 5px;
}

.MuiDialog-root
  .MuiDialog-container
  .MuiPaper-rounded
  .MuiDialogContent-root
  form
  .react-tel-input
  .flag-dropdown {
  width: 48px;
  border: 1px solid #e4dbfd;
  background: #e4dbfd;
  border-radius: 10px 0px 0px 10px;
}
.MuiDialog-root
  .MuiDialog-container
  .MuiPaper-rounded
  .MuiDialogContent-root
  form
  .react-tel-input
  .form-control {
  border: 1px solid #e4dbfd;
  border-radius: 8px;
  padding-left: 52px;
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.MuiDialog-root
  .MuiDialog-container
  .MuiPaper-rounded
  .MuiDialogContent-root
  form
  .MuiFormControl-root
  label[data-shrink='false'] {
  font-size: 15px;
  color: #555555;
  font-weight: 400;
}

.MuiDialog-root
  .MuiDialog-container
  .MuiPaper-rounded
  .MuiDialogContent-root
  form
  .MuiFormControl-root
  fieldset {
  border-color: #e4dbfd;
}

.MuiDialog-root
  .MuiDialog-container
  .MuiPaper-rounded
  .MuiDialogContent-root
  form
  .MuiFormControl-root
  label
  + .MuiInputBase-root {
  border-radius: 10px;
}

.MuiDialog-root
  .MuiDialog-container
  .MuiPaper-rounded
  .MuiDialogContent-root
  form
  .MuiFormControl-root
  label
  + .MuiInputBase-root {
  border-radius: 10px;
  margin-bottom: 10px;
}

.MuiDialog-root
  .MuiDialog-container
  .MuiPaper-rounded
  .MuiDialogContent-root
  form
  .MuiFormControl-root
  textarea {
  font-size: 15px;
  color: #121212;
  font-weight: 400;
}

.MuiDialog-root
  .MuiDialog-container
  .MuiPaper-rounded
  .MuiDialogContent-root
  form
  button.MuiButton-outlinedPrimary {
  color: #6b77e1;
  border: 1px solid #6b77e1;
  border-radius: 8px;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
}

.MuiDialog-root
  .MuiDialog-container
  .MuiPaper-rounded
  .MuiDialogContent-root
  form
  button.MuiButton-containedPrimary {
  border-radius: 8px;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
}

/*---new-proposal-page-css---*/

.acc-pro-full-cotain {
  border: 1px solid transparent;
  background: #fff !important;
  box-shadow: 0px 0px 20px 0px rgba(208, 208, 208, 0.25);
  padding: 20px;
  border-radius: 20px;
  margin-top: 32px;
  margin-left: 14px;
  margin-right: 14px;
}

.client-pro_dta {
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 10px;
}

.client-pro_dta .MuiTypography-root {
  font-size: 17px;
  font-weight: 600;
  color: #000723;
  margin-bottom: 1px;
}

.acc-pro-addss .MuiBox-root svg {
  width: 23px;
  height: 23px;
  fill: #7349e6;
}

.acc-pro-addss .MuiBox-root .MuiBox-root {
  margin-bottom: 7px;
}

.acc-pro-addss .MuiBox-root p.left_data {
  color: #555555;
  max-width: 100%;
  font-weight: 400;
  font-size: 14px;
}

.acc-hder-box-outer {
  align-items: center;
}

.acc-pro-accpt-box .MuiBox-root .MuiTypography-root {
  font-size: 20px;
  font-weight: 600;
  color: #000723;
}

.acc-pro-accpt-box .MuiBox-root button.MuiButtonBase-root {
  background: #e4dbfd;
  border: 1px solid #e4dbfd;
  width: 42px;
  height: 42px;
  border-radius: 8px;
  padding: 0;
  min-width: inherit;
}

.acc-pro-accpt-box .MuiBox-root button.MuiButtonBase-root svg {
  width: 24px;
  height: 24px;
  fill: #7349e6;
}
.acc-pro-accpt-box .MuiBox-root {
  align-items: center;
}

.acc-pro-accpt-box .MuiBox-root .MuiTypography-root.sale_status {
  background: #7349e6;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  min-width: 95px;
  text-align: center;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 17px;
}

.acc-pro-accpt-rgt-infm .MuiBox-root p {
  font-size: 14px;
  min-width: 120px;
  margin-right: 10px;
}

.acc-pro-accpt-rgt-infm .MuiBox-root p.right_data {
  white-space: normal;
  max-width: inherit;
  color: #555555;
  max-width: 100%;
  font-weight: 400;
  font-size: 14px;
  min-width: inherit;
  margin-right: 0;
}

.acc-pro-accpt-rgt-infm .MuiBox-root p.tt-dtl {
  font-size: 18px;
  font-weight: 600;
  color: #000723;
  margin-bottom: 10px;
  margin-right: 0;
}

.acc-pro-accpt-rgt-infm .MuiBox-root .MuiBox-root {
  margin-bottom: 7px;
}

.acc-pro-full-cotain .acpt-card {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: none;
  padding: 0 0 15px 0;
}

.acc-pro-full-cotain .acpt-contact {
  background: #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 10px 10px 0 0;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 14px;
}

.acc_stts_cs_pros {
  /* font-size: 20px; */
  color: #555555;
  font-weight: 500;
}

.acc_stts_cs_pros span {
  color: #121212;
  font-weight: 600;
}

.acpt-header {
  padding: 0 8px;
}

.acpt-header .acpt-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /* border: 1px solid #e0e0e0; */
  /* margin: 16px 15px; */
  padding: 20px;
  padding-top: 15px;
  /* border-radius: 10px; */
}

.acpt-header .acpt-title .icns {
  width: 25px;
  height: 25px;
  /* background: #e0e0e0; */
  /* border-radius: 10px; */
  display: flex;
  align-items: self-start;
  justify-content: center;
  margin-top: 5px;
  /* border: 1px solid #e0e0e0; */
}

.acpt-header .acpt-title .icns img {
  max-width: 35px;
}

.col-add-ic .acpt-header-title {
  color: #000723;
  overflow: visible;
  white-space: normal;
  text-align: left;
  margin-left: 0;
  border: none;
  width: 100%;
}

.col-add-ic .d-flex-label {
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  min-width: inherit;
  flex-wrap: wrap;
}
.acpt-header .acpt-title .col-add-ic {
  width: calc(100% - 85px);
  padding-left: 10px;
}

.col-add-ic .d-flex-label div {
  margin-left: 0;
}

.acpt-card-images .acpt-logo-cnt {
  border: 1px dashed #ebedff;
  min-height: 80px;
  padding: 20px;
  border-radius: 10px;
  margin: 15px 0;
  background: rgb(245, 248, 255);
  display: flex;
  justify-content: center;
}

.acpt-card-images .acpt-primary-cnt {
  border: 1px dashed #ebedff;
  display: flex;
  justify-content: center;
  min-height: 80px;
  padding: 20px;
  border-radius: 10px;
  margin: 15px 0;
  background: rgb(245, 248, 255);
}

.acpt-card-text {
  margin: 24px 14px;
}

.acpt-card-text .acpt-label {
  font-size: 17px;
}

.acpt-card-text .title-acpt {
  border: 1px solid rgb(87, 106, 164);
  border-radius: 10px;
  margin: 10px 0;
}

.acpt-card-text .title-acpt .acpt-txt {
  margin: 0;
  padding: 20px;
}

.acpt-card-text .title-acpt .acpt-txt p {
  color: #555555;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.6;
}

.acpt-card-text .title-acpt .acpt-txt ol,
.acpt-card-text .title-acpt .acpt-txt ul {
  padding-left: 20px;
}

.acpt-card-text .title-acpt .acpt-txt ol li,
.acpt-card-text .title-acpt .acpt-txt ul li {
  margin: 5px 0;
}

.acpt-card-quote-details {
  margin: 24px 0;
}
.acpt-card-quote-details .acpt-label {
  font-size: 17px;
  margin-bottom: 5px;
}

.acpt-label_count-pg {
  font-weight: 500;
  color: #4b4d5f;
  font-size: 14px;
}

.acpt-section_out_cs {
  border: 1px solid #e0e0e0;
  background: #fff !important;
  box-shadow: 0px 0px 20px 0px rgba(208, 208, 208, 0.25);
  padding: 20px;
  border-radius: 20px;
  margin: 15px 0;
}

.acpt-section_out_cs .acpt-title {
  margin-top: 0;
}

.acpt-rejectSpinnerButton {
  color: #7349e6;
}

.acpt-label-notes .title-acpt {
  margin-top: 10px;
}

.acpt-label-notes .title-acpt {
  margin-top: 10px;
  border-radius: 10px;
  padding: 20px;
  min-height: 150px;
}

.acpt-label-notes .title-acpt .MuiTypography-root {
  padding: 0;
  color: #555555;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.6;
}

.propos-sub_totl_cs {
  /* border-top: 2px dashed #576AA4; */
  /* border-bottom: 2px dashed #576AA4; */
  width: 100%;
  /* padding: 13px;
  margin: 30px 0 5px 0; */
}

/* .propos-sub_totl_cs>.MuiBox-root {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 15px;
} */

/* .acpt-total_all_values {
  border: 1px solid #ddd;
  padding: 0px 20px 20px;
  border-radius: 10px;
} */

.quote_recurring_scn {
  border: 1px solid #e4dbfd;
  padding: 0px 20px 20px;
  border-radius: 10px;
  display: flex;
  margin: 15px 0px;
  justify-content: space-between;
}

.quote_inner_type {
  display: flex;
  justify-content: space-between;
}

.propos-sub_totl_cs .d-flex-label .user-label {
  font-size: 17px;
  color: #121212 !important;
  font-weight: 600 !important;
}

.propos-sub_totl_cs .d-flex-label .MuiBox-root {
  color: #555;
  font-weight: 500;
}

.box-pjt-cs-inner_flow
  .scroll-board
  .droppable
  > .MuiBox-root
  .deal_title:hover,
.box-pjt-cs
  .box-pjt-cs-inner
  .scroll-board
  .droppable
  > .MuiBox-root
  .title_jb:hover {
  color: #855ef1;
}

/*----------calendar-page-css----*/

.calendar_date .fc-view-harness {
  background: #fff;
  border: 1px solid #e4dbfd;
  padding: 20px;
  border-radius: 20px;
}

.calendar_date .fc-view-harness .fc-view {
  padding: 24px;
}

/*----------create-user-css----*/

.tbs_user_info {
  border: 1px solid #e4dbfd !important;
  border-radius: 20px;
  box-shadow: 0 8px 20px rgba(127, 82, 251, 0.25);
  background: #fff;
  padding: 25px !important;
  /* margin-bottom: 22px; */
}

.side-bar_main li.non-active img,
.tgle-bar_main li.non-active img {
  width: 100%;
  max-width: 23px;
}

.side-bar_main li.list-active img,
.tgle-bar_main li.list-active img {
  filter: brightness(0);
  width: 100%;
  max-width: 23px;
}

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.tbs_user_info_cs {
  border-color: #e4dbfd !important;
}

.tbs_user_info_cs .MuiTabs-flexContainer button.MuiButtonBase-root {
  border: 1px solid transparent;
  border-radius: 10px;
  margin-right: 13px;
  color: #121212;
  font-weight: 400;
  background: #e4dbfd;
  min-width: 110px;
  margin-bottom: 10px;
  font-size: 15px;
}

.tbs_user_info_cs
  .MuiTabs-flexContainer
  button.MuiButtonBase-root.Mui-selected {
  border: 1px solid #7349e6;
  color: #fff;
  background: #7349e6;
}

.tbs_user_info_cs span.MuiTabs-indicator {
  display: none;
}

.tbs_user_info div[role='tabpanel'] > .MuiBox-root {
  border: 1px solid #e4dbfd;
  border-top: none;
  border-radius: 0 0px 10px 10px;
  padding: 20px;
}

.tbs_user_info div[role='tabpanel'] form fieldset {
  border-color: #e4dbfd;
}

.tbs_user_info div[role='tabpanel'] form .MuiInputBase-root {
  border-radius: 8px;
}
.tbs_user_info
  div[role='tabpanel']
  form
  .MuiInputBase-root
  button.MuiIconButton-root
  svg {
  fill: #7349e6;
}

.tbs_user_info div[role='tabpanel'] form input {
  color: #555;
  font-weight: 400;
  font-size: 15px;
}

.tbs_user_info
  div[role='tabpanel']
  form
  .MuiFormControl-root
  label[data-shrink='false'] {
  color: #555555 !important;
  font-weight: 400;
  font-size: 15px;
}

.tbs_user_info div[role='tabpanel'] form .MuiFormControl-root label#email-label,
.tbs_user_info
  div[role='tabpanel']
  form
  .MuiFormControl-root
  label#password-label {
  color: #7349e6;
  font-weight: 400;
}

.tbs_user_info
  div[role='tabpanel']
  form
  .MuiFormControl-root
  label#defaultCalender {
  color: #7349e6;
  font-weight: 400;
}

.tbs_user_info
  div[role='tabpanel']
  form
  .MuiFormControl-root
  div#defaultCalender {
  color: #555;
  font-weight: 400;
  font-size: 15px;
}

.tbs_user_info div[role='tabpanel'] form .MuiInputBase-root input + svg {
  fill: #7349e6;
}

.phy-add-cs {
  padding-top: 15px;
}

.bllng-add-cs label {
  margin-left: 0 !important;
  margin-right: 6px !important;
}

.bllng-add-cs label span.MuiButtonBase-root.MuiCheckbox-root {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 5px;
}

.bllng-add-cs {
  padding-top: 20px;
  padding-bottom: 15px;
}

.event-calender {
  padding: 20px;
  background: #fff;
  border-radius: 20px;
  border: 1px solid #e4dbfd;
  box-shadow: 0 8px 20px rgba(127, 82, 251, 0.25);
}

.top_calendar_bar {
  align-items: center;
  margin-bottom: 20px !important;
}

.top_calendar_right-app .nw_appit_btn_cs button.MuiButtonBase-root {
  margin-right: 0px;
}

.top_calendar_right-app button.MuiButtonBase-root {
  padding: 10px 18px;
  border-radius: 10px;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: normal;
  margin-right: 8px;
}

.top_calendar_bar .MuiTypography-root {
  font-size: 24px;
  padding-left: 10px;
}

.event-calender .fc-toolbar-title {
  font-size: 22px;
  font-weight: 600;
  text-transform: capitalize;
}

.event-calender .fc-daygrid-day-top {
  flex-direction: inherit;
}

.event-calender .calender-event {
  line-height: 1;
  font-size: 13px;
  padding-top: 9px;
  padding-bottom: 9px;
}
.event-calender
  .fc-header-toolbar.fc-toolbar
  .fc-button-group
  button.fc-prev-button,
.event-calender
  .fc-header-toolbar.fc-toolbar
  .fc-button-group
  button.fc-next-button {
  background: #e4dbfd !important;
  border: 1px solid #7349e6;
  width: 40px;
  height: 40px;
  border-radius: 10px !important;
  padding: 0;
  box-shadow: none !important;
}

.event-calender
  .fc-header-toolbar
  .fc-toolbar-chunk:last-child
  .fc-button-group {
  border: 1px solid #e4dbfd !important;
  border-radius: 10px;
}

.event-calender
  .fc-header-toolbar
  .fc-button-group
  button.fc-dayGridMonth-button,
.event-calender
  .fc-header-toolbar
  .fc-button-group
  button.fc-timeGridThreeDay-button {
  background: none;
  color: #555555;
  font-size: 14px;
  text-transform: capitalize;
  border: none;
  border-radius: 10px;
  font-weight: 500;
  box-shadow: none !important;
  padding: 9px 15px;
}

.event-calender
  .fc-header-toolbar
  .fc-button-group
  button.fc-dayGridMonth-button.fc-button-active {
  background: #7349e6;
  color: #fff;
}

.event-calender
  .fc-header-toolbar
  .fc-button-group
  button.fc-timeGridThreeDay-button.fc-button-active {
  background: #7349e6;
  color: #fff;
}

.event-calender .fc-view-harness table thead tr th th {
  border-color: #e4dbfd !important;
  padding: 10px;
  background: #e4dbfd;
  font-weight: 600;
  font-size: 15px;
}

.event-calender .fc-view-harness table tr td {
  border-color: #e4dbfd !important;
}

.event-calender .fc-view-harness table thead tr th {
  border-color: #e4dbfd !important;
}

.event-calender .fc-view-harness table {
  border-color: #e4dbfd !important;
  border-radius: 10px;
  overflow: hidden;
}

.event-calender .fc-view-harness table table {
  border-radius: 0;
  overflow: visible;
}

.cal-sts-filter .MuiPaper-root.MuiPaper-rounded.MuiAccordion-root:before {
  display: none !important;
}

.cal-sts-filter .MuiPaper-root.MuiPaper-rounded.MuiAccordion-root {
  background: #fff;
  box-shadow: none;
  margin: 16px 0;
  border: none !important;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(127, 82, 251, 0.25);
  padding-top: 12px;
  padding-bottom: 12px;
}

.cal-sts-filter
  .MuiPaper-root.MuiPaper-rounded.MuiAccordion-root
  .appt-filtr-toggle {
  min-height: 30px !important;
  max-height: 30px;
}

div#users_scoll {
  height: auto;
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 0 !important;
}

/* .event_scoll.MuiBox-root {
  border-top: 1px solid #e4dbfd;
  margin-top: 15px !important;
  padding-top: 13px;
} */

.cal-sts-filter div.MuiAccordionSummary-root {
  margin-top: 0;
  padding-right: 8px;
}

.cal-sts-filter .google-board {
  background: #fff;
  border: 1px solid #e4dbfd;
}

.cal-sts-filter
  .MuiPaper-root.MuiPaper-rounded.MuiAccordion-root
  .MuiCollapse-root
  .MuiBox-root
  label
  span.MuiTypography-root {
  font-size: 15px;
}

.cal-sts-filter
  .MuiPaper-root.MuiPaper-rounded.MuiAccordion-root
  .MuiCollapse-root
  .MuiBox-root
  label
  span.MuiButtonBase-root.MuiCheckbox-root {
  padding-top: 0;
  padding-bottom: 0;
}

.cal-sts-filter
  .MuiPaper-root.MuiPaper-rounded.MuiAccordion-root
  .MuiCollapse-root
  .MuiBox-root
  label {
  margin-bottom: 8px;
  margin-top: 2px;
}

.MuiDialog-root
  .MuiDialog-container
  .MuiPaper-rounded
  .MuiDialogContent-root
  .modal-section {
  border: none;
  padding: 0;
}

.drawer-content .MuiFormControl-root {
  border: none !important;
}
/* .drawer-content .MuiFormControl-root svg {
  fill: #fff;
} */

/*-----------appoitment-page-design-----*/

.MuiDialog-container
  .MuiGrid-root
  .d-flex-3.MuiBox-root
  button.MuiButtonBase-root.MuiButton-containedPrimary {
  text-transform: capitalize;
  font-size: 16px;
  border-radius: 8px;
  font-weight: normal;
}

.MuiDialog-container
  .MuiGrid-root
  .d-flex-3.MuiBox-root
  button.MuiButtonBase-root.MuiButton-outlinedPrimary {
  text-transform: capitalize;
  font-size: 16px;
  border-radius: 8px;
  font-weight: normal;
}

/*---------toggle-menu-bar-----*/

.tgle-bar_main li.list-active > .MuiButtonBase-root.MuiListItemButton-root {
  border-radius: 0px 0 0px 0px;
}

.tgle-bar_main li:before {
  width: 20px;
  height: 20px;
  top: -15px;
}

.tgle-bar_main li:after {
  width: 20px;
  height: 20px;
  bottom: -15px;
}
.tgle-bar_main li > .MuiButtonBase-root.MuiListItemButton-root:before {
  width: 20px;
  height: 20px;
  top: -20px;
}

.tgle-bar_main li > .MuiButtonBase-root.MuiListItemButton-root:after {
  width: 20px;
  height: 20px;
  bottom: -20px;
}

.tgle-bar_main li > .MuiButtonBase-root.MuiListItemButton-root img {
  width: 22px;
}

.tgle-bar_main li > .MuiButtonBase-root.MuiListItemButton-root {
  padding-left: 10px;
  padding-right: 10px;
}

/*----home-screen-design----*/

.calendar_date .fc-header-toolbar h2 {
  font-size: 24px;
  font-weight: 600;
}
.calendar_date .fc-header-toolbar .fc-button-group button.fc-button {
  background: #d7cef1 !important;
  border: 1px solid #7349e6;
  width: 40px;
  height: 40px;
  border-radius: 10px !important;
  padding: 0;
  box-shadow: none !important;
}

.calendar_date .fc-header-toolbar .fc-toolbar-chunk button.fc-today-button {
  padding: 10px 18px;
  border-radius: 10px;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.4;
  border: none;
  background-color: #7349e6;
}

.calendar_date
  .fc-header-toolbar
  .fc-button-group
  button.fc-button.fc-prev-button {
  margin-right: 8px;
}

.calendar_date .fc-header-toolbar .fc-button-group {
  margin-left: 17px;
}

.calendar_date .fc-view.fc-daygrid table thead tr th th {
  border-color: #e4dbfd !important;
  padding: 10px;
  background: #e1f6ff;
  font-weight: 600;
  font-size: 15px;
}

.calendar_date .fc-view.fc-daygrid table tr td {
  border-color: #e4dbfd !important;
}

.calendar_date .fc-view.fc-daygrid table thead tr th {
  border-color: #e4dbfd !important;
  background: #e4dbfd !important;
}

.calendar_date .fc .fc-daygrid-day-top {
  flex-direction: inherit;
  font-size: 15px;
  font-weight: 400;
}

.calendar_date .fc-view.fc-daygrid table {
  border-color: #e4dbfd !important;
  border-radius: 10px;
  overflow: hidden;
}

.calendar_date .fc-view.fc-daygrid table table {
  border-radius: 0;
  overflow: visible;
}

.layout_inner_edit_prps {
  margin: 20px;
  background: #fff;
  background: #ffffff;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid #e4dbfd;
  box-shadow: 0 8px 20px rgba(127, 82, 251, 0.25);
}

/*.layout_edit_pros_com {
  margin: 20px;
  background: #fff;
  border-radius: 20px;
  border: 1px solid #e4dbfd;
  box-shadow: 0px 8px 20px rgba(25,155,203,0.25);
  padding-bottom: 20px;
}*/

.edit_pros_right_cls_clne .layout-container {
  background: #fff;
  border-radius: 20px;
  border: 1px solid #e4dbfd;
  box-shadow: 0 8px 20px rgba(127, 82, 251, 0.25);
  padding-bottom: 20px;
  margin-top: 20px;
  padding: 20px;
  margin: 16px 10px 0 5px;
}

.layout_edit_pros_com .MuiGrid-root.MuiGrid-container {
  margin-top: 0;
}

.layout_edit_pros_com .proposal-item-container {
  background: #fff;
  border-radius: 20px;
  border: 1px solid #e4dbfd;
  box-shadow: 0 8px 20px rgba(127, 82, 251, 0.25);
  padding: 16px;
  margin-left: 8px;
  margin-bottom: 10px;
}

.layout_edit_pros_com .proposal-item-container .proposal-item-header {
  padding-left: 0;
  padding-right: 0;
  padding-top: 10px;
}

.layout_edit_pros_com .proposal-item-container .proposal-item-header b {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 4px;
  display: block;
}

.layout_edit_pros_com .proposal-item-container .proposal-item-header div {
  font-size: 15px;
}

.layout_edit_pros_com
  .proposal-item-container
  div[role='button']
  > .MuiBox-root {
  border: 1px solid #e4dbfd;
  padding: 12px 7px;
  margin-bottom: 12px;
  border-radius: 10px;
  align-items: center;
}

.layout_edit_pros_com
  .proposal-item-container
  div[role='button']
  > .MuiBox-root
  .MuiFormGroup-root
  label {
  margin-right: 0 !important;
}

.layout_edit_pros_com
  .proposal-item-container
  div[role='button']
  > .MuiBox-root
  > .MuiBox-root {
  margin-top: 0;
}

.layout_edit_pros_com
  .proposal-item-container
  div[role='button']
  > .MuiBox-root
  p.MuiTypography-root {
  font-size: 14px;
  font-weight: 500;
}

.layout_edit_pros_com
  .proposal-item-container
  div[role='button']
  > .MuiBox-root
  .MuiFormGroup-root
  label
  span.MuiSwitch-root {
  margin-top: 0;
  margin-bottom: 0;
}

.page_edit_pros_btn {
  margin: 24px 0 10px 0 !important;
}

.page_edit_pros_btn button.MuiButtonBase-root {
  border-radius: 8px;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 400;
  padding: 8px 20px;
}

.user-label_inet_nts_edit {
  background: #fff;
  border-radius: 20px;
  border: 1px solid #e4dbfd;
  box-shadow: 0 8px 20px rgba(127, 82, 251, 0.25);
  padding: 16px;
  margin: 20px 0px 20px 8px;
}

.user-label_inet_nts_edit .MuiBox-root {
  padding-left: 0;
  padding-right: 0;
}

.user-label_inet_nts_edit .MuiBox-root form textarea {
  height: 70px !important;
  color: #555;
  font-weight: 500;
  font-size: 14px;
}

.user-label_inet_nts_edit .MuiBox-root form fieldset {
  border: 1px solid #e4dbfd !important;
}

.user-label_inet_nts_edit .MuiBox-root form label[data-shrink='false'] {
  color: #555555;
  font-weight: 500;
  font-size: 14px;
}

.user-label_inet_nts_edit .MuiBox-root form .MuiFormControl-root {
  border-radius: 10px !important;
}
.user-label_inet_nts_edit
  .MuiBox-root
  form
  .MuiFormControl-root
  .MuiInputBase-root.MuiOutlinedInput-root {
  border-radius: 10px;
}

.user-label_inet_nts_edit .MuiBox-root form label[for='file'] .MuiBox-root {
  display: block;
  height: auto;
  text-align: center;
  padding: 16px;
  border: 2px dashed #e4dbfd;
  border-radius: 10px;
}

.user-label_inet_nts_edit
  .MuiBox-root
  form
  label[for='file']
  .MuiBox-root
  p.MuiTypography-root {
  font-size: 15px;
  margin-bottom: 10px;
  font-weight: 500;
  color: #121212;
}

.user-label_inet_nts_edit
  .MuiBox-root
  form
  label[for='file']
  button.MuiButtonBase-root {
  background: #7349e6;
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  text-transform: capitalize;
  padding: 7px 30px;
  border-radius: 10px;
}

.user-label_inet_nts_edit
  .MuiBox-root.sbmt_btn_contin
  button.MuiButtonBase-root {
  width: 100%;
  font-size: 16px;
  font-weight: normal;
  text-transform: capitalize;
  border-radius: 10px;
  height: 45px;
}

.tt_add_bttm p.MuiTypography-root {
  text-transform: capitalize;
}

table .map_btn span {
  max-width: 100% !important;
  text-transform: capitalize;
  text-align: left !important;
}

.ri_box_cols_client button.MuiButtonBase-root {
  border-radius: 8px;
  text-transform: capitalize;
}

.side-width + main .page-container {
  width: calc(100vw - 120px);
}

.calendar_date .fc-view.fc-daygrid table table {
  width: 100% !important;
}
.fc .fc-daygrid-body {
  width: 100% !important;
}

.event-calender
  .fc-view-harness
  .fc-timeGridThreeDay-view
  table
  tr
  td
  .fc-timegrid-slot-label-cushion {
  font-size: 14px;
  font-weight: 500;
}

.fc .fc-timegrid-axis-cushion {
  max-width: inherit;
  font-size: 15px;
  font-weight: 500;
  /* padding: 4px !important; */
}

.cal-sts-filter div.MuiAccordionSummary-root button.MuiButtonBase-root {
  padding-left: 0;
  padding-right: 0;
}

.cal-sts-filter div.MuiAccordionSummary-root button.MuiButtonBase-root svg {
  font-size: 23px;
  width: 21px;
  height: 21px;
}

.cal-sts-filter div.MuiAccordionSummary-root h6.MuiTypography-root {
  font-size: 16px;
  font-weight: 600;
}

.cal-sts-filter
  .MuiPaper-root.MuiPaper-rounded.MuiAccordion-root
  .appt-filtr-toggle
  button.MuiButtonBase-root.MuiIconButton-root {
  padding: 2px;
  background: none !important;
}
.overview-client .slect_view .MuiBox-root .MuiFormControl-root {
  width: auto;
  min-width: 125px;
}

.overview-client .slect_view .MuiBox-root,
.schedule-area .slect_view .MuiBox-root {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
}

/* booking form css */

.booking-card {
  padding: 20px;
  background: #fff;
  border-radius: 20px;
  border: 1px solid #e4dbfd;
  box-shadow: 0 8px 20px rgba(127, 82, 251, 0.25);
  margin: 35px 0 0 0;
}

.service-label {
  margin: 0 18px 0 0;
}

p.booking-txt {
  font-size: 24px;
  font-weight: 600;
  margin: 0 16px 0 0;
}

/* booking form css */

.schedule-area .slect_view .MuiBox-root .MuiFormControl-root {
  width: auto;
  min-width: 195px;
}

.client_pro_are_left .MuiGrid-root.MuiGrid-container {
  align-items: center;
}

.nw_appit_btn_cs ul li {
  font-size: 14px;
  font-weight: 400;
}

header.MuiPaper-root .MuiBox-root button.MuiButtonBase-root .profile-avatar {
  width: 35px;
  height: 35px;
}
header.MuiPaper-root
  .MuiBox-root
  button.MuiButtonBase-root
  .profile-avatar
  + .MuiBox-root {
  margin-left: 10px;
}

header.MuiPaper-root
  .MuiBox-root
  button.MuiButtonBase-root
  .profile-avatar
  + .MuiBox-root
  .MuiTypography-root {
  font-weight: 600 !important;
  font-size: 15px !important;
}

header.MuiPaper-root
  .MuiBox-root
  button.MuiButtonBase-root
  .profile-avatar
  + .MuiBox-root
  .MuiTypography-root
  + svg {
  width: 22px;
  height: 22px;
}

div#menu-appbar ul.MuiList-root li {
  font-size: 14px;
  line-height: 1;
  padding-top: 5px;
  padding-bottom: 5px;
}

div#menu-appbar ul.MuiList-root li svg {
  fill: #6b77e1;
}

.tgle-bar_main
  li
  > .MuiButtonBase-root.MuiListItemButton-root
  .MuiListItemIcon-root {
  justify-content: center;
}

.info_pop_cls p.hed_modl {
  font-size: 15px;
  color: #000;
  font-weight: 600;
  width: 100% !important;
  line-height: 1.5;
  margin-bottom: 7px;
}

.info_pop_cls {
  background: #fff;
  box-shadow: 0 5px 10px rgba(127, 82, 251, 0.15) !important;
  padding: 13px;
  border-radius: 15px;
  margin-bottom: 15px;
  border: 1px solid #e4dbfd;
}

.info_pop_cls .info_pop_cls_inn_ds {
  border-top: 1px solid #e4dbfd;
  padding-top: 5px;
}

.info_pop_cls .info_pop_cls_inn_ds p {
  font-size: 14px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  gap: 10px;
}

.info_pop_cls .info_pop_cls_inn_ds p b {
  font-weight: 600;
  font-size: 13px;
}

.box-slide_fmt_oter {
  background: #fff;
  box-shadow: 0 5px 10px rgba(127, 82, 251, 0.15) !important;
  padding: 13px;
  border-radius: 15px;
  border: 1px solid #e4dbfd;
}

.box-slide_fmt_oter .box-slide_fmt p {
  font-size: 15px;
  color: #000;
  font-weight: 600;
  width: 100% !important;
  line-height: 1.5;
  margin-bottom: 2px;
}

.box-slide_fmt_oter p {
  font-size: 14px;
  /* font-weight: 500; */
}

.box-slide_fmt_icn {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-slide_fmt_icn img {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
}

.drp-se-slct {
  margin-top: 15px;
}

.drp-se-slct p.MuiTypography-root {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  width: 100% !important;
  line-height: 1.5;
  margin-bottom: 20px;
}

.drp-se-slct .MuiFormControl-root fieldset {
  border-color: #e4dbfd !important;
}

.drp-se-slct .MuiFormControl-root .MuiInputBase-formControl {
  border-radius: 10px;
}

.drp-se-slct .MuiFormControl-root svg {
  fill: #7349e6;
}

.drp-se-slct .MuiFormControl-root div[role='button'] {
  font-size: 15px;
}
.drp-se-slct .MuiFormControl-root label[data-shrink='true'] {
  color: #7349e6;
}

.ds-se-ti {
  font-size: 16px !important;
  color: #000;
  font-weight: 600 !important;
  width: 100% !important;
  line-height: 1.5 !important;
  margin-bottom: 4px !important;
  margin-top: 5px !important;
}

.drp-se-slct {
  margin-top: 15px !important;
}

.proposal-card,
.proposal-sent-card,
.proposal-accepted-card,
.proposal-rejected-card {
  border: 1px solid #e4dbfd;
  box-shadow: none !important;
  border-radius: 20px;
  background: #fff !important;
  width: auto;
  margin: 3px 3px;
  padding: 16px;
}

.drw_sls_psn {
  background: #e4dbfd;
  border: 1px solid #e4dbfd;
  border-radius: 10px;
  padding: 10px 16px;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 26px;
}
.drw_sls_psn .d-flex-4 .MuiBox-root {
  font-size: 15px;
  color: #555555;
  font-weight: 500;
  /* margin: 0px; */
}

.drawer-content .btn_gp_dwr button.MuiButtonBase-root {
  padding: 8px 18px;
  border-radius: 10px;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: normal;
  box-shadow: none !important;
  margin-left: 6px;
  margin-right: 6px;
}

.drawer-content .btn_gp_dwr .nw_appit_btn_cs {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.pfl_cs p {
  font-size: 16px;
  color: #121212;
  font-weight: 600;
  width: 135px;
}

.pfl_cs .MuiBox-root {
  font-size: 15px;
  color: #555;
  font-weight: 500;
}

.pfl_cs {
  margin-top: 12px !important;
}

.btn_gp_dwr {
  padding-top: 10px;
}

.quck_act_accodi {
  border: 1px solid #e4dbfd;
  margin-top: 30px;
}

.quck_act_accodi {
  border: 1px solid #e4dbfd;
  margin-top: 30px;
  border-radius: 10px;
  background: #fff;
}

.quck_act_accodi .user-label {
  margin-top: 0 !important;
  background: #e4dbfd;
  padding: 12px 16px;
  border-radius: 10px 11px 0 0;
  border-bottom: 1px solid #e4dbfd;
  font-weight: 600 !important;
}

.drwr-accdn-cs {
  padding: 16px;
  margin-bottom: 30px;
}

.drwr-accdn-cs .MuiPaper-root {
  margin-bottom: 15px;
  border: 1px solid #e4dbfd !important;
  border-radius: 10px;
}

.drwr-accdn-cs .MuiPaper-root .MuiButtonBase-root {
  background: none;
  font-weight: 500;
  -webkit-flex-direction: inherit !important;
  -ms-flex-direction: inherit !important;
  flex-direction: inherit !important;
}

.drwr-accdn-cs .MuiPaper-root .MuiButtonBase-root .MuiAccordionSummary-content {
  margin-left: 0;
}

.drwr-accdn-cs
  .MuiPaper-root
  .MuiButtonBase-root
  .MuiAccordionSummary-content
  p.MuiTypography-root {
  color: #555555;
  font-size: 15px;
  font-weight: 500;
}

.drwr-accdn-cs .MuiPaper-root .MuiAccordionDetails-root {
  border-color: #e4dbfd !important;
}

.drwr-accdn-cs .MuiPaper-root .MuiAccordionDetails-root .MuiBox-root {
  font-size: 15px;
  font-weight: 400;
}

.drwr-accdn-cs
  .MuiPaper-root
  .MuiButtonBase-root
  .MuiAccordionSummary-expandIconWrapper
  svg {
  fill: #000;
}

.jb_st_dt > .time_get_cs {
  width: 100%;
}

.jb_st_dt .lead_proposal + .time_get_cs {
  width: auto;
}

/* .layout-container button.MuiButtonBase-root {
  padding: 10px 18px;
  border-radius: 10px;
  text-transform: capitalize;
} */

.booking-form_inner .MuiBox-root {
  align-items: center;
}

.booking-form_inner .MuiBox-root button.MuiButtonBase-root {
  font-size: 13px;
  line-height: 1;
  padding: 6px 15px;
  border-radius: 6px;
  text-transform: capitalize;
  background: #e00a2c;
}

.pd-re {
  padding-top: 5px !important;
}
.pd-re .service-label p {
  color: #555555;
  font-weight: 500;
  font-size: 15px;
}

.qst-tt-txt {
  font-weight: 500 !important;
  font-size: 15px !important;
  line-height: 1.5 !important;
  margin-top: 8px !important;
  color: #555 !important;
}

.pd-cs-txt {
  padding-top: 0 !important;
  padding-bottom: 20px;
}

.proposal-card:hover,
.proposal-sent-card:hover,
.proposal-accepted-card:hover,
.proposal-rejected-card:hover {
  background: #fff !important;
  border-color: #7349e6;
  box-shadow: 0 8px 20px rgba(127, 82, 251, 0.25) !important;
  cursor: pointer;
}

.pro_pdf_cls .pspl_pdf_lnk button.MuiButtonBase-root {
  padding: 0 !important;
  border-radius: 0 !important;
  color: #7349e6 !important;
  text-decoration: underline;
  min-width: inherit !important;
  background: none !important;
  text-transform: capitalize !important;
  margin: 0 !important;
}

.uplo_fie .MuiBox-root .MuiBox-root {
  border-radius: 0;
  border: none;
}

.MuiDialogContent-root .MuiTypography-root.MuiTypography-h6 {
  font-size: 17px !important;
}

.uplo_fie
  .MuiBox-root
  .MuiBox-root
  .drag-drop-thumbnail
  + button.MuiIconButton-root {
  padding: 8px;
  border-radius: 6px;
}

.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote {
  line-height: 1.5;
  font-size: 14px;
}

.proposal-flex.pro_pdf_cls {
  gap: 5px;
  margin-top: 5px;
}

.proposal-flex.pro_pdf_cls .MuiBox-root {
  padding: 0 8px 0 0;
}

.proposal-flex.pro_pdf_cls .pspl_pdf_lnk {
  margin: 0;
  padding: 0 !important;
}

.pro-src-hndle {
  display: flex;
  width: 100%;
  max-width: 850px;
}

.pro-src-hndle > div {
  width: 100%;
  max-width: 600px;
  background: #fff;
  border-radius: 10px;
}

.pro-src-hndle > div .nm_sch_br {
  border: 1px solid #e4dbfd;
  height: 50px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(127, 82, 251, 0.15);
}
.pro-src-hndle > div .nm_sch_br input {
  font-size: 15px;
  font-weight: 500;
  color: #212121;
  /* height: 50px; */
}

.pro-src-hndle button.MuiButtonBase-root {
  height: 48px;
  margin-left: 15px;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  font-size: 15px;
  width: auto;
}

.prp-dt-tb table td button.MuiButtonBase-root {
  padding: 6px 8px;
}

.se_tbs_upp_wrapper .gridTabs {
  gap: 20px;
}

.tabLeftSide {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 8px 20px #7f52fb40;
  width: calc(100% - 36px);
  margin-left: 16px;
  padding-bottom: 30px;
}

.settings-tabs .MuiTabs-scroller .MuiTabs-flexContainer .Mui-selected {
  background: #7349e6;
  color: #fff;
}

.tabRightSide {
  border: 1px solid #e4dbfd !important;
  border-radius: 20px;
  box-shadow: 0 8px 20px rgba(127, 82, 251, 0.25);
  background: #fff;
  padding: 20px !important;
  margin-bottom: 22px;
  width: 100%;
}

.tabLeftSide .MuiTabs-vertical .MuiTabs-fixed > span {
  display: none;
}

.tabLeftSide .settings-tabs .MuiTabs-fixed > div > button {
  display: flex;
  justify-content: start;
  align-items: center;
  min-height: 55px;
  gap: 5px;
  color: #000;
  border-radius: 0;
}

.tabLeftSide .settings-tabs .MuiTabs-fixed > div > button:first-child {
  border-radius: 20px 20px 0 0;
}

.setting-tabs_nml button.MuiButtonBase-root {
  background: #e4dbfd;
  margin-right: 12px;
  color: #121212;
  font-weight: 600;
}

.setting-tabs_nml span.MuiTabs-indicator {
  display: none !important;
}

.setting-tabs_nml {
  padding-bottom: 15px;
}

.setting-tabs_nml button.MuiButtonBase-root[aria-selected='true'] {
  background: #7349e6;
  color: #fff;
}

.se_tbs_upp_wrapper div[role='tabpanel'] > .MuiBox-root {
  /* padding-left: 0px;
  padding-right: 0px; */
  padding: 0;
}

.pro_bsi_dtls.settingTabTitle {
  border: 1px solid #e4dbfd !important;
  border-radius: 20px;
  /*box-shadow: 0 4px 14px rgba(127,82,251,0.25);*/
  background: #fff;
  padding: 0px !important;
  margin-bottom: 22px;
}

.pro_bsi_dtls.settingTabTitle .user-label {
  background-color: #e4dbfd;
  padding: 18px 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.setting-tab-container {
  border-radius: 20px;
  background: #fff;
}

.setting-tab-title {
  background-color: #e4dbfd;
  padding: 18px 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.setting-tab-body {
  padding: 20px;
  box-shadow: 0 8px 20px #7f52fb40;
  border-radius: 10px;
}

.settingsColor .MuiSelect-select .MuiBox-root > div > svg {
  display: none;
}

.settingColorOptions > div > svg {
  fill: rgb(78, 146, 25);
}

.pro_bsi_dtls {
  border: 1px solid #e4dbfd !important;
  border-radius: 20px;
  /*box-shadow: 0 4px 14px rgba(127,82,251,0.25);*/
  background: #fff;
  padding: 20px !important;
  margin-bottom: 22px;
}

.pro_bsi_dtls .user-label {
  font-size: 18px;
  margin-bottom: 10px;
}

.pro_bsi_dtls .MuiFormControl-root label[data-shrink='true'] {
  color: #7349e6;
}
.pro_bsi_dtls .MuiFormControl-root input {
  color: #555;
  font-size: 15px;
}

.pro_bsi_dtls .MuiFormControl-root label[data-shrink='false'] {
  font-size: 15px;
  color: #555;
}

.pro_bsi_dtls .MuiFormControl-root fieldset {
  border-color: #e4dbfd !important;
}

.settings_tax_head {
  margin: 16px 0 0 0;
  background: #e4dbfd;
  padding: 13px;
  border-radius: 10px;
}

p.settings_tax_bar {
  font-size: 16px;
  color: #121212;
  font-weight: 500;
  text-align: center;
}

.tax_map_data {
  padding: 5px;
  border: 1px solid #e4dbfd;
  border-radius: 10px;
  margin: 10px 0 10px 0;
}

.logo_expend_min {
  margin: 0px;
  border: 1px solid #e4dbfd !important;
  border-radius: 20px;
  box-shadow: 0 4px 14px rgba(127, 82, 251, 0.25);
  background: #fff;
  padding: 20px !important;
}

.se_tbs_upp_wrapper .logo_expend_min {
  box-shadow: none !important;
  border: none !important;
  padding: 5px !important;
}

.logo_expend_min .user-label {
  font-size: 18px;
  margin-bottom: 10px;
}

.logo_expend_min .drag-drop-thumbnail {
  object-fit: contain;
  padding: 4px;
  border: none;
  width: 100%;
  height: auto;
  max-width: 280px;
  display: block;
  margin: 0 auto;
  max-height: 280px;
}

.logo_expend_min .MuiGrid-root .MuiBox-root {
  border-radius: 10px;
  border: 2px dashed #e4dbfd;
}

.logo_expend_min .MuiGrid-root .MuiBox-root .MuiBox-root {
  border: none;
  border-radius: 0;
  position: relative;
}

.logo_expend_min .MuiGrid-root .MuiBox-root svg {
  width: 80px;
  height: 80px;
}

.logo_expend_min .MuiGrid-root .MuiBox-root button.MuiButtonBase-root {
  background: #7349e6;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  text-transform: capitalize;
  padding: 8px 40px;
  border-radius: 10px;
}

.logo_expend_min
  .MuiGrid-root
  .MuiBox-root
  button.MuiButtonBase-root
  svg[data-testid='CloseIcon'] {
  width: 24px;
  height: 24px;
}

.logo_expend_min
  .MuiGrid-root
  .MuiBox-root
  button.MuiButtonBase-root.MuiIconButton-sizeMedium {
  padding: 0;
  width: 34px;
  height: 34px;
  border-radius: 5px;
  background: #ff6a6a;
}

.bk_fm_fld > .MuiBox-root {
  margin-top: 0;
}

.bk_fm_fld .MuiFormControl-root {
  margin-top: 0;
}

.pro_bsi_dtls.oths_cls {
  padding-top: 0 !important;
  margin-top: 22px;
}

.pro_bsi_dtls .user-label + label {
  margin-bottom: 10px;
  margin-right: 0;
}

.pro_bsi_dtls .user-label + label span.MuiButtonBase-root.MuiCheckbox-root {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 5px;
}

.pro_bsi_dtls .user-label + label span.MuiTypography-root {
  font-size: 13px;
  font-weight: 500;
  color: #555;
}
.spc {
  margin-top: 8px;
}

.pro_bsi_dtls svg {
  fill: #7349e6;
}

.pro_bsi_dtls.scl_dtls
  .MuiGrid-root.MuiGrid-container
  .MuiGrid-root.MuiGrid-item
  .MuiFormControl-root {
  margin-top: 5px;
  margin-bottom: 0;
}

.pro_bsi_dtls.oths_cls
  .MuiGrid-root.MuiGrid-container
  .MuiGrid-root.MuiGrid-item
  .MuiFormControl-root {
  margin-top: 5px;
  margin-bottom: 0;
}

.app-settng-fld-ouetr {
  border: 1px solid #e4dbfd !important;
  border-radius: 20px;
  box-shadow: 0 4px 14px rgba(127, 82, 251, 0.25);
  background: #fff;
  padding: 20px !important;
  margin-bottom: 22px;
}

.app-settng-fld-ouetr fieldset {
  border-color: #e4dbfd !important;
}

.app-settng-fld-ouetr .custom-tag-input label[data-shrink='true'],
.app-settng-fld-ouetr label[data-shrink='true'] {
  color: #7349e6;
}

.app-settng-fld-ouetr label[data-shrink='false'] {
  color: #555;
  font-size: 15px;
}

.app-settng-fld-ouetr .custom-tag-input .MuiInputBase-root {
  min-height: 55px;
}

.app-settng-fld-ouetr
  .custom-tag-input
  .MuiInputBase-root
  .MuiButtonBase-root.MuiChip-root.MuiChip-filled {
  font-size: 13px;
  font-weight: 500;
}

.app-settng-fld .MuiBox-root {
  margin-bottom: 25px;
}

.app-settng-fld .MuiBox-root:last-child {
  margin-bottom: 10px;
}

.app-settng-fld-ouetr input {
  font-size: 15px;
}

.choose-colo_cs {
  border: 1px solid #e4dbfd !important;
  border-radius: 20px;
  box-shadow: 0 4px 14px rgba(127, 82, 251, 0.25);
  background: #fff;
  padding: 20px !important;
}

.choose-colo_cs p.MuiTypography-root {
  font-size: 18px;
}

.choose-colo_cs .circle-picker {
  width: auto !important;
}

.crrcy_cnyt_noted {
  flex-wrap: wrap;
  padding-left: 10px;
  margin-bottom: 10px;
}
.note_cs.MuiBox-root {
  width: 100%;
}

.note_cs.MuiBox-root p.MuiTypography-root {
  font-weight: 500 !important;
  color: #7349e6 !important;
}

.note_cs_crrn,
.note_cs_crrn_ttl {
  font-size: 20px !important;
}

.dys_slect_bx {
  border: 1px solid #e4dbfd !important;
  border-radius: 20px;
  box-shadow: 0 4px 14px rgba(127, 82, 251, 0.25);
  background: #fff;
  padding: 20px !important;
  margin-left: 0px;
}

.dys_slect_bx .user-label {
  font-size: 18px;
  margin-bottom: 10px;
}

.dys_slect_bx .MuiGrid-item .MuiFormGroup-root label {
  background: #fff;
  border: 1px solid #e4dbfd !important;
  margin: 6px 0;
  padding: 5px 10px;
  border-radius: 15px;
}

.dys_slect_bx.allow-appoint-days
  .MuiGrid-root.MuiGrid-container
  .MuiGrid-root.MuiGrid-container
  .MuiGrid-root.MuiGrid-item {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  padding-left: 6px !important;
}

.dys_slect_bx.allow-appoint-days .MuiGrid-item .MuiFormGroup-root label {
  border-radius: 0;
  border: none !important;
}

.dys_slect_bx.allow-appoint-days
  .MuiGrid-item
  .MuiFormGroup-root
  label
  span.MuiSwitch-root {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
}

.dys_slect_bx.allow-appoint-days
  .MuiGrid-item
  .MuiFormGroup-root
  label
  span.MuiTypography-root {
  font-size: 15px;
}

/*---profile-setting----*/

.prof_sett_wrpa {
  background: #fff;
  padding: 20px;
  border: 1px solid #e4dbfd !important;
  border-radius: 20px;
}

.prof_sett_wrpa .prof-nonedit-container {
  border-bottom: 1px solid #576aa4;
  padding: 5px;
}

.cm_dls_text p.MuiTypography-root:first-child {
  min-width: 185px;
  padding-right: 15px;
}

.cm_dls_text p.MuiTypography-root {
  margin: 4px 0 6px 0 !important;
}

.all_pfle_dtls_edt {
  margin-top: 20px;
  padding: 20px 20px 40px 20px;
}

.user-label.biger {
  font-size: 22px;
  margin-bottom: 12px;
}

.all_pfle_dtls_edt .user-label.biger {
  font-size: 22px;
  margin-bottom: 12px;
}

/* .all_pfle_dtls_edt .user-label {
  font-size: 17px;
} */

.all_pfle_dtls_edt .user-label + label {
  margin-right: 6px;
}

.all_pfle_dtls_edt .user-label + label span.MuiTypography-root {
  font-size: 15px;
  color: #555;
}

.notifi_mssge {
  padding-top: 12px;
}

.notifi_mssge .MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-12 {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

/* .prfl_pics .MuiGrid-root .MuiBox-root {
  border: 2px dashed #e4dbfd;
  border-radius: 10px;
} */

.prfl_pics
  .MuiGrid-root
  .MuiBox-root
  button.MuiButtonBase-root.MuiButton-outlined {
  background: #7349e6;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  text-transform: capitalize;
  padding: 8px 40px;
  border-radius: 10px;
}

.sign_pics {
  margin: 15px 0;
}

.sign_pics .user-label {
  margin-bottom: 15px !important;
}

.sign_pics .quill.signature .ql-container.ql-snow {
  border: 2px dashed #e4dbfd;
  border-radius: 0px 0px 10px 10px;
}
.sign_pics .ql-editor.ql-blank::before {
  font-size: 15px;
  font-weight: 500;
}

.sign_pics .quill.signature .ql-container.ql-snow p {
  font-style: normal !important;
  font-size: 15px;
}

.sign_pics .quill.signature .ql-toolbar.ql-snow {
  background: #e4dbfd;
  border: 2px dashed #e4dbfd;
  border-bottom: none;
  border-radius: 10px 10px 0 0;
}

.sign_pics .quill.signature .ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: inherit;
}

.bll_addss span.MuiButtonBase-root.MuiCheckbox-root {
  padding-top: 0;
  padding-bottom: 0;
}

.all_pfle_dtls_edt fieldset {
  border-color: #e4dbfd !important;
}

.all_pfle_dtls_edt label[data-shrink='true'] {
  color: #7349e6 !important;
}

.all_pfle_dtls_edt input {
  font-size: 15px;
  color: #121212;
}

.all_pfle_dtls_edt label[data-shrink='false'] {
  color: #121212;
  font-size: 15px;
}

.MuiDialogContent-root .MuiInputBase-root input {
  border-radius: 10px;
  font-size: 15px;
  color: #212121;
  /* font-weight: 500; */
}

.MuiDialog-root
  .MuiDialog-container
  .MuiPaper-rounded
  .MuiDialogContent-root
  form
  .MuiFormControl-root
  fieldset {
  border-radius: 10px;
}

.book_edit_pop label {
  font-size: 16px;
  font-weight: 600;
}

.tpe_answ p.MuiTypography-root {
  font-size: 16px;
  font-weight: 600;
}

.tpe_answ p.MuiTypography-root.requ {
  font-size: 15px;
  font-weight: 400;
  color: #555;
}

.tpe_answ {
  align-items: center;
}

.tpe_answ
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-12.MuiGrid-grid-md-4
  .MuiFormGroup-root
  label {
  margin-right: 0;
}

.tpe_answ
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-12.MuiGrid-grid-md-4
  .MuiFormGroup-root
  label
  span.MuiSwitch-root {
  margin-right: 3px;
  margin-bottom: 0;
  margin-top: 0;
}

.book_edit_pop .data-added-nmb {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}

.book_edit_pop .data-added-nmb button.MuiButtonBase-root {
  width: auto;
  min-width: inherit;
  float: right;
  padding: 0 5px;
}

.ans-enrt .MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-12.MuiGrid-grid-md-2 {
  padding-left: 0;
}

.MuiDialogContent-root .ans-enrt .MuiInputBase-root input + fieldset {
  border-radius: 10px 0 0 10px !important;
  box-shadow: 0 6px 16px rgba(25, 155, 203, 0.15);
}

.MuiDialogContent-root .ans-enrt .MuiInputBase-root input {
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 0 0 0 0 !important;
}

.ans-enrt
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-12.MuiGrid-grid-md-2
  button.MuiButtonBase-root {
  border-radius: 0 10px 10px 0 !important;
  height: 51.56px;
  width: 100%;
  font-size: 15px;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 0 6px 16px rgba(25, 155, 203, 0.15) !important;
}

.ans-enrt {
  margin-bottom: 8px;
}

.sch_btn_act button.MuiButtonBase-root {
  text-transform: capitalize;
  font-weight: 400;
  border-radius: 10px;
}

.sch_btn_act .slct-actions {
  /* width: auto; */
  border-radius: 10px;
}

.sch_btn_act .slct-actions div#actions {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
  font-size: 15px;
}

.sch_btn_act .slct-actions fieldset {
  border: none !important;
}

.sch_btn_act .slct-actions svg {
  fill: #fff;
}

.sch_btn_act .slct-actions label[data-shrink='true'] {
  background: #7349e6;
  color: #fff !important;
}

.de_cls button.MuiButtonBase-root {
  text-transform: capitalize;
  font-size: 15px;
  border-radius: 10px;
}

.de_cls button.MuiButtonBase-root + button.MuiButtonBase-root {
  margin-right: 2px;
}

.sign_pics_tile .user-label {
  margin-bottom: 12px;
}

.prfl_pics_pd .MuiBox-root .MuiBox-root {
  border: 2px dashed #e4dbfd;
  border-radius: 10px;
  margin-top: 0;
}

.prfl_pics_pd
  .MuiBox-root
  .MuiBox-root
  button.MuiButtonBase-root.MuiButton-outlined {
  background: #7349e6;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  text-transform: capitalize;
  padding: 8px 40px;
  border-radius: 10px;
}

.unit-measure > .MuiBox-root {
  margin-top: 5px;
  margin-bottom: 15px;
}

.prod_nmect .MuiFormControl-root {
  margin-top: 7px;
}

.tbs_user_info form .MuiFormControl-root label[data-shrink='true'] {
  color: #7349e6 !important;
}

.tbs_user_info form .MuiFormControl-root div#type {
  font-weight: 400;
  color: #555;
  font-size: 15px;
}
.bllng_addss
  label.MuiFormControlLabel-root
  span.MuiButtonBase-root.MuiCheckbox-root {
  background: none !important;
  padding-top: 0;
  padding-bottom: 0;
}
.bllng_addss label.MuiFormControlLabel-root {
  margin-right: 6px;
}

.bllng_addss label.MuiFormControlLabel-root span.MuiTypography-root {
  font-size: 15px;
}

.typ-clnt > .MuiBox-root {
  margin-top: 0;
  margin-bottom: 7px;
}

.typ-clnt-deals button.MuiButtonBase-root {
  padding: 2px !important;
  border-radius: 0 !important;
}

.th-bsinnss-accnt .MuiFormControl-root.MuiFormControl-fullWidth {
  margin-top: 0;
}
.billing_his_client .date_pym_cls.MuiBox-root svg {
  margin-right: 0;
}

.role_user
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-12
  > .MuiBox-root
  > .MuiFormControl-root {
  width: 100%;
}

/* .MuiDialogContent-root button.MuiButtonBase-root {
  border-radius: 10px;
  text-transform: capitalize;
} */

button.MuiButtonBase-root:hover {
  box-shadow: none !important;
}

.export_form_cs {
  text-align: center;
}

.export_form_cs:first-child {
  margin-bottom: 10px;
}

.prfl_pics_modal label .MuiBox-root {
  border: 2px dashed #e4dbfd;
  border-radius: 10px;
}

.prfl_pics_modal label .MuiBox-root button.MuiButtonBase-root {
  background: #7349e6;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  text-transform: capitalize;
  padding: 8px 40px;
  border-radius: 10px;
}

.prfl_pics_pd .MuiBox-root .MuiBox-root .MuiBox-root {
  border: none;
  border-radius: 0;
}

.MuiFormControl-root fieldset {
  border-color: #e4dbfd !important;
  border-radius: 20px !important;
}

.tile_ed_ppossl button.MuiButtonBase-root svg {
  width: 18px;
  height: 18px;
}

.tile_ed_ppossl button.MuiButtonBase-root {
  margin-left: 0;
  padding: 5px;
  border-radius: 5px;
}

.sv-tm p.MuiTypography-root {
  font-size: 15px;
  font-weight: 500;
}

.sv-tm svg {
  fill: #7349e6;
}

.title_form_proposal {
  padding: 5px 20px 30px 20px;
  border: 1px solid #e4dbfd;
  border-radius: 10px;
  margin: 10px 0 20px 0;
}

.user-label span {
  color: #555555;
  font-size: 15px;
  font-weight: 500;
}

.uplo_fie label .MuiBox-root {
  border: 2px dashed #e4dbfd;
  border-radius: 10px;
}

.uplo_fie label .MuiBox-root button.MuiButtonBase-root.MuiButton-outlined {
  background: #7349e6;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  text-transform: capitalize;
  padding: 8px 40px;
  border-radius: 10px;
}

.rdio_gp_cs label#logoLocation {
  display: none;
}

.MuiGrid-root.uplo_fie {
  margin-bottom: 16px;
}

.rdio_gp_cs .MuiFormControl-root {
  width: 100%;
}

.rdio_gp_cs
  .MuiFormGroup-root
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-4 {
  max-width: inherit;
  flex-basis: inherit;
  -webkit-flex-basis: inherit;
  -ms-flex-preferred-size: inherit;
}

.bx-fl-up label[for='file'] .MuiBox-root {
  border: 2px dashed #e4dbfd;
  border-radius: 10px;
}

.bx-fl-up
  label[for='file']
  .MuiBox-root
  button.MuiButtonBase-root.MuiButton-outlined {
  background: #7349e6;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  text-transform: capitalize;
  padding: 8px 40px;
  border-radius: 10px;
}

.title_form_proposal .ql-toolbar.ql-snow {
  background: #e4dbfd;
  border: 2px dashed #e4dbfd;
  border-bottom: none;
  border-radius: 10px 10px 0 0;
}

.title_form_proposal .quill.signature .ql-container.ql-snow {
  border: 2px dashed #e4dbfd;
  border-radius: 0px 0px 10px 10px;
}

.title_form_proposal
  .quill.signature
  .ql-toolbar.ql-snow
  + .ql-container.ql-snow {
  border-top: inherit;
}

.title_form_proposal .sign_pics .quill.signature .ql-container.ql-snow p {
  font-style: normal !important;
  font-size: 15px;
}

.title_form_proposal .ql-editor.ql-blank::before {
  font-size: 15px;
  font-weight: 500;
}

.title_form_proposal label[data-shrink='true'] {
  color: #7349e6 !important;
}

.edt_pspl_header {
  padding: 0 16px;
}

.edt_pspl_header .MuiBox-root {
  align-items: center;
}

.edt_pspl_header h4.MuiTypography-root {
  font-size: 24px;
  margin: 0;
}

.pspl_heade_btn button.MuiButtonBase-root {
  border-radius: 10px;
  text-transform: capitalize;
  font-size: 15px;
  padding: 10px 18px;
  /* font-weight: normal; */
}

.pspl_heade_btn button.MuiButtonBase-root svg {
  margin-right: 6px;
}

.MuiDialogContent-root .circle-picker {
  width: auto !important;
}

.login-form h6.MuiTypography-root,
.register-form h6.MuiTypography-root {
  font-weight: 500;
}

.client_pro_are_left .slect_view div#new {
  background: #7349e6;
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: inherit;
  height: auto;
  color: #fff;
  border-radius: 10px;
}

.list-client-note .client-user-details.nts_cs {
  border-radius: 10px 10px 0 0;
  padding: 0 5px 10px 13px;
}

.remove_notes_fetech button.MuiButtonBase-root {
  background: none !important;
  padding: 5px;
  min-width: inherit;
}

.list-client-note .client-user-details.nts_cs > .MuiGrid-root {
  margin-left: 0;
  /* margin-right: 0; */
  padding-right: 16px;
  align-items: center;
  padding: 8px 0px 0 0px;
  width: auto;
  margin-top: -16px;
}

.list-client-note .client-user-details.nts_cs > .MuiGrid-root .use_nm_ftch {
  background: #ffedcc;
  font-size: 12px;
  font-weight: 500;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 30px;
  letter-spacing: -1px;
  color: #000;
}

.list-client-note .client-user-details.nts_cs .not_bx_inner_cls {
  float: left;
  width: calc(100% - 65px);
  padding: 0 13px;
}

.list-client-note .client-user-details.nts_cs .remove_notes_fetech {
  width: 30px;
  text-align: right;
  float: left;
}
.list-client-note .client-user-details.nts_cs .remove_notes_fetech img {
  width: 19px;
}

.not_bx_inner_cls p.MuiTypography-root {
  font-size: 13px;
  color: #555555;
  font-weight: 500;
}

.not_bx_inner_cls p.MuiTypography-root.nts_nm {
  font-size: 15px;
  color: #121212;
  font-weight: 600;
}

.note-attachment .MuiGrid-root.MuiGrid-item p.MuiTypography-root {
  color: #555;
  font-size: 13px;
  margin-bottom: 12px;
  font-weight: 500;
  word-break: break-word;
}

.note-attachment .MuiGrid-root.MuiGrid-item {
  padding-right: 16px;
}

.note-attachment .MuiGrid-root.MuiGrid-item img {
  height: 55px;
  width: 55px;
  object-fit: cover;
  border-radius: 7px;
  border: 1px solid #ccc;
  /* object-position: top left; */
}
.user-label_inet_nts_edit .note-attachment {
  padding: 0 0 10px 20px !important;
}

.user-label_inet_nts_edit .note-attachment .MuiGrid-root.MuiGrid-container {
  margin-top: -16px;
}

.layout-container-quote .MuiTabs-root button.MuiButtonBase-root {
  padding: 8px 18px;
  margin-right: 12px;
  background: #e4dbfd;
  color: #121212;
}

.layout-container-quote .MuiTabs-root button.MuiButtonBase-root.Mui-selected {
  background: #7349e6;
  color: #fff;
}

.layout-container-quote
  .MuiTabs-root
  button.MuiButtonBase-root
  button.MuiButtonBase-root {
  padding: 0;
  min-width: 24px;
  margin: 0 0 0 5px;
  background: none !important;
}

.layout-container-quote
  .MuiTabs-root
  button.MuiButtonBase-root
  button.MuiButtonBase-root {
  padding: 0;
  min-width: 30px;
  margin: 0;
  background: none !important;
}

.layout-container-quote
  .MuiTabs-root
  button.MuiButtonBase-root
  button.MuiButtonBase-root
  svg {
  width: 20px;
  height: 20px;
}

.layout-container-quote
  .MuiTabs-root
  button.MuiButtonBase-root.Mui-selected
  button.MuiButtonBase-root
  svg {
  fill: #fff;
}

.layout-container-quote .MuiTabs-root span.MuiTabs-indicator {
  display: none !important;
}

.layout-container-quote
  .MuiTabs-root
  + button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined {
  background: #e4dbfd;
  padding: 8px;
  min-width: 48px !important;
  color: #000;
  font-size: 22px;
  min-height: 47px;
  top: 3px;
  border: none;
}

.tbs_added_quote {
  padding-bottom: 15px;
  border-bottom: 1px solid #e4dbfd !important;
}

.quite-se-tit {
  padding-top: 0 !important;
}

.quite-se-tit .MuiFormControl-root {
  margin-top: 0;
}

.quote_item_name_main {
  margin-right: 16px;
  border: 1px solid #e4daff;
  padding: 15px;
  border-radius: 8px;
  margin-top: 15px;
}

.quote_item_name_main
  .quote_item_name
  .MuiAutocomplete-root
  button.MuiButtonBase-root {
  padding: 2px;
  background: none !important;
}

.quote_item_name .MuiAutocomplete-root + button.MuiButtonBase-root {
  margin-left: 12px;
  padding: 8px !important;
  min-width: 56px;
  border-radius: 8px;
}
.quote_item_name .MuiAutocomplete-root + button.MuiButtonBase-root svg {
  width: 26px;
  height: 26px;
}

.quote-edit-box {
  margin-top: 10px;
}

.quote_item_name {
  margin-top: 1px !important;
}

.line_total_bt button.MuiButtonBase-root {
  /* min-width: 20px; */
  padding: 0;
  border-radius: 0;
  background: none !important;
  margin: 8px 0 0 10px;
}

.line_total_bt button.MuiButtonBase-root svg {
  fill: #f00;
  /* width: 22px; */
  /* height: 22px; */
}

.layout-container-quote div#simple-tabpanel-0 > .MuiBox-root {
  padding-right: 0;
  padding-left: 16px;
}

.item_new_box_added {
  padding-bottom: 0 !important;
}

span.smll_pc {
  background: #e4dbfd;
  font-size: 14px;
  padding: 3px 10px;
  border-radius: 5px;
  line-height: 1;
  font-weight: 500;
  margin-left: 5px;
}

.add_nw_itm_frm button.MuiButtonBase-root {
  margin-right: 15px;
  /* font-weight: 400; */
}

.profit-mgn-pgss {
  border: 1px solid #e4daff;
  width: calc(100% - 16px);
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 10px;
  margin-left: 16px;
  margin-top: 10px;
}

.profit-mgn-pgss .MuiGrid-root.MuiGrid-container {
  padding-left: 10px;
  padding-right: 10px;
}

.rd_scep {
  padding-top: 5px !important;
}

span.MuiSlider-valueLabelOpen {
  top: -2px !important;
}

.pc_tble_totl table.MuiTable-root th,
.pc_tble_totl table.MuiTable-root td {
  border: 1px solid #e4dbfd !important;
}

.pc_tble_totl table.MuiTable-root th:first-child {
  background: #f8f5ff;
}

.pc_tble_totl .MuiPaper-root {
  border: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}

.dlet_sct {
  margin-bottom: 16px;
}

.quote_tabs_inner {
  border: 1px solid #e4daff;
  width: 100% !important;
  margin: 15px 0 12px 14px !important;
  padding: 20px 16px 20px 0;
  border-radius: 10px;
}

.quote_tabs_indx_inner {
  border: 1px solid #e4daff;
  width: 100% !important;
  margin: 15px 0 24px 15px !important;
  padding: 20px 16px 20px 0;
  border-radius: 10px;
}

.dlet_sct {
  margin-top: -16px;
}

.tab_prce_nem {
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.tab_prce_nem .MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-12 {
  padding-left: 0;
}

.tab_prce_nem .user-label {
  font-weight: 500;
  font-size: 15px;
}
.tab_prce_nem .user-label + .MuiFormControl-root.MuiFormControl-fullWidth {
  margin-top: 9px;
  margin-bottom: 0;
}

.tab_prce_nem
  .MuiFormControl-root.MuiFormControl-fullWidth
  label[data-shrink='true'] {
  color: #7349e6 !important;
}

.client_request_icons svg.client-navicon {
  width: 45px;
  height: 45px;
  padding: 8px;
  margin-right: 0;
}

.MuiGrid-root.MuiGrid-item.client_request_icons {
  -webkit-flex-basis: inherit;
  -ms-flex-preferred-size: inherit;
  flex-basis: inherit;
  max-width: inherit;
  width: calc(50px + 16px);
}

.MuiGrid-root.MuiGrid-item.client_request_descb {
  -webkit-flex-basis: inherit;
  -ms-flex-preferred-size: inherit;
  flex-basis: inherit;
  max-width: inherit;
  width: calc(100% - 70px);
}
.MuiGrid-root.MuiGrid-item.client_request_descb .MuiTypography-root {
  font-weight: 500;
  color: #555;
  font-size: 15px;
}

.MuiGrid-root.MuiGrid-item.client_request_descb button.MuiButtonBase-root {
  background: #f2edff;
  color: #121212;
  border-radius: 6px;
  padding: 5px 10px;
  line-height: 1.2;
  margin-top: 3px;
  border: 1px solid #e4daff;
  font-size: 13px;
}

.jb-cs-sts .MuiTypography-root.overview_txt {
  font-size: 15px;
  color: #121212;
}

.jb-cs-sts .quote_status {
  border-radius: 6px;
  padding: 5px 10px;
  line-height: 1;
  font-size: 13px;
  /* width: auto; */
}

.dt-sts p.MuiTypography-root {
  color: #555;
  font-size: 14px;
  font-weight: 400;
}

.ov-cs-sts p.overview_txt {
  max-width: 100%;
  white-space: normal;
  overflow: visible;
  text-overflow: inherit;
  text-transform: inherit;
  font-size: 15px;
  color: #555;
  margin-bottom: 5px;
}

.ov-cs-sts p.MuiTypography-root {
  font-size: 15px;
  color: #555;
}

.pc_bx_sts_request {
  width: 125px;
  text-align: center;
  border-radius: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 6px;
  font-size: 14px !important;
  margin: 2px 0 0 0;
  text-transform: capitalize;
  color: #685ae8;
  background-color: #eeedf2;
}

.pc_bx_sts p.MuiTypography-root {
  text-align: right;
  line-height: 1.2;
  font-size: 15px;
  font-weight: 500;
}

.quote_tbas_des .MuiGrid-root.MuiGrid-container {
  align-items: flex-start !important;
  margin-bottom: 0;
}

label.to_hed {
  font-weight: 600;
}

.em_pop_des .MuiBox-root {
  margin-top: 10px;
  margin-bottom: 10px;
}

.em_pop_des label[for='file'] .MuiBox-root {
  margin-top: 0;
  border: 2px dashed #e4dbfd;
  border-radius: 10px;
}

.em_pop_des label[for='file'] .MuiBox-root {
  margin-top: 0;
  border: 2px dashed #e4dbfd;
  border-radius: 10px;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 200px;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
}

.em_pop_des label[for='file'] .MuiBox-root p.MuiTypography-root {
  font-size: 1rem;
  font-weight: 600;
  padding: 10px;
}

.em_pop_des label[for='file'] .MuiBox-root button.MuiButtonBase-root,
.att-file label[for='file'] .MuiBox-root button.MuiButtonBase-root {
  background: #7349e6;
  font-size: 16px;
  font-weight: normal;
  text-transform: capitalize;
  padding: 8px 40px;
  border-radius: 10px;
  color: #fff !important;
}

.addd_pp_ne_cc button.MuiButtonBase-root {
  height: 54px;
  margin-top: 0 !important;
}

.fl_rd-spc {
  padding-top: 0 !important;
}

.att-file label[for='file'] .MuiBox-root {
  border: 2px dashed #e4dbfd;
  border-radius: 10px;
}

.filter_job_sche .MuiAccordionDetails-root > .MuiBox-root > .MuiBox-root {
  height: auto;
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 0 !important;
}

.clent_edt_ppup
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-md-6
  > .MuiFormControl-root {
  margin-top: 0;
  margin-bottom: 0;
}

.clent_edt_ppup .MuiGrid-root.MuiGrid-item.MuiGrid-grid-md-6 > .MuiBox-root {
  margin-top: 0;
}

.bs_acc-pp {
  padding-top: 0 !important;
}
.sw_ac_btn_cs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 10px;
}

.project_status_edit + .MuiFormControl-root.MuiTextField-root {
  width: 100%;
}

.prfl_pics_pd .drag-drop-thumbnail + button.MuiButtonBase-root {
  padding: 8px;
  border-radius: 5px;
}

.appt-reqs-data button:after {
  content: '';
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  right: -4px;
  background: #7349e6;
  transform: rotate(45deg);
}

.layout-container.user_main_container .MuiBox-root.serach_reate_area {
  background: #fff !important;
  padding: 16px;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(127, 82, 251, 0.25);
  border: 1px solid #e4dbfd !important;
}

.notification_set_setting
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-12
  .MuiFormGroup-root {
  width: 33.33%;
  padding: 0 15px 0 5px;
  margin-bottom: 23px;
}

.notification_set_setting .MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-12 {
  display: flex;
  flex-wrap: wrap;
  /* gap: 29px; */
}

.quote_tbas_des {
  margin-bottom: 16px;
  border: 1px solid #e4dbfd;
  border-radius: 10px;
  padding: 12px 15px 10px 12px;
  cursor: pointer;
}

.quote_tbas_des:hover {
  background: #e4dbfd;
}

.quote_tbas_des:last-child {
  margin-bottom: 0;
}

.discount_diposit_section {
  display: flex;
  justify-content: space-between;
}

.discount_diposit_section .discount_diposite_title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
}

.discount_diposite_SelectBox .css-1q1lg23 {
  margin-top: 0px;
}

.delete_discount_diposit_section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete_discount_diposit_section > button {
  padding: 0;
  border-radius: 0;
  background: none !important;
}

.delete_discount_diposit_section > button > svg {
  fill: #f00;
}

.quote_subtotal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
}

.quote_total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #e4daff;
}

.tax_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
}

.tax_section .tax_value {
  width: 90%;
  border: 1px solid #e4dbfd;
  padding: 10px;
  border-radius: 8px;
}

.tax_section .css-1q1lg23 {
  width: 90%;
  margin-top: 0px;
}

.nmlstatus {
  border-radius: 6px;
  padding: 5px 10px;
  line-height: 1;
  font-size: 13px;
  width: 100px !important;
}

.dt-sts p.MuiTypography-root:first-child {
  margin-bottom: 0;
}

.ov-cs-sts p.MuiTypography-root:first-child {
  margin-bottom: 0;
}

.invoic_tb_outer table {
  border-spacing: 0;
  border: 1px solid #e4dbfd;
}

.invoic_tb_outer table tr th {
  border: none;
  font-size: 16px;
  font-weight: 600;
  background: #e4dbfd;
  padding: 10px 15px;
  border-top: 1px solid #e4dbfd;
  border-bottom: 1px solid #e4dbfd;
  max-width: inherit;
}

.invoic_tb_outer table tr td {
  border: none;
  padding: 13px 15px;
  font-size: 14px;
  color: #555555;
  font-weight: 500 !important;
  background: #fff;
  border-top: 1px solid #e4dbfd;
  border-bottom: 1px solid #e4dbfd;
  max-width: inherit;
}

.ri_box_cols_neer {
  align-items: center;
}

.ri_box_cols_client .ri_box_cols_neer button.MuiButtonBase-root {
  border-radius: 8px;
}

.prp-dt-tb table .row_data {
  overflow: visible;
  white-space: normal;
  text-overflow: inherit;
}

.cal-sts-filter
  .MuiPaper-root.MuiPaper-rounded.MuiAccordion-root
  .appt-reqs-data
  > .MuiBox-root:last-child {
  margin-bottom: 0;
}

.cal-sts-filter
  .MuiPaper-root.MuiPaper-rounded.MuiAccordion-root
  .appt-reqs-data
  > .MuiBox-root:last-child
  .appt-req-btn.MuiBox-root {
  margin-bottom: 0;
}

.cal-sts-filter
  .MuiPaper-root.MuiPaper-rounded.MuiAccordion-root
  .appt-reqs-data {
  box-shadow: none;
  padding: 0px 7px 11px 0;
  margin-top: 15px;
  max-height: 250px;
  overflow-y: auto;
  height: auto;
  border-radius: 0;
}

.appt-reqs-data button:hover:after {
  background-color: rgb(80, 51, 161);
}

.pro_bsi_dtls:last-child {
  margin-bottom: 5px;
}

.logo_expend_min.see_fl {
  margin: 0;
}

.setting-tabs_nml {
  border-bottom: 1px solid #e4dbfd !important;
}

.tax-stting-top {
  align-items: center;
  margin-top: -16px;
}

.tax-stting-top .user-label {
  margin: 0;
}

.settings_tax_head + .MuiGrid-container .MuiFormControl-root {
  width: 100%;
}

.tax_map_data_inner {
  align-items: center;
}

.tax_rate_cls_mdl {
  padding-top: 0 !important;
}

.tax_rate_cls_mdl_ch {
  padding-top: 0 !important;
}

.tax_rate_label {
  font-size: 15px;
  font-weight: 500;
  color: #000;
}

.custom_page_rdi_cls {
  border: 1px solid #e4dbfd;
  border-radius: 10px;
}
.custom_page_rdi_cls label.MuiFormLabel-root .MuiBox-root {
  margin-top: 0;
}

.quill.signature .ql-container.ql-snow {
  border: 2px dashed #e4dbfd;
  border-radius: 0px 0px 10px 10px;
}

.ql-toolbar.ql-snow {
  background: #e4dbfd;
  border: 2px dashed #e4dbfd;
  border-bottom: none;
  border-radius: 10px 10px 0 0;
}

.waaarrnty_cs .user-label {
  margin-top: -16px;
}

.fb-up label .MuiBox-root {
  border: 2px dashed #e4dbfd;
  border-radius: 10px;
}

.fb-up label .MuiBox-root button.MuiButtonBase-root.MuiButton-outlined {
  background: #7349e6;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  text-transform: capitalize;
  padding: 8px 40px;
  border-radius: 10px;
}

.add_newima_txt .add_nw_stin_clck label .MuiBox-root {
  border: 2px dashed #e4dbfd;
  border-radius: 10px;
}

.bx_rd_spce .user-label {
  font-size: 15px;
}

.bx_rd_spce .MuiFormControl-root {
  margin-top: 4px;
}

.bx_rd_spce .user-label + .MuiBox-root {
  margin-top: 4px;
}

.jb-sche-hdr .user-label.MuiBox-root {
  max-width: 500px;
}

.jb-sche-hdr button.MuiButtonBase-root {
  border-radius: 8px;
  font-weight: normal;
  text-transform: capitalize;
  padding: 8px 18px;
}

/*-----header-stylinng----*/

header.MuiPaper-root.mui-fixed
  button.MuiButtonBase-root[aria-label='open drawer'] {
  left: -10px;
  top: 45px;
  background: #fff !important;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25) !important;
}

header.MuiPaper-root.mui-fixed
  button.MuiButtonBase-root[aria-label='open drawer']
  svg {
  fill: #7349e6;
}

header.MuiPaper-root.mui-fixed.close_header_bar {
  width: calc(100% - 56px);
  margin-left: 56px;
}

/* Home Owner */
header.MuiPaper-root.mui-fixed
  button.MuiButtonBase-root[aria-label='homeOwner open drawer'] {
  left: -25px;
  top: 15px;
  background: #fff !important;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25) !important;
  padding: 2px;
}

header.MuiPaper-root.mui-fixed
  button.MuiButtonBase-root[aria-label='homeOwner open drawer']
  > svg {
  fill: #4b4d5f;
}

header.MuiPaper-root.mui-fixed.homeOwner_close_header_bar {
  width: calc(100% - 145px);
  margin-left: 56px;
}

.arrowToggle {
  fill: #000;
}

.toggle_rotate {
  rotate: 180deg;
}

/* .MuiDialog-container label[for='file'] .MuiBox-root {
  border: 2px dashed #e0e0e0;
  border-radius: 10px;
} */

.up-docs_main-inner {
  background: #fff;
  padding: 20px;
  border: 1px solid #e4daff !important;
  border-radius: 20px;
  box-shadow: 0 8px 20px rgba(127, 82, 251, 0.25);
}

.up-docs_main.MuiBox-root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  border-bottom: 1px solid #e4daff !important;
  padding-bottom: 15px;
}

.up-docs_main.MuiBox-root .user-label {
  font-size: 22px;
}

.pdf-docs_inner_cls {
  border: 1px solid #e4daff !important;
  padding: 16px;
  border-radius: 10px;
  margin-top: 10px;
}

.pdf-docs_inner_cls .card_pdf_docs .document-card {
  max-width: 100%;
  border: none;
  padding: 0;
}

.pdf-docs_inner_cls
  .card_pdf_docs
  .document-card
  .document-icons
  button.MuiButtonBase-root {
  padding: 0;
}

.docs_pdf_des_bx_btn.MuiBox-root button.MuiButtonBase-root {
  padding: 6px;
  border-radius: 5px;
  width: 40px;
  height: 30px;
}

.docs_pdf_des_bx_btn.MuiBox-root button.MuiButtonBase-root svg {
  width: 18px;
  height: 18px;
}

.docs_pdf_des_bx_btn.MuiBox-root .document-value {
  color: #000;
  font-weight: 500;
}

.card_pdf_docs .document-img {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.job_type_sld .MuiFormControl-root .MuiFormGroup-root.recurr_type_job {
  padding: 0;
  border: none;
}

.job_type_sld
  .MuiFormControl-root
  .MuiFormGroup-root.recurr_type_job
  .recurr_type_job
  span.MuiTypography-root.MuiFormControlLabel-label
  p.MuiTypography-root:first-child {
  margin-bottom: 0;
  font-weight: 500;
  width: auto;
  padding-left: 4px;
  font-size: 15px;
}

.job_type_sld
  .MuiFormControl-root
  .MuiFormGroup-root.recurr_type_job
  .recurr_type_job
  span.MuiTypography-root.MuiFormControlLabel-label
  p.MuiTypography-root:last-child {
  padding-left: 5px;
  /* font-size: 14px; */
}

.cs_durtion p.MuiTypography-root {
  padding: 0 15px;
  font-size: 15px;
}

.cs_durtion {
  justify-content: space-between;
}

.cs_durtion p.MuiTypography-root span {
  display: block;
  font-weight: 500;
}

.summar_ppup_k_c.MuiGrid-root.MuiGrid-container {
  margin-top: 0;
  align-items: center;
}

.summar_ppup_k_c.MuiGrid-root.MuiGrid-container .MuiTypography-root {
  color: #000;
}

.k-c-btn-cs.MuiBox-root button.MuiButtonBase-root {
  min-width: 95px;
  padding: 8px 12px;
}

.fre-qncy_dp {
  display: flex;
  align-items: center;
  margin: 0 16px 8px 0;
}

.fre-qncy_dp_every {
  display: flex;
  align-items: center;
  margin: 0 0px 8px 0px;
}

.fre-qncy_dp p.MuiTypography-root,
.fre-qncy_dp_every p.MuiTypography-root {
  color: #000;
  font-size: 14px;
}

.fre-qncy_dp .MuiBox-root {
  margin-top: 6px;
}

.fre-qncy_dp .MuiBox-root div[role='button'] {
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 14px;
}
.custom_freq_interval {
  width: 100%;
}

.custom_freq_interval .frequncy-outer {
  padding-left: 0;
}

.fre-qncy_dp .MuiBox-root label[data-shrink='false'],
.fre-qncy_dp_every .MuiFormControl-root label[data-shrink='false'] {
  font-size: 15px;
  color: #000;
  /* font-weight: 500; */
  -webkit-transform: translate(14px, 12px) scale(1);
  -moz-transform: translate(14px, 12px) scale(1);
  -ms-transform: translate(14px, 12px) scale(1);
  transform: translate(14px, 12px) scale(1);
}

.fre-qncy_dp_every .MuiFormControl-root {
  margin-top: 6px;
  max-width: 125px !important;
}

.fre-qncy_dp_every .MuiFormControl-root .MuiInputBase-root input {
  color: #000;
  padding-top: 12px;
  padding-bottom: 13px;
  font-size: 15px;
}

/*.jb_clnder_box button.MuiButtonBase-root:not(:first-child) {
  margin-left: -1px;
}*/

.nml_templts_main_outer.MuiBox-root {
  border: 1px solid #e4dbfd !important;
  border-radius: 20px;
  box-shadow: 0 8px 20px rgba(127, 82, 251, 0.25);
  background: #fff;
  padding: 20px !important;
  margin-bottom: 22px;
}

.nml_templts_main_outer .setting-tabs_nml button.MuiButtonBase-root {
  min-width: 135px;
}

.nml_templts_main_outer div[role='tabpanel'] > .MuiBox-root {
  padding-left: 0;
  padding-right: 0;
}
.nml_templts_main_outer .layout-container {
  padding: 0;
}

label[data-shrink='true'] {
  background: #fff;
  color: #7349e6 !important;
}

.sent_general_commii.MuiBox-root {
  width: calc(100% - 16px);
  border: 1px solid #e4daff;
  padding-bottom: 16px;
  margin-top: 20px;
  margin-left: 16px;
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(127, 82, 251, 0.15),
    rgba(127, 82, 251, 0.15) 0px 0px 10px inset;
  background: #fff;
}

.sent_general_commii.MuiBox-root .MuiGrid-root.MuiGrid-container {
  width: 100%;
  margin: 0;
}

.sent_general_commii strong,
.sent_general_commii b {
  font-weight: 600;
}

.sent_general_commii.MuiBox-root
  .MuiGrid-root.MuiGrid-container
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-12.MuiGrid-grid-md-6:first-child
  .MuiBox-root {
  border-right: 1px solid #e4daff;
}

.to-send_cls {
  flex-direction: column;
}

.to-send_cls span {
  display: block;
  border: 1px solid #e4daff;
  border-radius: 8px;
  padding: 10px;
  margin-top: 3px;
}

.to-send_cls b {
  font-weight: 500;
  color: #000;
}

.to-send_cls_typo_messgae {
  border: 1px solid #e4daff;
  border-radius: 0 0 8px 8px;
  padding: 15px 10px;
  margin-top: 0;
  border-top: inherit;
}

.mess_semd_comm {
  padding-top: 0 !important;
}

.sub_mils.to-send_cls span {
  border-radius: 8px 8px 0 0;
}

.attched_files_im_cs {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: calc(100% - 16px);
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  border: 2px dashed #e4daff;
  border-radius: 10px;
  margin-left: 16px;
  margin-top: 15px;
  padding: 15px 10px;
}

.to-send_cls + .attched_files_im_cs.MuiBox-root {
  margin-top: 3px;
}

button.repeat_week {
  margin: 0 0 -1px -1px;
}
button.repeat_week:hover {
  position: relative;
  z-index: 1;
}

button.repeat_week.MuiButton-contained {
  padding: 7px 21px;
}

.MuiAccordion-rounded.drip_seqs_toggle {
  box-shadow: none !important;
  padding: 0;
}

.MuiAccordion-rounded.drip_seqs_list_toggle {
  box-shadow: none !important;
  padding: 0;
  border: 1px solid #e4daff;
  margin: 16px 0 !important;
  border-radius: 10px !important;
}

p.drip_main_header {
  margin: 0 0 0 16px;
  font-size: 16px;
  font-weight: 600 !important;
}

/* .toggle_seq_section {
  border: 1px solid #E4DAFF;
  border-radius: 5px;
  box-shadow: 0 8px 20px rgba(127,82,251,0.25);
} */

.MuiAccordion-rounded.Mui-expanded.MuiAccordion-gutters.drip_down_toggle_list {
  box-shadow: none;
  border: 1px solid #e4daff;
  border-radius: 10px;
  margin: 14px 0 0 0 !important;
}

.MuiAccordion-rounded.drip_seqs_list_toggle .MuiAccordionSummary-gutters {
  background: #e4daff;
}

.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.toggle_seq_drip_head.css-wqry2j-MuiPaper-root-MuiAccordion-root:before {
  top: 0px;
  height: 0px;
  right: 0px;
}

.MuiAccordion-rounded.drip_seqs_toggle
  .MuiAccordionSummary-gutters
  .MuiAccordionSummary-expandIconWrapper
  button.MuiButtonBase-root {
  padding: 0;
}

.MuiAccordion-rounded.drip_seqs_toggle
  .MuiAccordionSummary-gutters
  .MuiAccordionSummary-expandIconWrapper
  button.MuiButtonBase-root
  svg {
  width: 22px;
  height: 22px;
}

.MuiAccordion-rounded.drip_seqs_toggle
  h6.MuiTypography-root
  p.MuiTypography-root {
  font-weight: 500;
}

.MuiAccordion-rounded.drip_seqs_toggle .MuiAccordionSummary-gutters {
  padding-top: 3px;
  padding-bottom: 3px;
  /* border-bottom: 1px solid #fff; */
}

.MuiAccordion-rounded.drip_down_toggle_list
  .MuiAccordionSummary-gutters
  .MuiAccordionSummary-expandIconWrapper
  button.MuiButtonBase-root {
  background: #fff;
  border-radius: 10px;
}

.MuiAccordion-rounded.drip_down_toggle_list
  .toggle_seq_section
  .MuiAccordionSummary-expandIconWrapper
  button.MuiButtonBase-root.MuiIconButton-root {
  background: none !important;
}

.MuiAccordion-rounded.drip_down_toggle_list .MuiAccordionSummary-gutters {
  background: #f2edff;
  border-radius: 10px 10px 0 0;
}

.MuiAccordion-rounded.drip_down_toggle_list
  .MuiAccordionSummary-gutters
  .MuiAccordionSummary-content {
  margin: 12px 0;
  padding: 3px 0;
}

p.drip_detail_content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px;
  width: 100%;
  max-width: 250px;
}

.MuiAccordion-rounded.drip_down_toggle_list
  .toggle_seq_section
  .toggle_seq_drip_head
  .MuiAccordionSummary-gutters {
  border-radius: 10px;
}

.MuiAccordion-rounded.drip_down_toggle_list
  .toggle_seq_section
  .toggle_seq_drip_head
  .MuiAccordionSummary-gutters
  .MuiAccordionSummary-content {
  margin-top: 5px;
  margin-bottom: 5px;
}

.MuiAccordion-rounded.drip_down_toggle_list
  .toggle_seq_section
  .MuiPaper-root.drip_seqs_list_toggle {
  margin: 10px 0 !important;
}

.MuiAccordion-rounded.drip_down_toggle_list
  .toggle_seq_section
  .toggle_seq_drip_head {
  border-radius: 10px;
  border: 1px solid #f2edff;
}

.drips-container
  .MuiAccordion-rounded
  .MuiCollapse-root.MuiCollapse-vertical
  .toggle_seq_section
  .MuiAccordionSummary-content
  h6.MuiTypography-root {
  width: 100%;
  max-width: 700px;
}

@media only screen and (max-width: 1750px) {
  .billing_his_client .date_pym_cls.MuiBox-root {
    font-size: 14px;
  }

  .payment_oder_ns.MuiBox-root .MuiTypography-root:last-child {
    padding: 3px 7px;
  }

  .billing_his_client .MuiBox-root {
    gap: 6px;
  }

  .payment_oder_ns.MuiBox-root .MuiTypography-root {
    font-size: 13px;
  }

  .appt-req-btn p.req-head-title {
    font-size: 12px !important;
    margin: 0 !important;
  }

  .ri_box_cols_client .ri_box_cols_neer button.MuiButtonBase-root {
    border-radius: 8px;
    padding: 6px 16px 6px 8px;
  }

  .invoic_tb_outer table tr th {
    font-size: 14px;
  }

  .ov-cs-sts p.MuiTypography-root,
  .dt-sts p.MuiTypography-root {
    font-size: 14px !important;
  }

  .pc_bx_sts p.MuiTypography-root,
  .jb-cs-sts .MuiTypography-root.overview_txt {
    font-size: 14px !important;
  }

  .appt-reqs-data button {
    min-width: 40px !important;
    padding: 7px !important;
  }

  .appt-reqs-data > .MuiBox-root > p.MuiTypography-root {
    font-size: 16px;
  }

  .appt-reqs-count p {
    font-size: 12px;
  }

  .appt-reqs-data > .MuiBox-root {
    margin-bottom: 12px;
  }

  .appt-reqs-count {
    height: 24px;
    width: 24px;
  }

  .appt-reqs-data button svg {
    width: 26px;
    height: 26px;
  }

  .appt-reqs-data {
    padding: 10px;
  }

  .cols_sts_bdy .de_job_prc {
    font-size: 13px;
  }

  .role_user .MuiGrid-root.MuiGrid-container label span.MuiTypography-root {
    font-size: 15px;
  }

  .notifi_mssge
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-12
    span.MuiTypography-root {
    font-size: 15px;
  }

  .notifi_mssge .MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-12 {
    gap: 20px;
  }

  .pro_bsi_dtls.addss_cls_fmr
    .MuiGrid-root.MuiGrid-container
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-md-6
    .MuiBox-root {
    display: flex;
    flex-wrap: wrap;
    margin-left: -8px;
    margin-right: -8px;
  }

  .pro_bsi_dtls.addss_cls_fmr
    .MuiGrid-root.MuiGrid-container
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-md-6
    .MuiBox-root
    .user-label {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
  }
  .pro_bsi_dtls.addss_cls_fmr
    .MuiGrid-root.MuiGrid-container
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-md-6
    .MuiFormControl-root.MuiFormControl-fullWidth {
    width: calc(50% - 16px);
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 10px;
  }

  .pro_bsi_dtls.addss_cls_fmr
    .MuiGrid-root.MuiGrid-container
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-md-6 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .pro_bsi_dtls.addss_cls_fmr
    .MuiGrid-root.MuiGrid-container
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-md-6
    .MuiBox-root.spc {
    margin-left: 0;
    margin-right: 0;
  }

  .pro_bsi_dtls.addss_cls_fmr
    .MuiGrid-root.MuiGrid-container
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-md-6
    .MuiBox-root.spc
    .user-label {
    width: auto;
    padding-left: 0;
  }

  .pro-src-hndle > div {
    max-width: 540px;
  }

  .mar-img-cs {
    /* height: 100%; */
    max-width: 155px;
    min-width: 155px;
    min-height: 210px;
    max-height: 210px;
  }

  .proposal-value {
    font-size: 14px;
  }

  .from-now {
    font-size: 12px !important;
  }
  .proposal-name {
    font-size: 16px !important;
  }

  .pro_right_cont_cls_des {
    padding-left: 15px;
    padding-top: 18px;
  }

  .overview-client .MuiTabs-scroller button {
    min-width: 90px;
    margin-right: 10px;
    padding: 10px 12px;
    min-height: 43px;
    font-size: 13px;
  }

  .client_address {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    max-width: 980px;
  }

  .payment_oder_ns {
    padding-left: 0;
  }

  .layout_inner_edit_prps {
    margin: 20px 0;
  }

  .layout_edit_pros_com {
    margin: 20px 0;
  }

  button.btn_cs_crl {
    padding: 6px 12px;
    margin-left: 4px;
    margin-right: 4px;
    font-size: 14px;
  }
  .nw_appit_btn_cs button.MuiButton-contained {
    padding: 6px 12px;
    font-size: 14px;
  }

  .total_tt {
    font-size: 14px !important;
    padding-top: 0;
  }

  .togg_btn_sh .c-pointer {
    padding: 7px 15px !important;
    font-size: 14px;
    min-width: 65px;
  }

  .tile_tt {
    font-size: 14px !important;
  }

  .box-pjt-cs-inner_flow {
    flex-basis: calc(240px + 14px);
    max-width: calc(240px + 14px);
  }

  .sales_pp_hdr img.sc_icon {
    margin: 0 10px 0 3px;
  }

  .togg_btn_sh {
    margin-left: 14px;
  }

  .project_status_edit img.sc_icon {
    width: 30px;
    max-height: 30px;
  }

  .project_status_edit svg {
    width: 22px;
    height: 22px;
  }

  .project_status_edit {
    margin-bottom: 9px;
  }

  .job-status_cls {
    margin: 12px 10px !important;
    padding: 15px !important;
    border-radius: 20px !important;
    margin-bottom: 0px !important;
    padding-bottom: 23px !important;
  }

  .process_sts_cs .close-icon.box_drg {
    padding-right: 3px;
    padding-left: 0;
  }

  .scroll-board {
    margin: 4px 1px;
  }

  .job-status_cls:before {
    border-radius: 20px;
    bottom: 7px;
  }

  .job-status_cls .text-ellipse {
    font-size: 14px;
  }

  .job_cm_st {
    font-size: 13px !important;
  }

  .deal_title {
    font-size: 14px;
  }

  .process_sts_cs > .MuiBox-root {
    font-size: 11px;
    letter-spacing: 0;
    padding-left: 7px;
    padding-right: 7px;
  }

  .cols_sts_bdy .title_jb {
    font-size: 14px;
  }

  .de_job_en p {
    font-size: 13px !important;
  }

  .jb_st_dt {
    gap: 5px;
    margin: 10px 0 0px 0;
  }

  .time_get_cs {
    font-size: 12px;
  }

  .box-pjt-cs-inner_flow .scroll-board .droppable > .MuiBox-root {
    padding: 10px !important;
    margin-bottom: 17px !important;
    min-height: 135px !important;
    border-radius: 15px !important;
  }

  .cols_sts_bdy .lead_proposal p {
    font-size: 13px;
  }
  .lead_proposal {
    max-width: 106px;
  }

  .cols_sts_bdy .lead_proposal svg {
    margin-right: 3px;
    width: 20px;
    height: 20px;
  }

  .cls-cnt {
    gap: 18px;
  }

  .cal-sts-filter
    .MuiPaper-root.MuiPaper-rounded.MuiAccordion-root
    .MuiCollapse-root
    .MuiBox-root
    label
    span.MuiTypography-root {
    font-size: 12px;
  }

  .cal-sts-filter
    .MuiPaper-root.MuiPaper-rounded.MuiAccordion-root
    p.MuiTypography-root {
    margin-bottom: 10px;
    font-size: 16px;
  }

  .cal-sts-filter
    .MuiPaper-root.MuiPaper-rounded.MuiAccordion-root
    .MuiCollapse-root
    .MuiBox-root
    label
    span.MuiButtonBase-root.MuiCheckbox-root {
    padding-right: 5px;
  }
  .cal-sts-filter .google-board p {
    font-size: 15px;
  }

  .cal-sts-filter .google-board p + p {
    font-size: 13px;
  }

  .cal-sts-filter .google-board {
    padding: 18px 10px;
  }

  .cal-sts-filter .MuiAccordionDetails-root {
    padding-left: 8px;
    padding-right: 8px;
  }

  .cal-sts-filter .google-board button span {
    font-size: 13px;
  }

  .layout-container {
    padding: 18px 8px 18px 18px;
  }

  .top_bar_lyt_cs button.MuiButtonBase-root {
    font-size: 14px;
    padding: 8px 15px;
    border-radius: 9px !important;
  }

  .top_bar_lyt_cs .MuiBox-root label[data-shrink='false'] {
    transform: translate(14px, 10px) scale(1);
    font-size: 14px;
  }

  .top_bar_lyt_cs .MuiBox-root div#actions {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .top_calendar_right-app button.MuiButtonBase-root {
    padding: 7px 15px;
    font-size: 14px;
  }

  .acpt-header .acpt-title .icns {
    width: 25px;
    height: 25px;
  }

  .acpt-header .acpt-title .icns img {
    max-width: 35px;
  }

  .acpt-header .acpt-title .col-add-ic {
    width: calc(100% - 65px);
  }

  .col-add-ic .acpt-header-title {
    font-size: 17px;
  }

  .acc_stts_cs_pros {
    font-size: 17px;
  }

  .acc-pro-accpt-rgt-infm .MuiBox-root p.right_data {
    font-size: 14px;
  }

  .acc-pro-accpt-rgt-infm .MuiBox-root p {
    font-size: 14px;
    min-width: inherit;
    margin-right: 0;
  }

  .acc-pro-accpt-rgt-infm .MuiBox-root {
    margin-right: 0;
  }

  .acc-pro-addss .MuiBox-root p.left_data {
    font-size: 14px;
  }

  .acc-pro-addss .MuiBox-root {
    margin-left: 0;
  }

  .acpt-header .acpt-title .col-add-ic .d-flex-label {
    font-size: 14px;
  }

  .top_calendar_bar .MuiTypography-root {
    font-size: 17px;
  }

  .event-calender .fc-toolbar-title {
    font-size: 17px;
  }

  .side-bar_main li:before {
    width: 26px;
    height: 26px;
  }

  .side-bar_main li > .MuiButtonBase-root.MuiListItemButton-root:after {
    width: 31px;
  }

  td,
  th {
    text-overflow: inherit !important;
    white-space: normal !important;
    overflow: visible !important;
  }

  .client-nm-im p {
    font-size: 17px !important;
  }

  .prp-dt-tb table tr th {
    font-size: 14px;
    padding: 12px 10px;
  }

  .prp-dt-tb table tr td {
    font-size: 13px;
    padding: 10px 10px;
  }

  .prp-dt-tb table tr td .map_btn button.MuiButtonBase-root {
    font-size: 13px;
  }

  .ri_box_cols_client {
    margin-left: 0;
  }

  .ri_box_cols_client {
    padding: 16px !important;
  }

  .total_get_cs {
    margin-bottom: 10px !important;
    margin-top: 5px !important;
    padding: 10px 16px;
  }

  /* main {
    padding-left: 16px !important;
    padding-right: 16px !important;
  } */
  .page-container {
    width: calc(100vw - 290px);
    height: calc(100vh - 160px);
  }

  .process_sts_cs .close-icon.box_drg img {
    width: 17px;
  }

  .side-bar_main li span {
    font-size: 14px;
  }

  .side-bar_main li > .MuiButtonBase-root.MuiListItemButton-root,
  .tgle-bar_main li > .MuiButtonBase-root.MuiListItemButton-root {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .event-calender
    .fc-header-toolbar
    .fc-button-group
    button.fc-dayGridMonth-button,
  .event-calender
    .fc-header-toolbar
    .fc-button-group
    button.fc-timeGridThreeDay-button {
    font-size: 13px;
    text-transform: capitalize;
    padding: 7px 10px;
  }

  .event-calender
    .fc-header-toolbar.fc-toolbar
    .fc-button-group
    button.fc-prev-button,
  .event-calender
    .fc-header-toolbar.fc-toolbar
    .fc-button-group
    button.fc-next-button {
    width: 34px;
    height: 34px;
  }

  .calendar_date .fc-view.fc-daygrid table thead tr th th {
    padding: 8px;
    font-size: 14px;
  }

  .calendar_date .fc .fc-daygrid-day-top {
    font-size: 14px;
  }

  .calendar_date .fc-header-toolbar h2 {
    font-size: 17px;
  }

  .calendar_date .fc-header-toolbar .fc-button-group button.fc-button {
    width: 34px;
    height: 34px;
    border-radius: 8px !important;
  }

  .calendar_date .fc-header-toolbar .fc-toolbar-chunk button.fc-today-button {
    padding: 7px 15px;
    border-radius: 8px;
    font-size: 14px;
  }

  .event-calender .fc-view-harness table thead tr th th {
    padding: 8px;
    font-size: 14px;
  }

  .event-calender .fc-daygrid-day-top {
    font-size: 14px;
  }

  .event-calender .calender-event {
    font-size: 12px;
  }

  .bx-with-bg .MuiGrid-item.MuiGrid-grid-md-8 .MuiInputBase-root {
    max-width: 400px;
    /* background: #f00; */
  }

  .bx-with-bg .MuiFormControl-root div#sort,
  .bx-with-bg .MuiFormControl-root div#filter {
    font-size: 14px;
  }

  .client_pro_are_left p.MuiTypography-root {
    font-size: 17px;
  }

  .client_pro_are_left
    .MuiGrid-grid-md-4
    .MuiBox-root
    button.MuiButtonBase-root {
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 14px;
  }

  .text_rate {
    font-size: 15px;
  }

  .text_rate_til {
    font-size: 13px;
  }

  .user-label {
    font-size: 17px;
  }

  .cl_in-new .MuiBox-root {
    font-size: 14px;
  }

  .cl_tgas .MuiFormControl-root label[data-shrink='false'] {
    font-size: 14px;
  }

  .billing_his_client .MuiBox-root.view_balance .MuiTypography-root {
    font-size: 15px;
  }

  .client-nm-im svg.client-navicon {
    width: 40px;
    height: 40px;
  }

  .overview-client div[role='tabpanel'] > .MuiBox-root {
    padding-left: 16px;
    padding-right: 20px;
  }

  .ri_box_cols_client p.MuiTypography-root.cle-det-title-cs {
    font-size: 17px;
  }
  .MuiTypography-root.card_text {
    font-size: 12px;
  }

  .expiry_details.MuiBox-root p {
    font-size: 12px;
  }

  .card_img img {
    max-width: 40px;
  }
}

@media only screen and (max-width: 1450px) {
  .box-pjt-cs-inner_flow {
    flex-basis: calc(240px + 14px);
    max-width: calc(240px + 14px);
  }

  .pro-src-hndle > div {
    max-width: 390px;
  }
  .ov-cs-sts p.MuiTypography-root,
  .dt-sts p.MuiTypography-root {
    font-size: 13px !important;
  }
}
@media only screen and (max-width: 1200px) {
  main {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

/* job schedule */

.slct-actions {
  width: 100%;
  max-width: 200px;
  background: #7349e6;
  border-radius: 4px;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect {
  color: #ffffff !important;
}

.edit-job-app-header {
  width: 100%;
  max-width: 230px;
}

/* job schedule */

/* drips and sequences */

.drips-container {
  background: #fff;
  border: none !important;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(127, 82, 251, 0.25);
  padding: 16px 16px 20px 16px;
}

.drips-container .drip-head {
  font-size: 22px;
  font-weight: 600;
  text-transform: capitalize;
  margin: 10px 0 0 0;
}

.drip_seqs_toggle {
  margin: 14px 0 0 0;
  border: 1px solid #e4daff;
  border-radius: 10px !important;
  padding: 5px;
}

/* .drip_seqs_toggle:hover{
  background: #F2EDFF;
} */

.drips-container .css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
  height: 0px !important;
}

.seq_content_rate {
  width: 136px;
  height: 38px;
  border-radius: 8px;
  text-align: center;
  padding: 8px;
  background: #f2edff;
}

.drip_default_status {
  height: 20px;
  border-radius: 4px;
  text-align: center;
  padding: 2px 5px;
  background: #fff;
  border: 1px solid grey;
  color: #7349e6;
  margin: -4px 0 0 -5px;
}

.automation_container .MuiBox-root {
  display: flex;
  gap: 10px;
}

.automation_container .MuiBox-root > Button {
  text-transform: capitalize;
  padding: 2px 5px;
  height: 35px;
}

.automation_container .MuiBox-root > Button > svg {
  font-size: 20px;
  padding: 1px;
}

.drip_seq_status {
  border: 1px solid #e4daff;
  padding: 12px;
  border-radius: 10px;
  margin: 14px 0px 14px 0px;
}

.seq_content_rate_step {
  width: 136px;
  height: 38px;
  border-radius: 8px;
  text-align: center;
  padding: 8px;
  background: #f2edff;
  margin: 0 0 0 auto;
}

.seqs_status_action {
  width: 100%;
  max-width: 45px !important;
  min-width: 0 !important;
  padding: 0px !important;
}

.recurr_job_title {
  width: 100% !important;
  margin: 0px 0px 0px 3px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  max-width: 150px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.th_Action {
  text-align: right;
}

.no_drip_data_found {
  padding: 8px;
  border-radius: 8px;
  margin: 8px 0px 15px 0px;
  border: 1px solid grey;
  width: 250px;
  text-align: center;
}

.drip_keyword {
  padding: 11px;
  background: #f2edff;
  border-radius: 10px;
  margin: 5px 0 8px 0px;
}

.drip_arr_grid {
  border: 1px solid #e4dbfd;
  margin: 0px 0 28px -9px !important;
  border-radius: 10px;
}

.box-pjt-cs-inner_flow .scroll-board .droppable > .MuiBox-root {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff !important;
}

.box-pjt-cs .box-pjt-cs-inner .scroll-board .droppable > .MuiBox-root {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff !important;
}

.schedule-area
  .slect_view
  .MuiBox-root
  .MuiFormControl-root
  .MuiSelect-select
  > .MuiBox-root {
  justify-content: flex-start;
}

.overview-client
  .slect_view
  .MuiBox-root
  .MuiFormControl-root
  .MuiSelect-select
  > .MuiBox-root {
  justify-content: flex-start;
}

/* drips and sequences */

/* Task managment */

.task_manage_content {
  width: 100%;
  margin: 0 0 30px 0;
}

.task_manage_content .MuiGrid-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.task_manage_search_bar {
  width: 100% !important;
  border: 1px solid #e4dbfd !important;
  box-shadow: 0 6px 16px rgba(127, 82, 251, 0.15);
  border-radius: 10px !important;
  padding: 3px 8px 2px 8px;
}

.task_manage_select {
  width: 50%;
}

.sort_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.sort_container .css-1q1lg23 {
  margin-top: 0px;
}

.task_manage_list_container {
  border-radius: 10px;
  background: #fff;
  border: 1px solid #e4dbfd;
  box-shadow: 0 8px 20px rgba(127, 82, 251, 0.25);
  padding: 10px 10px 40px 10px;
}

.tast_manage_row {
  padding: 5px 10px;
  border: 1px solid #e4daff;
  margin: 15px 10px;
  border-radius: 10px;
  background: #ffffff;
}

.tast_manage_row .list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tast_manage_row .list .list_value {
  display: flex;
  justify-content: center;
  align-items: center;
}

.list_value .MuiFormControlLabel-labelPlacementEnd {
  margin-right: 0px;
}

.badge .css-cf6wkw-MuiBadge-badge {
  font-size: 10px;
}

.taskListdelete_none {
  pointer-events: none;
}

.taskListdelete {
  cursor: pointer;
}

.taskListdelete:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

.list_type {
  font-size: 11px;
  padding: 2px 5px 2px 10px;
  background: #f0eaff;
  border-radius: 10px;
  font-weight: 500;
}

.assignedTo
  .MuiBox-root
  .css-q8hpuo-MuiFormControl-root
  .MuiInputLabel-formControl
  > span {
  display: none;
}

.tast_manage_row .list .list_value.rightSide {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.copyTaskBtn {
  display: flex;
  justify-content: end;
  align-items: center;
}

.createtask_checkBox {
  display: flex;
  align-items: center;
}

.createtask_checkBox .css-j204z7-MuiFormControlLabel-root {
  margin-right: 0px;
}

.taskList_no_data {
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 1px solid #e4daff;
  border-radius: 8px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 18px;
}

.taskAutoSelect {
  font-weight: 500;
  background: #e4dbfc;
  padding: 15px 10px;
  border-radius: 8px;
  border: 1px solid #e4dbfb;
  margin-bottom: 10px;
}

.multiSelect_contactIDs
  .MuiAutocomplete-hasPopupIcon
  .MuiFormControl-fullWidth
  .MuiInputBase-formControl
  .MuiChip-colorDefault {
  color: #fff;
  background: #7349e6;
}

.multiSelect_contactIDs
  .MuiAutocomplete-hasPopupIcon
  .MuiFormControl-fullWidth
  .MuiInputBase-formControl
  .tag {
  color: #fff;
  background: #7349e6;
  border: 1px solid #7349e6;
  border-radius: 40px;
  text-align: center;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  padding: 5px 10px;
}

.multiSelect_contactIDs
  .MuiAutocomplete-hasPopupIcon
  .MuiFormControl-fullWidth
  .MuiInputBase-formControl
  .tag
  > svg {
  font-size: 16px;
  margin-left: 8px;
  cursor: pointer;
  border-radius: 20px;
  background: #fff;
  fill: #7349e6 !important;
}

.multiSelect_contactIDs
  .MuiAutocomplete-hasPopupIcon
  .MuiFormControl-fullWidth
  .MuiInputBase-formControl
  .MuiChip-colorDefault
  .MuiChip-deleteIcon {
  color: #fff;
}

.seq_drip_data {
  border-radius: 8px;
  display: flex;
  width: 500px;
  margin-bottom: 8px;
}

.taskEdit_taskList_container {
  border: 1px solid #e4dbfc;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.taskEdit_taskList_container .head {
  border-bottom: 1px solid #e4dbfc;
  background: #e4dbfc;
  padding: 15px 10px;
  font-weight: 600;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.taskEdit_taskList_container .body {
  padding: 10px;
}

.taskEdit_taskList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.taskEdit_taskList_actions {
  display: flex;
  gap: 8px;
}

.taskListComplete {
  cursor: pointer;
}

.taskListComplete.taskStatus {
  fill: #754be2;
}

.tast_manage_row .list .list_value.rightSide .MuiBox-root .MuiTypography-root {
  width: auto !important;
  padding-left: 5px;
  font-size: 15px;
}

.tast_manage_row .list .list_value.rightSide .MuiBox-root {
  width: auto !important;
}

.tast_manage_row .list .list_value p.MuiTypography-root {
  font-size: 15px;
}

.tast_manage_row .list .list_value .badge {
  background: #f0eaff;
  border-radius: 10px;
  text-transform: capitalize;
  padding-top: 5px;
  padding-right: 5px;
}

.tast_manage_row .list .list_value .badge span.MuiBadge-badge {
  position: relative;
  left: 0;
  top: -3px;
  -webkit-transform: inherit;
  -moz-transform: inherit;
  -ms-transform: inherit;
  transform: inherit;
  transform-origin: inherit;
  height: auto;
  font-size: 10px;
  line-height: 10px;
  background: #6f3bff;
}

.task_description {
  width: 58%;
  word-break: break-word;
  padding: 10px 8px;
  background: #f8f5ff;
  margin: 0px 0px 0px 25px;
  font-size: 12px;
  border: 1px solid #e4daff;
  border-radius: 8px;
  line-height: 18px;
  white-space: pre-line;
}

.task_description .readMore {
  color: #7349e6;
  text-decoration-line: underline;
  text-decoration-color: #b195ff;
}

.MuiGrid-container .search_task_cs > div {
  background: #fff !important;
  position: relative;
  border-radius: 10px !important;
  width: 100%;
}

.sel_task_cs .MuiFormControl-root {
  max-width: 300px;
}

.sel_task_cs .MuiFormControl-root fieldset,
.sort-task-cs .sort_container fieldset {
  border-color: #9b75f1 !important;
}

.sort-task-cs .sort_container {
  width: 100%;
  gap: 0 !important;
  margin-left: 0 !important;
  padding-right: 15px !important;
}

.sort-task-cs .sort_container .MuiBox-root {
  width: 100%;
}

.add-task-cs .css-4u5uwd {
  margin-left: 0px;
}

.drawer-content .MuiFormControl-root .userType svg {
  fill: #6f3bff;
}

/* notify-bar */

button.notify_bell_btn {
  background: none !important;
  border-radius: 4px;
  width: 100%;
  max-width: 40px;
  /* padding: 6px 6px 0px 0px; */
}

.notify_bar_menu {
  /* background: #e0e0e0!important; */
  display: block !important;
  padding: 11px 0px 17px 14px !important;
}

img.bell_btn_icon {
  color: #6b77e1;
  width: 100%;
  max-width: 35px;
  height: 100%;
  max-height: 35px;
}

button.MuiIconButton-root.notify_bell_btn:hover {
  background: #e4dbfd;
}

.notify_settings_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin: 0 0 13px 0; */
}

button.notify_bell_settings_icon {
  color: #000;
  margin: 0px;
  padding: 0px;
  left: 360px;
}

button.notify_filter_btn {
  margin-right: 12px;
  border: 1px solid #6b77e1;
  color: #6b77e1;
  text-transform: capitalize;
  border-radius: 5px;
  font-size: 12px;
  line-height: 1.5;
}

button.filter_all_count {
  margin-right: 12px;
  border: 1px solid #6b77e1;
  color: #fff;
  background: #6b77e1;
  text-transform: capitalize;
  border-radius: 5px;
  font-size: 12px;
  line-height: 1.5;
}
.notify_settings_header p.MuiTypography-root {
  font-size: 16px;
}

.filter_all_count span.filter_count {
  margin: 0 0 0 4px;
  background: #fff;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  color: #000;
}

.notify_filter_btn span.filter_count {
  margin: 0 0 0 4px;
  background: #6b77e1;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  color: #fff;
}

.notify_list_user {
  display: flex;
  justify-content: center;
  background: linear-gradient(88deg, #a6afff 3.24%, #bca5ff 100.47%);
  align-items: center;
  border-radius: 100%;
  height: 44px;
  width: 44px;
  min-width: 44px;
  position: relative;
}

p.notify_user_name {
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  letter-spacing: -1px;
  line-height: 1;
}

.notify_list_content_head {
  margin: 0px 0 0 15px;
}

p.req_head {
  font-size: 14px;
  margin: 0 5px 0 0;
  /* flex: 1 1; */
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 290px;
  white-space: nowrap;
}
.notify_list_content_head
  .MuiBox-root
  button.MuiButtonBase-root.MuiButton-root {
  padding: 0;
  color: #555;
  background: none !important;
}

p.req_duration {
  color: #4b4d5f;
  font-size: 12px;
  font-style: italic;
}

p.all_req_desc {
  font-size: 13px;
  /* text-overflow: ellipsis;
  width: auto;
  max-width: 420px;
  white-space: nowrap; */
  word-break: break-word;
  text-align: left;
}

li.main_notify_content {
  padding: 14px;
}

.head_main_div {
  display: flex;
  margin-bottom: 10px;
  padding-bottom: 10px;
  width: 100%;
  border-bottom: 1px solid #9e9e9e5e;
  padding-left: 15px;
  padding-right: 15px;
}

.head_hover_main {
  display: flex;
  margin-bottom: 10px;
  padding-bottom: 10px;
  width: 100%;
  border-bottom: 1px solid #576aa4;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
}

.popup_notify_head {
  border: 1px solid #e4dbfd;
  border-radius: 10px 10px 0px 0px;
  padding: 13px;
  background: #e4dbfd;
}

.notify_toggle_settings_list {
  margin-top: 16px;
  margin-bottom: 10px;
}

.notify_toggle_settings_list .MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-12 {
  display: flex;
  flex-wrap: wrap;
}

label.check_toggle_value {
  width: 245px;
}

.toggle_list {
  padding: 0 0 0 18px;
  border: 1px solid #e4dbfd;
  border-radius: 0px 0px 10px 10px;
}

svg.new_list_icon {
  font-size: 26px;
  color: #6f3bff;
  vertical-align: middle;
}

.footer_read_head {
  width: 100%;
  background: #6b77e1;
}

.footer_read_head button.MuiButtonBase-root.MuiButton-root {
  padding: 10px;
}

.main_notify_content {
  padding: 0;
  max-height: 44rem;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  max-width: 100% !important;
}

.main_notify_content .lisiting_header {
  width: 100%;
  /* overflow-y: auto; */
  max-width: 100% !important;
  padding-top: 10px;
  padding-bottom: 0px;
  max-height: 450px;
  position: relative;
}
.main_notify_content .lisiting_header .infinite-scroll-component__outerdiv {
  height: 100%;
  max-height: 450px;
  overflow-y: auto;
}
.notify_list_content_head .MuiBox-root {
  align-items: center;
}

button.notify_bell_btn button.MuiButtonBase-root span.MuiBadge-badge {
  font-size: 10px;
  min-width: 22px;
  padding: 0 4px;
  height: 20px;
  color: #fff;
  top: 7px;
  right: 8px;
  background: #6b77e1;
  border-radius: 100%;
}

.unread_data {
  width: 10px;
  height: 10px;
  background: #4a9e5c;
  border-radius: 100%;
  position: absolute;
  margin: 33px 30px 0 0px;
  border: 2px solid #fff;
}

.edit_seq_icon_btn {
  height: 28px !important;
  font-size: 12px !important;
  width: 100%;
  max-width: 252px;
  border-radius: 7px !important;
}

.side-width .company-logo {
  width: 40px;
  object-fit: cover;
  object-position: left;
}

.quote_tax_scn {
  padding: 0px 18px 18px 18px;
  border: 1px solid #e4daff;
  border-radius: 10px;
  margin: 24px 0 0px 14px;
}

.quote_type_amount {
  margin: -12px 0 0 0;
}

.notes_discount_container {
  width: 100%;
}

.notes_discount_scn {
  padding: 0px 18px;
  border: 1px solid #e4daff;
  border-radius: 10px;
  margin: 15px 0 12px 14px;
}

.delete_icon_grid {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dscnt_tax_scn {
  border-left: 1px solid #e4daff;
}

.add_dscnt_scn {
  border-bottom: 1px solid #e4daff;
}

.add_dscnt_scn .MuiBox-root .MuiGrid-container {
  display: flex;
  justify-content: space-between;
}

.dicount_deposit_value {
  display: flex;
  justify-content: end;
  align-items: center;
}

.add_tax_toggle_btn {
  padding: 5px 0px !important;
  text-transform: capitalize !important;
  font-size: 15px !important;
}

.view_tax_total {
  margin: 12px 0;
  border-bottom: 1px solid #e4daff;
}

.non_index_total {
  margin: 0 0 0 13px !important;
  border: 1px solid #e4daff;
  border-radius: 10px;
  padding: 2px 16px 8px 0px;
}

.booking_test_url {
  padding: 10px;
  border: 1px solid #e4daff;
  border-radius: 10px;
  width: 100%;
  display: flex;
}

.proposalName_with_arrowBackIcon button.MuiButtonBase-root {
  background: #fff;
  padding-top: 5px !important;
  min-height: inherit !important;
  height: auto !important;
  line-height: 1;
  padding-bottom: 5px !important;
  border-radius: 8px !important;
  color: #000 !important;
}

/* notify-bar */

/* backIcon */
.layout-container .backIcon {
  margin-bottom: 10px;
}

.backIcon {
  padding: 7px 12px !important;
  border-radius: 10px !important;
}

.backIcon > span {
  margin-right: 0px;
}
/* backIcon */

.tabLeftSide .settings-tabs .MuiTabs-fixed > div > button {
  max-width: 100%;
}

.layout-container.pl-zero {
  padding-left: 0;
  padding-right: 0;
  margin-top: 10px;
}

.new-client-detail-outer
  .client_external_tabs
  div[role='tabpanel']
  > .MuiBox-root {
  padding-left: 0 !important;
}

.new-client-detail-outer
  .client_external_tabs
  div[role='tabpanel']
  .client_pro_are_left {
  box-shadow: none;
  border-radius: 10px;
}

.new-client-detail-outer
  .client_external_tabs
  div[role='tabpanel']
  div[role='tabpanel']
  > .MuiBox-root {
  padding-left: 24px !important;
}

.new-client-detail-outer .MuiGrid-grid-md-8 .ri_box_cols_client {
  box-shadow: none !important;
  border: none !important;
  padding-left: 15px !important;
  margin-left: 0;
  padding-right: 15px !important;
  padding-bottom: 0 !important;
  margin-bottom: 0;
}

.billing_his_client .MuiBox-root.bt-sp.bll-histry .MuiBox-root {
  min-width: 165px;
}

.billing_his_client
  .MuiBox-root.bll-histry
  .MuiFormControl-root
  label[data-shrink='false'] {
  font-size: 14px;
}

.billing_his_client
  .MuiBox-root.bll-histry
  .MuiFormControl-root
  div[role='button'] {
  font-size: 14px;
}

.new-client-detail-outer
  .tabs_button_cs
  .MuiTabs-scroller
  span.MuiTabs-indicator {
  display: none;
}

.cl-nme-des-bx .MuiTypography-root {
  font-size: 15px;
}

.cl-nme-des-bx .MuiTypography-root.cl-nme-dtl {
  font-size: 22px;
  margin-bottom: 10px;
}

.paymentabs-outer.MuiBox-root {
  border: 1px solid #e4dbfd;
  padding: 20px;
  border-radius: 15px;
}

.paymentabs-outer.MuiBox-root .MuiTypography-root.hed_pymn-text {
  min-width: 160px;
}

.tbs_in_text h3.MuiTypography-root {
  font-size: 16px;
  margin-bottom: 15px;
}

.tbs_in_text p.MuiTypography-root {
  margin-bottom: 10px;
}

.tbs_in_text label.MuiFormControlLabel-root {
  margin-bottom: 10px;
}

.tbs_in_text p.MuiTypography-root a {
  color: #7349e6;
}

label.sectionContent.size_miidle {
  font-size: 17px;
  font-weight: 600;
  margin-left: 2px;
}

.layout-container-quote .sectionContent {
  font-weight: 500;
  margin-left: 2px;
}

.sub-total-outer .MuiTypography-root.sub-total-outer-tile {
  font-size: 15px;
  font-weight: 400;
}

.sub-total-outer .MuiTypography-root {
  font-weight: 500;
  font-size: 15px;
}

.min-total-outer p.MuiTypography-root {
  font-size: 15px;
  font-weight: 600;
}

.user-label p.MuiTypography-root {
  font-size: 15px;
}

.step_send_immde label.MuiFormControlLabel-root span.MuiButtonBase-root {
  padding-top: 0;
  background: none !important;
  padding-bottom: 0;
  padding-right: 0;
}

.step_send_immde.MuiBox-root {
  margin-top: -12px;
  margin-bottom: 5px;
}

.send_select.MuiBox-root .MuiBox-root {
  min-width: 175px;
}

.step_send_immde label.MuiFormControlLabel-root {
  margin-right: 7px;
}

@media only screen and (max-width: 1650px) {
  .client_pro_are_left p.MuiTypography-root {
    font-size: 15px;
  }

  .tabLeftSide .settings-tabs .MuiTabs-fixed > div > button {
    gap: 0;
    padding-left: 10px;
    padding-right: 10px;
  }

  .client_external_tabs .MuiTabs-scroller button {
    padding: 8px 15px;
    min-height: 41px;
    min-width: 85px;
    font-size: 13px;
  }
  .client_external_tabs .MuiTabs-scroller button:last-child {
    margin-right: 0;
  }

  .cl-nme-des-bx .MuiTypography-root {
    font-size: 14px;
  }

  .cl-nme-des-bx .MuiTypography-root.cl-nme-dtl {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .dummy_client_img img {
    height: 120px;
    width: 120px;
  }
}

@media only screen and (max-width: 1299px) {
  .client_external_tabs .MuiTabs-scroller button {
    padding: 7px 12px;
    min-height: 37px;
    min-width: 75px;
    font-size: 12px;
  }
  .not_bx_inner_cls p.MuiTypography-root {
    font-size: 12px;
  }
}

.MuiDateCalendar-root {
  width: 355px !important;
}

/* ----------------------- HOME-OWNER CSS ------------------ */

/* my-project */

.project_main_container {
  padding: 30px 16px 10px 16px;
}

.project_search .MuiInputBase-root {
  border: 1px solid #e0e0e0 !important;
  border-radius: 20px !important;
  width: 100%;
  max-width: 600px;
}

.project_search .MuiGrid-root .MuiGrid-item > div > div > svg {
  fill: #6b77e1;
  font-size: 25px;
}

.search_grid > div {
  background: #fff !important;
  position: relative;
  border-radius: 20px !important;
  width: 100%;
  max-width: 600px;
}

.project_search .MuiInputBase-root input[type='text'] {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  padding-right: 14px !important;
  font-size: 15px;
  font-weight: 400;
  color: #000;
}

.emptyStateImg {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  margin: auto;
  height: 230px;
  width: auto;
}

.stepperContainer
  .MuiStepper-horizontal
  .MuiStep-horizontal
  .MuiStepLabel-horizontal
  .MuiStepLabel-labelContainer
  .Mui-completed {
  color: #6b77e1;
}

.MuiDialog-container
  .MuiPaper-elevation[aria-labelledby='homeOwner-customized-dialog-title'] {
  padding: 16px;
  border-radius: 20px;
}

.MuiDialog-container
  .MuiPaper-elevation[aria-labelledby='homeOwner-customized-dialog-title']
  > div {
  border: none !important;
  padding: 20px !important;
  border-radius: 0px !important;
}

.MuiDialog-root
  .MuiDialog-container
  .MuiPaper-rounded
  h2#homeOwner-customized-dialog-title {
  /* text-transform: capitalize; */
  color: #4b4d5f;
  font-size: 20px;
  font-weight: 500;
}

.project_container .project_title {
  font-weight: 500;
  padding: 12px 20px;
  background-color: #e4dbfd;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.project_container
  .MuiBox-root
  .MuiGrid-container
  .MuiGrid-item
  .MuiFormControl-fullWidth
  > label {
  color: #6b77e1 !important;
  background-color: #fff !important;
}

.project_container
  .MuiBox-root
  .MuiGrid-container
  .MuiGrid-item
  .MuiFormControl-fullWidth
  .MuiOutlinedInput-root
  > fieldset {
  border-color: #6b77e1 !important;
  border-radius: 20px !important;
}

.project_title > span {
  color: #7349e6;
}

.project_container .project_body {
  padding: 20px;
  border: 1px solid #e4dbfd;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.projectType_options
  .MuiGrid-container
  .MuiGrid-item
  .projectType_options
  > label {
  padding: 8px;
  border-radius: 10px;
  margin-top: 2px;
  border: 1px solid #e0e0e0;
  width: 100%;
}

.projectType_options
  .MuiGrid-container
  .MuiGrid-item
  .projectType_options
  > label
  .MuiFormControlLabel-label {
  color: #4b4d5f;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  font-weight: 400;
}

.checkboxIcon_img {
  width: 20%;
  height: 20%;
  border-radius: 10px;
}

.projectType_options.selected {
  border: 1px solid #6b77e1 !important;
  color: #7349e6 !important;
}

.projectType_options.otherType {
  width: 205% !important;
}

.inviteType_options
  .MuiGrid-container
  .MuiGrid-item
  .inviteType_options
  > label {
  padding: 6px;
  border-radius: 10px;
  margin-top: 2px;
  border: 1px solid #e0e0e0;
  width: 100%;
}

.inviteType_options
  .MuiGrid-container
  .MuiGrid-item
  .inviteType_options
  > label
  .MuiFormControlLabel-label {
  color: #121212;
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 14px;
}

.inviteType_options.selected {
  border: 1px solid #6b77e1 !important;
  color: #7349e6 !important;
}

.project_body
  .MuiGrid-container
  .MuiGrid-item
  .MuiBox-root
  .MuiFormControl-root {
  border: 0px;
}

.project_body
  .MuiGrid-root
  .MuiGrid-item
  .MuiFormControl-root
  .MuiInputBase-root {
  margin-bottom: 5px !important;
}

.project_body .prfl_pics .MuiGrid-root .sc-bgqQcB .MuiBox-root > button {
  color: #fff !important;
}

.projectDates .MuiGrid-root .MuiGrid-item .MuiBox-root .MuiFormControl-root {
  border: 0px;
}

.projectDates
  .MuiGrid-root
  .MuiGrid-item
  .MuiBox-root
  .MuiFormControl-root
  .Mui-error {
  border-color: #e0e0e0 !important;
}

.budget_see {
  padding: 15px;
  border-radius: 20px;
  border: 1px solid #6b77e1;
}

.budget_amount {
  color: #6b77e1;
  font-weight: 500;
}

.keepBudget_private {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.keepBudget_private .MuiFormControlLabel-labelPlacementEnd {
  margin-right: 0px;
}

.keepBudget_private .note {
  font-size: 16px;
  font-weight: 500;
}

.keepBudget_private .note > span {
  font-size: 12px;
  color: #4b4d5f;
  font-style: italic;
}

.thankyouImg {
  display: block;
  margin: 0 auto;
  width: 35%;
  height: 35%;
}

.thankyouText {
  text-align: center;
  padding-top: 16px;
}

.thankyouText > div {
  font-size: 20px;
  font-weight: 500;
}

.thankyouText > p {
  font-size: 14px;
  color: #121212;
}

.proceedBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.proceedBtn > button {
  width: 35%;
  padding: 12px 7px;
}

.inviteTypeText {
  color: #4b4d5f;
  font-size: 16px;
  padding-bottom: 10px;
}

.projectList-container {
  background-color: #fff;
  padding: 14px 20px;
  border-radius: 10px;
}

.projectImg {
  width: 100%;
  border-radius: 20px !important;
  height: 200px !important;
  border: 1px solid #eee !important;
  max-width: 350px !important;
  object-fit: cover !important;
}

.projectList-container .MuiBox-root > img {
  /* height: 35%; */
  margin-top: 5px;
}

.projectInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.projectStatus_actions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.statusType_count {
  /* text-transform: capitalize; */
  font-size: 14px;
  padding: 6px 12px;
  border-radius: 8px;
}

.pendingStatus {
  color: #ff5c00;
  background-color: #fff1e9;
  text-transform: capitalize;
}

.receivedStatus {
  color: #4a9e5c;
  background-color: #78dd8e1a;
}

.proposalCount {
  color: #a5a6af;
  background-color: #eff1f7;
}

/* .jobPostBtn > button {
  padding: 6px 10px !important;
} */

.editProjectIcon {
  height: 28px !important;
  width: 28px !important;
  color: #a5a6af;
  cursor: pointer;
}

.deleteProjectIcon {
  border: 0px !important;
  width: 22px !important;
  height: 22px !important;
  cursor: pointer;
}

.fromNow {
  font-size: 14px;
  color: #a5a6af;
}

.projectName {
  font-size: 20px;
  font-weight: 500;
  /* text-transform: capitalize; */
}

.projectDescription {
  font-size: 14px;
  color: #4b4d5f;
  /* text-transform: capitalize; */
}

.project_details {
  display: flex;
  /* justify-content: space-between; */
  margin: 15px 5px 30px 5px;
}

.project_details_colmn {
  display: flex;
  gap: 10px;
  border: 1px solid #e0e0e0;
  padding: 10px 12px;
  border-radius: 10px;
  margin-right: 10px;
  align-items: flex-start;
}

.project_details_colmn > svg {
  fill: #6b77e1;
}

.colmn_title {
  font-size: 16px;
  color: #4b4d5f;
  font-weight: 500;
}

.colmn_value {
  font-size: 14px;
  color: #000;
  font-weight: 500;
  word-break: break-word;
}

.tabOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tab_additionOptions {
  display: flex;
  align-items: center;
  gap: 20px;
}

/* .tab_additionOptions > button {
  padding: 14px 28px;
  border-radius: 20px;
  text-transform: capitalize;
} */

[aria-label='contractor_tabs'] > .Mui-selected {
  color: #4b4d5f !important;
}

[aria-label='contractor_tabs'] > button {
  text-transform: capitalize;
  color: #a5a6af !important;
}

.inviteContractorForm
  .MuiGrid-root
  .MuiGrid-item
  .MuiFormControl-root
  .MuiInputBase-root
  .MuiInputAdornment-root
  > svg {
  fill: #6b77e1;
}

.inviteContractorFormBtn {
  width: 18%;
}

/* .addContractorBtn {
  font-weight: 400 !important;
  text-transform: capitalize !important;
  border-radius: 20px !important;
  padding: 10px 20px !important;
} */

.fieldWithCheckbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fieldWithCheckbox > label > span {
  padding: 0px;
}

.projectStatusBtn {
  cursor: default !important;
  font-weight: 400 !important;
  border-radius: 14px !important;
  padding: 8px 18px !important;
}

.projectStatusBtn:hover {
  border: none !important;
}

.actionsMenu:hover {
  color: #6b77e1;
}

.emailConfirmImg {
  display: block;
  margin: 0 auto;
  width: 280px;
}

.emailConfirmBtns {
  width: 30% !important;
  padding: 10px 12px !important;
}

.inviteByLink-container
  .MuiGrid-root
  .MuiGrid-item
  .MuiBox-root
  .MuiFormControl-root
  .MuiOutlinedInput-root
  > fieldset {
  border-radius: 20px !important;
  border-color: #e0e0e0 !important;
}

.inviteByLink-container
  .MuiGrid-root
  .MuiGrid-item
  .MuiFormControl-root
  .MuiOutlinedInput-root
  > fieldset {
  border-radius: 20px !important;
  border-color: #e0e0e0 !important;
}

.MuiInputBase-root.MuiOutlinedInput-root > Svg {
  fill: #6b77e1;
}

.MuiInputAdornment-root > svg {
  fill: #6b77e1;
}

/* compare screen styles */

.compare_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.compare_proposal_parent {
  background-color: #fff;
  padding: 14px 30px;
  border-radius: 10px;
}

.compare_heading_text {
  color: #4b4d5f !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  margin-top: 0.5rem;
}

.compare_heading > button {
  text-transform: math-auto;
  font-size: 18px !important;
  border-radius: 10px !important;
  padding: 12px 0px !important;
  width: 220px !important;
}

.compare_subheading_text {
  width: 53.5625rem;
  height: 1.9375rem;
  flex-shrink: 0;
  font-size: 0.9rem;
  color: #4b4d5f;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.compare_selection_parent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.compare_selection_box {
  display: flex;
  width: 15.3125rem;
  height: 18.75rem;
  flex-shrink: 0;
  border: 2px dashed #cecece;
  background: #fff;
  border-radius: 8%;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.comapre_selection_add {
  width: 5.0625rem;
  height: 5.5625rem;
  font-size: 5rem !important;
  color: rgba(206, 206, 206, 1);
  cursor: pointer;
}

.comapre_selection_add:hover {
  color: rgba(206, 206, 255, 1);
  scale: 1.2;
  transition: all 0.5s ease-in-out;
}

.compare_selection_text > p {
  color: #4b4d5f;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* ----------------compare selecto------------------*/

.compare_selector_subheading {
  width: 50.9375rem;
}
.compare_selector_subheading > p {
  color: #4b4d5f;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.select_contractor {
  display: flex;
  width: 51.125rem;
  height: 3.625rem;
  padding: 0.875rem 1.25rem;
  align-items: center;
  flex-shrink: 0;
  border-radius: 1.25rem;
  background: #fff;
}

.compare_selector_btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  padding: 0.5rem 1.2rem;
}

/* .compare_selector_return {
  display: flex;
  width: 11.875rem;
  height: 3.625rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 1.25rem;
  background: rgba(107, 119, 225, 0.05);
  border: 0.5px solid #6b77e1;
}

.compare_selector_return > button {
  width: 100%;
  height: 100%;
} */

/* .compare_selector_submit {
  display: flex;
  width: 11.875rem;
  height: 3.625rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 1.25rem;
  background: #6b77e1;
}

.compare_selector_submit > button {
  color: #fff;
  width: 100%;
  height: 100%;
} */

.getProposal_container {
  margin: 20px;
  margin-top: 32px;
}

.proposal_details_section {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
}

.proposalTitle {
  display: flex;
  align-items: center;
  gap: 14px;
  text-transform: capitalize;
}

.project_info_received {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding-bottom: 6px;
}

.project_info_received > svg {
  fill: #6b77e1;
}

.proposal_content {
  border: 1px solid #576aa4;
  padding: 16px;
  border-radius: 20px;
  color: #4b4d5f;
  font-weight: 400;
}

.proposal_content.handle_overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}

.proposal_status {
  background: #78dd8e1a;
  color: #4a9e5c;
  font-size: 14px;
  padding: 8px;
  border-radius: 10px;
  text-transform: capitalize;
}

.declineBtn {
  background: #fff3f8 !important;
  color: red !important;
  width: 12% !important;
  padding: 15px 0px !important;
  text-transform: capitalize !important;
  border-radius: 10px !important;
}

.HireBtn {
  width: 12% !important;
  padding: 12px 0px !important;
  text-transform: capitalize !important;
  border-radius: 10px !important;
}

.hirePopupImg {
  display: block;
  margin: 0 auto;
}

.hirePopupBtns {
  padding: 10px 0px !important;
  width: 25% !important;
}

.hireStatus {
  background: rgb(74, 158, 92) !important;
  color: #fff !important;
}

.declineBtnContractor {
  background: #fff3f8 !important;
  color: red !important;
  padding: 10px 12px !important;
  text-transform: capitalize !important;
  border-radius: 20px !important;
  cursor: default !important;
}

.tabelCellData::first-letter {
  text-transform: uppercase;
}

.proposal_amount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px dashed #576aa4;
  font-weight: 400;
}

.proposal_amount.total {
  border-bottom: none !important;
}

.proposal_amount.note {
  border-bottom: 0px !important;
}

.projectStatusBtn.received {
  color: #4a9e5c;
  background: #effef2;
  border: 1px solid #effef2 !important;
}

.projectStatusBtn.received:hover {
  border: 1px solid #effef2 !important;
  background: #effef2 !important;
}

.qstnBtns {
  border-radius: 50px !important;
  padding: 7px 12px !important;
  text-transform: none !important;
}

.conversation {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
  margin: 0.8rem;
}

/* -------------Invoice Home Owner---------------- */

.invoice-banner {
  padding-top: 16px;
  margin: 16px;
}

.client-invc-list {
  padding: 25px;
  /* border: 1px solid #e0e0e0; */
  box-shadow: none !important;
  border-radius: 20px;
  /* background: #fff !important; */
  width: auto;
}

.invoice-list-card {
  border: 1px solid #e4dbfd;
  box-shadow: none !important;
  border-radius: 20px;
  background: #fff !important;
  width: auto;
  margin: 3px 3px;
  padding: 16px;
}

.invoice-list-card .invoice-status,
.invc-tbl-req-data .invoice-status {
  background: #e0ffe6;
  color: #4a9e5c;
  align-items: center;
  border-radius: 20px;
  display: flex;
  font-size: 15px;
  font-weight: 400;
  height: 32px;
  justify-content: center;
  min-width: 75px;
  padding: 5px 17px;
  text-align: center;
  text-transform: capitalize;
}

.invoice-list-card:hover {
  background: #fff !important;
  border-color: #6b77e1;
  box-shadow: 0 8px 20px rgba(127, 82, 251, 0.25) !important;
  cursor: pointer;
}

.invc-payment-req {
  border: 1px solid #e4dbfd;
  box-shadow: none !important;
  border-radius: 10px;
  background: #fff !important;
  padding: 30px;
  margin: 27px 0px 0px 0px;
}

.invc-req-amount {
  border: 1px solid #e4dbfd;
  border-radius: 10px;
  padding: 16px;
}

.invc-tbl-req-data {
  background: #fff !important;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid #e4dbfd;
}

.data_tb.invc-tbl-data_cs table {
  border-spacing: 0;
  border: 1px solid #e4dbfd;
}

.invc-paid-payements {
  border: 1px solid #e4dbfd;
  margin: 10px 0;
}

.invc-paid-payements .head-data {
  background: #eff1f7;
  padding: 10px;
}

.invc-attchements-data {
  background: #fff;
  margin: 10px 0;
  border-radius: 10px;
  border: 1px solid #e4dbfd;
  padding: 30px;
}

.invc-attchements-data .invc-card {
  position: relative;
  background: #ffffff;
  padding: 15px;
  cursor: pointer;
  border: 1px solid #e4dbfd;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}

.invc-attchements-data .invc-card img {
  border-radius: 10px;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
  margin: 0;
}

.invc-attchements-data .invc-attach-edit {
  position: relative;
  padding-bottom: 60%;
  overflow: hidden;
  margin: auto;
}

/* .invc-attchements-data .invc-attach-edit img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
  margin: 0;
} */

/* ------------compare_chat--------------- */

.compare_chat_connatiner {
  margin-left: '14px';
  margin-right: '14px';
}

.user_chat_bubble {
  width: fit-content;
  max-width: 87%;
  border-radius: 20px 20px 0px 20px;
  border: 1px solid #576aa4;
  padding: 0.72rem;
}

.user_chat_bubble:hover {
  cursor: pointer;
}

.ava_avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  border-radius: 50%;
  border-width: thin;
  overflow: hidden;
}

.ava_chat_bubble {
  width: fit-content;
  max-width: 87%;
  border-radius: 20px 20px 20px 0px;
  border: 1px solid #576aa4;
  padding: 0.6rem;
  font-size: 14px;
}

.name_avatarDiv {
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 500;
}

.compare_proposal_avatar {
  margin: 0 auto !important;
  color: #fff !important;
  background-color: transparent !important;
  background-image: linear-gradient(
    88deg,
    #a6afff 3.24%,
    #bca5ff 100.47%
  ) !important;
}

.compareRating_text {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  color: #4b4d5f;
}

.compareRating_text .compareRating_star {
  padding: 0.9rem 0.5rem;
  display: flex;
  gap: 0.125rem;
  justify-content: center;
  border-radius: 1.25rem;
  height: 0.9rem;
  align-items: center;
  width: 4.2rem;
}

.compareHireBtn {
  display: block !important;
  margin: 0 auto !important;
  width: 8rem !important;
  padding: 0.3rem 0rem !important;
}

.tabelCellData::first-letter {
  text-transform: uppercase;
}

.campare_spec_colms {
  width: 100% !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-content: center !important;
  gap: 10px !important;
  font-size: 16px !important;
}

.compare_proposal_parent_headerTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.questionBtns {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 0.8rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #576aa4 !important;
}

.project_container
  .MuiBox-root
  .MuiGrid-container
  .MuiGrid-item
  .MuiFormControl-fullWidth.projectType_input
  .MuiOutlinedInput-root
  > fieldset {
  border-color: #6b77e1 !important;
  border-radius: 10px !important;
}

.project_container
  .MuiBox-root
  .MuiGrid-container
  .MuiGrid-item
  .MuiFormControl-fullWidth.projectType_input
  .MuiOutlinedInput-root
  > input {
  padding: 18.5px 14px !important;
}

.chat_action_container {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin: 1.5rem 0.8rem 1rem 0.8rem;
  border-radius: 20px;
}
.chat_action_input {
  width: 100%;
}

/* css loader for chat waiting */
.bouncing-loader {
  display: flex;
  justify-content: center;
}

.bouncing-loader > div {
  width: 13px;
  height: 13px;
  margin: 4px 6px;
  border-radius: 50%;
  background-color: #a3a1a1;
  opacity: 1;
  animation: bouncing-loader 0.28s infinite alternate;
  /* background-color: #9747FF; */

  background: linear-gradient(88deg, #a6afff 3.24%, #bca5ff 100.47%);
  box-shadow: 0px 8px 20px 0px rgba(177, 171, 255, 0.35);
}
@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-8px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.1s;
  /* background-color: #9747FF; */
  background: linear-gradient(88deg, #a6afff 3.24%, #bca5ff 100.47%);
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.3s;
  box-shadow: 0px 8px 20px 0px rgba(177, 171, 255, 0.35);
  background: linear-gradient(88deg, #a6afff 3.24%, #bca5ff 100.47%);
  /* background-color: #9747FF;*/
}

.ava_chat_loader {
  width: fit-content;
  max-width: 87%;
  border-radius: 20px 20px 20px 0px;
  border: 1px solid #e0e0e0;
}

.chat_vibras {
  display: flex;
  justify-content: center;
  margin-bottom: 5%;
}

.chat_vibras > p {
  color: #e0e0e0;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.proposal_title_img {
  border: 1px dashed #ddd;
  min-height: 80px;
  padding: 20px;
  border-radius: 10px;
  margin: 24px 0;
}

.proposal_title_img > img {
  display: block;
  margin: 0 auto;
}

.name_avatarBox {
  text-transform: uppercase;
  font-size: 18px;
}

.backIconModal {
  display: flex;
  align-items: center;
  gap: 10px;
}

.backIconModal > svg {
  cursor: pointer;
  font-size: 25px;
}

/* .compare_heading_btn > button {
  text-transform: capitalize !important;
  padding: 12px 0px !important;
  width: 110px !important;
  border-radius: 10px !important;
} */

.startOver_btn_grp {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.startOver_btn_grp > button {
  padding: 12px 0px !important;
  width: 110px !important;
}

/* .tabelCellData .intro {
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
} */

/* .chatWithVibras_btn > button {
  text-transform: math-auto !important;
  padding: 14px 0px !important;
  width: 170px !important;
  border-radius: 10px !important;
} */

.myProposal_tabs
  .MuiTabs-root
  .MuiTabs-scroller
  > [aria-label='myProposal_tabs']
  > .Mui-selected {
  color: #fff !important;
  background-color: #6b77e1 !important;
}

.myProposal_tabs
  .MuiTabs-root
  .MuiTabs-scroller
  > [aria-label='myProposal_tabs']
  > #simple-tab-0 {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  text-transform: capitalize;
}

.myProposal_tabs
  .MuiTabs-root
  .MuiTabs-scroller
  > [aria-label='myProposal_tabs']
  > #simple-tab-1 {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  text-transform: capitalize;
}

.myProposal_tabs .MuiTabs-root .MuiTabs-scroller .MuiButtonBase-root {
  color: #6b77e1 !important;
  border: 1px solid #6b77e1 !important;
}

.myProposal_tabs .MuiTabs-root .MuiTabs-scroller > .MuiTabs-indicator {
  background-color: #6b77e1 !important;
}

.client-view-appt-form .reqBtn {
  border-radius: 10px !important;
  padding: 14px !important;
}

.appt-req-back-btn {
  margin: 16px 0 0 0px;
}

.billing-details-section {
  padding: 20px;
}

.card-default {
  background: #e0ffe6;
  color: #4a9e5c;
  font-size: 15px !important;
  font-weight: 400 !important;
  width: 10%;
  text-align: center;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 17px;
  border-radius: 20px;
  text-transform: capitalize;
}

.billing-card-div {
  border: 1px solid #e4dbfd;
  border-radius: 20px;
  padding: 20px;
  cursor: pointer;
  margin: 0 0 20px 0;
}

.billing-card-div-active {
  border: 1px solid #576aa4;
  border-radius: 20px;
  padding: 20px;
  cursor: pointer;
  margin: 0 0 20px 0;
}

.billing-card-div:hover {
  background: #f1edfd;
}

.billing-card-div-active:hover {
  background: #f1edfd;
}

.billing-card-div .billing-card-brand,
.billing-card-div-active .billing-card-brand {
  margin: 0 16px 8px 0px;
  padding: 10px;
  border: 1px solid #576aa4;
  border-radius: 8px;
  display: flex;
  height: 38px;
  width: 100%;
  max-width: 60px;
  align-items: center;
}

.billing-card-div svg.brand-card-dots,
.billing-card-div-active svg.brand-card-dots {
  margin: 0 12px 0 0;
}

.react-datepicker-wrapper input.stripe_month_card_picker {
  padding: 12.5px 0px 12.5px 14px;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  background: #fff;
  height: 44px;
  margin-top: 10px;
  width: 100%;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__header {
  background-color: #e4dbfd !important;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
  font-size: medium;
}

/* .react-datepicker-wrapper input.stripe_month_card_picker {
  padding: 12.5px 0px 12.5px 14px;
  border: 1px solid #e4dbfd;
  border-radius: 20px;
  background: #fff;
  height: 44px;
  margin-top: 16px;
  width: 100%;
} */

.react-tel-input .form-control {
  border: 1px solid #dddddd !important;
  border-radius: 20px !important;
  width: 100% !important;
  height: 46px !important;
}

input[type='tel'] {
  border: none;
  outline: none;
  padding: 0px 2px;
}

/*   app. request css */

.back_withoutBtn_flex {
  display: flex;
}
.bookingForm_checkbox_parent {
  display: flex;
  gap: 15px;
  align-items: center;
  overflow: auto !important;
}
.bookingForm_checkbox_con label {
  margin-right: 6px !important;
}
.appt-req-back-btn .back_withoutBtn_flex {
  color: #4b4d5f;
  text-decoration: underline;
}

/*   my invoices css */

.invoice-banner .clinet_search_bx {
  border-radius: 20px !important;
}

.combineAddress_con,
.invoice_date_con {
  margin-left: 28px;
}

/* table css */

.data_tb_cs .client-head1 td:last-child {
  border-radius: 0px 20px 20px 0px;
  text-align: center;
  max-width: 70px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: normal !important;
  border-radius: 1px solid black;
}

.data_tb_cs .client-head1 td:first-child {
  border-radius: 20px 0px 0px 20px;
  text-align: center;
  max-width: 70px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: normal !important;
  padding: 12px 0px;
}

.data_tb_cs .client-head1 tr td {
  border: none;
  padding: 12px 15px;
  font-size: 15px;
  color: #121212;
  font-weight: 400 !important;
  max-width: inherit;
  border: 1px solid #576aa4;
  border-left: none;
  border-right: none;
  text-align: center !important;
  max-width: 70px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: normal !important;
}

.data_tb_cs .client-head1 {
  border-collapse: separate;
  border-spacing: 0 15px;
}

.reporting-data-container .client-head1 td {
  border: none !important;
}

.data_tb_cs table {
  border-spacing: 0;
  border-collapse: collapse;
}

.data_tb_cs table th {
  border: none;
  padding: 17px 15px;
  font-size: 15px;
  color: #4b4d5f;
  font-weight: 500 !important;
  max-width: inherit;
  border-left: none;
  border-right: none;
  text-align: center;
  max-width: 200px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.data_tb_cs table th:first-child {
  border-radius: 20px 0px 0px 20px;
  text-align: center;
  max-width: 150px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  padding: 12px 0px;
}

.data_tb_cs table th:last-child {
  border-radius: 0px 20px 20px 0px;
  text-align: center;
  max-width: 150px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.data_tb_cs.client_data-tble tr td:first-child svg {
  background: #ffedcc;
  width: 32px;
  height: 32px;
  padding: 4px;
  border-radius: 30px;
  fill: #555;
}

.data_tb_cs.client_data-tble tr td {
  background: #fff;
  transition: all 0.3s ease-in-out;
}

.data_tb_cs.client_data-tble tr,
.data_tb_cs.user_table_list tr {
  background: #eff1f7;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.data_tb_cs.client_data-tble tr.deal_thead:hover {
  background: #ffffff;
  cursor: auto;
}

.data_tb_cs.client_data-tble .cl_nm_fl {
  background: linear-gradient(89deg, #a6afff 3.24%, #bca5ff 100.47%);
  width: 36px;
  height: 36px;
  padding: 4px;
  font-weight: 500;
  border-radius: 30px;
  color: #ffffff !important;
  font-size: 13px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 9px;
  min-width: 34px;
}

.data_tb_cs .client-head1 tr td:first-child {
  border-left: 1px solid #576aa4 !important;
}

.data_tb_cs .client-head1 tr td:last-child {
  border-right: 1px solid #576aa4 !important;
}

.data_tb_cs .client-head1 tr td .row_data:first-child {
  max-width: 800px;
}

/* table scrollbar css */

.nml-scroll {
  /* margin-top: 32px; */
  margin-bottom: 16px;
  max-height: 400px;
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
  max-width: 100%;
}

.nml-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
  color: #fff;
}

.nml-scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}

.nml-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #6b78e1;
}
.stripe_month_card_picker_con
  .react-datepicker-wrapper
  input.stripe_month_card_picker {
  height: 44px;
}

/* --------------table-------------- */

.data_tb_cs .prod_service_tbl_row tr td {
  border: none;
  padding: 12px 15px;
  font-size: 15px;
  color: #121212;
  font-weight: 400 !important;
  max-width: inherit;
  border: 1px solid #ddd;
  border-left: none;
  border-right: none;
  text-align: center !important;
  max-width: 70px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.data_tb_cs .prod_service_tbl_row td:first-child {
  border-left: 1px solid #ddd;
  border-radius: 20px 0px 0px 20px;
  text-align: center;
  max-width: 70px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  padding: 12px 5px;
}

.data_tb_cs .prod_service_tbl_row td:last-child {
  border-right: 1px solid #ddd;
  border-radius: 0px 20px 20px 0px;
  text-align: center;
  max-width: 70px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  padding: 12px 0px;
}

.data_tb_cs .prod_service_tbl_row {
  border-collapse: separate;
  border-spacing: 0 15px;
}

.data_tb_cs .prod_service_tbl_row td:first-child {
  border-left: 1px solid #ddd;
  border-radius: 20px 0px 0px 20px;
  text-align: center;
  max-width: 70px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  padding: 12px 5px;
}

.data_tb_cs .prod_service_tbl_row td:last-child {
  border-right: 1px solid #ddd;
  padding: 12px 0px;
}

.data_tb_cs .prod_service_tbl_row tr.invc_tbl_head {
  background: #eff1f7;
}

.data_tb_cs .prod_service_tbl_row {
  background: #ffffff;
  cursor: auto;
}

.data_tb_cs.prod_main_tbl {
  background: #ffffff !important;
  padding: 15px 0px;
  /* border-radius: 20px; */
}

.row_data_quote {
  /* overflow: hidden !important; */
  /* text-overflow: ellipsis !important; */
  /* white-space: nowrap !important; */
  word-break: break-word;
}

.data_tb_cs .invc_tbl_row tr td {
  border: none;
  padding: 12px 15px;
  font-size: 15px;
  color: #121212;
  font-weight: 400 !important;
  max-width: inherit;
  border: 1px solid #e0e0e0;
  border-left: none;
  border-right: none;
  text-align: center !important;
  max-width: 70px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.data_tb_cs .invc_tbl_row td:first-child {
  border-left: 1px solid #e0e0e0;
  border-radius: 20px 0px 0px 20px;
  text-align: center;
  max-width: 70px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  padding: 12px 0px;
}

.data_tb_cs .invc_tbl_row td:last-child {
  border-right: 1px solid #e0e0e0;
  border-radius: 0px 20px 20px 0px;
  text-align: center;
  max-width: 70px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  padding: 12px 0px;
}

.data_tb_cs .invc_tbl_row tr.invc_tbl_head:hover {
  background: #eff1f7;
  cursor: auto;
}

.data_tb_cs .invc_tbl_row tr.invc_tbl_head {
  background: #eff1f7;
}

.data_tb_cs .invc_tbl_row {
  border-collapse: separate;
  border-spacing: 0 15px;
}
.myProposal__tableWithoutAction::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}
.myProposal_tabs {
  background-color: white;
  width: fit-content;
  padding: 0.25rem 0.25rem;
  border-radius: 1rem;
}

.sales-tab-active {
  color: #fff;
  background-color: #6b77e1;
}

.togg_btn_tab {
  border-radius: 20px;
  width: 100%;
  max-width: 310px;
  background: #fff;
  padding: 5px;
}

.profile-avatar {
  color: #fff;
  border-radius: 30px;
  background: var(
    --Gradient,
    linear-gradient(88deg, #a6afff 3.24%, #bca5ff 100.47%)
  );
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
}

.qou_opt_items {
  color: #6b77e1;
  text-transform: capitalize;
  font-size: 12px;
  background: #f5f8ff;
  padding: 4px 6px;
  font-weight: 500;
  position: relative;
  border-radius: 10px;
}

.download_p {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-right: 16px;
}

.notify_settings_header .MuiBox-root > button.MuiButton-root.Mui-disabled {
  border: none !important;
}

.back_withoutBtn {
  font-size: 18px;
  display: flex;
  text-decoration: underline;
  color: #000 !important;
  font-weight: 500;
  cursor: pointer;
  align-items: center;
}
.txt-field-err-sp .MuiFormHelperText-root {
  bottom: -10px;
}
.hw-task_description {
  word-break: break-word;
  padding: 10px 8px;
  font-size: 12px;
  border: 1px solid #e4daff;
  border-radius: 8px;
  line-height: 18px;
  white-space: pre-line;
}

.hw-task_description .hw-readMore {
  color: #7349e6;
  text-decoration-line: underline;
  text-decoration-color: #b195ff;
  cursor: pointer;
}
.see_all_rct_act {
  text-decoration: underline !important;
  width: 100%;
  padding: 0px !important;
  border: none !important;
  background-color: #fff;
}

.invite_contractor_by_link {
  color: '#A5A6AF';
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-bottom: 8px;
}
.tble_hire_button {
  border-radius: 14px !important;
  min-width: 60px !important;
  padding: 10px 18px;
  font-size: 16px;
  background-color: #6b77e1 !important;
}
/* ----------Responsive css---------------------------------- */

.hw-wrap-main-cs-ctr {
  flex-direction: row;
  height: 100vh;
  overflow: hidden;
}

.hw-wrap-main-cs-ctr main {
  min-height: 100%;
  overflow: auto;
  height: auto;
}

/* responsive css */

.hw-responsive_bar header.MuiPaper-root.mui-fixed {
  width: 100% !important;
  margin-left: 0;
  right: 0;
  margin-top: 0px;
  border-radius: 0 !important;
}

.hw-responsive_bar
  header.MuiPaper-root.mui-fixed
  button.MuiButtonBase-root[aria-label='open drawer'] {
  left: 23px;
  top: 12px;
  padding: 0px;
  background: none !important;
  box-shadow: none !important;
}
.hw-responsive_bar .resSidebar .appbar_header_title {
  width: 100%;
  text-align: center;
}
.hw-responsive_bar .side-bar_main_homeOwner li span {
  font-size: 14px;
}
.hw-responsive_bar .side-bar_main_homeOwner img,
.hw-responsive_bar .tgle-bar_main_homeOwner img {
  width: 20px !important;
}

.hw-menu-close-icon {
  position: absolute;
  top: 15px;
  right: 20px;
  z-index: 99999;
  filter: brightness(0) invert(1);
  width: 20px;
  height: 20px;
}

button.hw-notify_bell_btn_res {
  background: none !important;
  border-radius: 4px;
  width: 100%;
  max-width: 40px;
  margin-right: 5px !important;
  margin-top: 5px !important;
  /* padding: 6px 6px 0px 0px; */
}

button.MuiIconButton-root.hw-notify_bell_btn_res:hover {
  background: #e4dbfd;
}

button.hw-notify_bell_btn_res button.MuiButtonBase-root span.MuiBadge-badge {
  font-size: 10px;
  min-width: 22px;
  padding: 0 4px;
  height: 20px;
  color: #fff;
  top: 3px;
  right: 8px;
  background: #6b77e1;
  border-radius: 100%;
}

.hw-res_bell_icon {
  width: 30px;
}
button.hw-res_bell_icon button.MuiButtonBase-root span.MuiBadge-badge {
  top: 3px !important;
}
.hw-expandIcon svg {
  margin-left: 0px !important;
}
.res-editProjectIcon {
  height: 28px;
  width: 28px;
  color: #a5a6af;
}

.res-deleteProjectIcon {
  border: 0px;
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.res-scroll {
  /* margin-top: 32px;
  margin-bottom: 16px; */
  max-height: 100%;
  overflow: auto;
  overflow-y: auto;
  width: 100%;
  max-width: 100%;
}

.res-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
  color: #fff;
}

.res-scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}

.res-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #6b78e1;
}

/*-----addtional-css-18-09-2024----*/

/* .data_tb_cs.prod_main_tbl.manage_leads_cs {
  padding: 0;
  border-radius: 0;
}


.data_tb_cs.manage_leads_cs .prod_service_tbl_row td:last-child {
  padding: 12px 10px;
}

.data_tb_cs.manage_leads_cs .prod_service_tbl_row td:first-child{
  padding: 12px 10px;
}

.data_tb_cs.manage_leads_cs .prod_service_tbl_row tr td {
  overflow: visible !important;
  white-space: normal !important;
  max-width: inherit !important;
  min-width: 50px;
  padding: 12px 10px;
}

.data_tb_cs.manage_leads_cstable th {
  padding: 10px 10px;
}
@media only screen and (max-width:767px){
  button.notify_bell_btn_res button.MuiButtonBase-root span.MuiBadge-badge {
    min-width: 20px;
  }

  .data_tb_cs.manage_leads_cs .prod_service_tbl_row tr td {
  min-width: 150px;
  padding: 8px 6px;
}

.data_tb_cs.manage_leads_cs .prod_service_tbl_row td:first-child {
  padding: 8px 6px;
  min-width: 40px;
}

.data_tb_cs.manage_leads_cs .prod_service_tbl_row td:last-child {
  padding: 8px 6px;
}

.data_tb_cs.manage_leads_cs table th {
  padding: 8px 6px 0 6px;
}

}

@media only screen and (max-width:1199px){

.data_tb_cs.prod_main_tbl.manage_leads_cs .MuiBox-root {
  overflow: auto;
}

} */
.res_scroll_tbl {
  overflow-y: auto;
}
.res_scroll_tbl::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
  height: 8px; /* Set the height for horizontal scrollbar */
}

.res_scroll_tbl::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

/* ----------Responsive css Max Width 1249---------------------------------- */

@media only screen and (max-width: 1249px) {
  .compare_proposal_parent_headerTitle.res_compare_proposal_parent_headerTitle
    .compare_proposal_parent_headerTitle {
    width: calc(100% - 175px);
    /* gap: 15px; */
    justify-content: flex-start;
    padding-right: 10px;
  }
  .compare_proposal_parent_headerTitle.res_compare_proposal_parent_headerTitle
    .compare_proposal_parent_headerTitle
    img {
    min-width: 60px;
  }
  .compare_proposal_parent_headerTitle.res_compare_proposal_parent_headerTitle
    .compare_proposal_parent_headerTitle
    .MuiBox-root:last-child {
    width: 100%;
  }

  .compare_proposal_parent_headerTitle.res_compare_proposal_parent_headerTitle
    button.MuiButtonBase-root {
    width: 137px;
  }
  .compare_heading_text {
    font-size: 20px !important;
    line-height: 1.3 !important;
    margin-bottom: 5px;
  }

  .questionBtns {
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .questionBtns button.MuiButtonBase-root {
    font-size: 14px;
  }

  .compare_subheading_text {
    height: auto;
  }

  .ava_chat_bubble,
  .user_chat_bubble {
    font-size: 13px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

/* ----------Responsive css Max Width 991---------------------------------- */

@media only screen and (max-width: 991px) {
  .questionBtns button.MuiButtonBase-root {
    font-size: 11px;
  }

  .compare_proposal_parent {
    padding: 14px 15px;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .questionBtns {
    gap: 10px;
  }

  .compare_subheading_text {
    font-size: 13px;
  }

  .data_tb_cs.prod_main_tbl {
    overflow: auto;
  }
}
.data_tb_cs.prod_main_tbl::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
  height: 8px; /* Set the height for horizontal scrollbar */
}

::selection {
  background: #4b4d5f !important;
  color: #fff;
}

/* ----------Responsive css Max Width 767---------------------------------- */

@media only screen and (max-width: 767px) {
  .getProposal_container {
    margin: 0;
    margin-top: 0;
  }
  .proposal_details_section {
    padding: 20px 15px;
  }

  .project_info_received .MuiBox-root:last-child {
    flex: 1;
    min-width: inherit;
  }

  .project_info_received .MuiBox-root {
    width: auto;
    min-width: 85px;
  }
  .data_tb_cs.prod_main_tbl {
    overflow: auto;
  }
  .acpt-section_out_cs {
    padding: 15px 15px;
    border-radius: 4px;
    background: #fff;
  }
  .data_tb_cs table th {
    padding: 12px 10px;
    font-size: 12px;
  }

  .data_tb_cs .prod_service_tbl_row {
    border-spacing: 0 10px;
  }

  .data_tb_cs .prod_service_tbl_row td:first-child {
    padding: 10px 10px;
  }
  .data_tb_cs .prod_service_tbl_row tr td {
    padding: 10px 10px;
    font-size: 12px;
  }

  .data_tb_cs .prod_service_tbl_row td:last-child {
    padding: 10px 10px;
  }

  .data_tb_cs .prod_service_tbl_row td p {
    font-size: 13px;
    color: #121212;
  }

  .data_tb_cs table th:first-child {
    padding: 12px 10px;
  }

  .data_tb_cs .prod_service_tbl_row td:last-child {
    overflow: visible !important;
    max-width: inherit !important;
  }
  .data_tb_cs.client_data-tble tr td {
    font-size: 12px;
    padding: 6px 8px;
  }
  .data_tb_cs.client_data-tble tr td p {
    font-size: 12px;
  }
  .compare_proposal_parent_headerTitle.res_compare_proposal_parent_headerTitle
    .compare_proposal_parent_headerTitle
    .compare_heading_text {
    font-size: 15px !important;
    line-height: 1.3 !important;
    margin-bottom: 5px;
  }

  .compare_proposal_parent_headerTitle.res_compare_proposal_parent_headerTitle {
    flex-direction: column;
    align-items: flex-end;
    /* justify-content: flex-end; */
  }

  .compare_proposal_parent_headerTitle.res_compare_proposal_parent_headerTitle
    .compare_proposal_parent_headerTitle {
    width: 100%;
    gap: 10px;
    justify-content: flex-start;
    padding-right: 0;
  }

  .compare_proposal_parent_headerTitle.res_compare_proposal_parent_headerTitle
    .compare_proposal_parent_headerTitle
    img {
    min-width: 40px;
  }

  .compare_proposal_parent_headerTitle.res_compare_proposal_parent_headerTitle
    button.MuiButtonBase-root {
    width: 117px;
    margin-top: 0px;
    /* float: right; */
  }

  .questionBtns {
    margin-left: 0 !important;
    padding-top: 0;
    padding-bottom: 15px;
  }

  .res_conversation {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .ava_chat_bubble,
  .user_chat_bubble {
    font-size: 12px;
    line-height: 1.3;
  }
  .res_conversation .ava_avatar .MuiAvatar-root.profile-avatar {
    font-size: 14px;
  }

  .res_conversation .ava_avatar {
    min-width: 32px;
  }

  .compare_subheading_text {
    margin-bottom: 10px;
  }

  .MuiPaper-root.MuiPaper-elevation1.MuiTableContainer-root.res_scroll_tbl {
    border-radius: 10px !important;
    box-shadow: none !important;
    border: 1px solid #ccc;
  }

  .chat_action_input .MuiInputAdornment-root img {
    max-width: 22px !important;
    min-width: 22px !important;
  }
  .res_projectInfo {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: start;
  }
  .res_projectStatus_actions {
    flex-wrap: wrap;
    white-space: nowrap;
    justify-content: start;
  }
  .tble_hire_button {
    min-width: 75px !important;
    padding: 6px 12px;
    place-self: center;
    font-size: 13px !important;
  }
  ::selection {
    background: red;
  }
  .res_d-flex-2 {
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
  }
  .res_d-flex-2 button {
    margin: 0;
  }
}

.productName_data{
  padding: 0 20px;
  word-break: break-word !important;
  text-overflow: unset;
  white-space: normal;
}

/* ----------Responsive css Max Width 600---------------------------------- */

@media only screen and (max-width: 600px) {
  .propos-sub_totl_cs p {
    font-size: 12px;
  }

  .res_declineBtn {
    background: #fff3f8 !important;
    color: red !important;
    width: 12% !important;
    text-transform: capitalize !important;
    min-width: 100px !important;
    padding: 10px 18px !important;
    font-size: 13px !important;
  }
  .compare_chat_connatiner {
    overflow: hidden;
  }
  .res_conversation {
    font-size: 13px !important;
  }
  .res_conversation .ava_avatar {
    width: 2rem;
    height: 2rem;
  }
  .res_checkboxIcon_img {
    border-radius: 0px;
  }
  .res_qstnBtns {
    font-size: 13px !important;
    overflow: hidden;
    min-width: 200px;
    width: 100%;
  }
  .res_confrim_logout {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .invite_contractor_by_link {
    font-size: 13px;
    flex-direction: column;
    align-items: start;
    overflow: auto;
  }
  .invite_contractor_by_link::-webkit-scrollbar {
    width: 8px; /* Set the width of the scrollbar */
    height: 8px; /* Set the height for horizontal scrollbar */
  }
  .res_stepperContainer {
    overflow-x: scroll;
    overflow-y: auto;
    padding-bottom: 10px;
  }
  .res_stepperContainer::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  /* ----------Accespt proposal header---------------------------------- */
  .acpt-header {
    flex-direction: column;
  }
  .acpt-header .acpt-title {
    padding: 5px 20px;
  }
  .acpt-header .acpt-title .col-add-ic {
    display: flex;
    width: 100%;
    gap: 8px;
  }
  .acpt-header-title + div {
    min-width: 50%;
  }
  .acpt-header .acpt-title img {
    width: 15px;
  }
  .acpt-card-text .title-acpt .res_acpt-text::-webkit-scrollbar {
    width: 8px; /* Set the width of the scrollbar */
    height: 8px; /* Set the height for horizontal scrollbar */
  }
  .acpt-card-text .title-acpt .res_acpt-text p {
    font-size: 13px;
  }
  .acpt-card-text .title-acpt .res_acpt-txt::-webkit-scrollbar {
    width: 8px; /* Set the width of the scrollbar */
    height: 8px; /* Set the height for horizontal scrollbar */
  }
  .acpt-card-text .title-acpt .res_acpt-txt p {
    font-size: 13px;
  }
  .res_title-acpt {
    overflow-x: auto;
  }
  .res_title-acpt::-webkit-scrollbar {
    width: 8px; /* Set the width of the scrollbar */
    height: 8px; /* Set the height for horizontal scrollbar */
  }
  .signature-canvas {
    height: 200px;
  }
  .project_search .MuiInputBase-root input[type='text'] {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    padding-right: 8px !important;
    font-size: 13px;
    font-weight: 400;
    color: #000;
  }
  .project_search svg {
    width: 18px;
  }
  div#menu-appbar ul.MuiList-root li .MuiBox-root {
    margin-left: 0;
  }

  div#menu-appbar ul.MuiList-root li {
    height: auto;
    min-height: inherit;
  }
  .acpt-card-text .title-acpt .acpt-txt {
    margin: 0;
    font-size: 13px;
    padding: 10px 20px;
  }
  .back_withoutBtn svg {
    width: 20px;
  }
  .clinet_search_bx .MuiInputBase-input {
    font-size: 13px;
  }
  .clinet_search_bx .MuiSvgIcon-root {
    width: 20px;
  }
  .bookingForm_checkbox_parent::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .bookingForm_checkbox_con label {
    font-size: 13px;
  }
  .productName_data{
    padding: 0 10px;
  }
}

@media only screen and (max-width: 1450px) {
  .list-proposal-right-panel
    .MuiGrid-root.MuiGrid-container
    .MuiGrid-item.MuiGrid-grid-sm-6.MuiGrid-grid-md-4 {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .myProposal__tableWithoutAction .prod_service_tbl_row td:last-child > div {
    display: flex;
    flex-direction: column;
  }
  .bookingForm_checkbox_parent {
    flex-wrap: wrap;
  }
  .bookingForm_checkbox_parent > .bookingForm_checkbox_con {
    width: 49%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .bookingForm_checkbox_parent > .bookingForm_checkbox_con > label {
    display: block;
  }
}

@media only screen and (max-width: 1280px) {
  .list-proposal-right-panel
    .project_search
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-lg-9.search_grid {
    -webkit-flex-basis: 60%;
    -ms-flex-preferred-size: 60%;
    flex-basis: 60%;
    max-width: 60%;
    padding-right: 15px;
    padding-top: 16px;
  }

  .list-proposal-right-panel
    .project_search
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-lg-3 {
    -webkit-flex-basis: 40%;
    -ms-flex-preferred-size: 40%;
    flex-basis: 40%;
    max-width: 40%;
  }
}

@media only screen and (max-width: 1199px) {
  .list-proposal-right-panel {
    padding-top: 0;
    margin: 16px 0;
  }

  .list-proposal-right-panel
    .project_search
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-lg-9.search_grid {
    -webkit-flex-basis: 55%;
    -ms-flex-preferred-size: 55%;
    flex-basis: 55%;
    max-width: 55%;
  }

  .list-proposal-right-panel
    .project_search
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-lg-3 {
    -webkit-flex-basis: 45%;
    -ms-flex-preferred-size: 45%;
    flex-basis: 45%;
    max-width: 45%;
  }
  .accpt_card_pro_right_cont {
    padding-left: 10px;
    padding-top: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .list-proposal-right-panel
    .MuiGrid-root.MuiGrid-container
    .MuiGrid-item.MuiGrid-grid-sm-6.MuiGrid-grid-md-4 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
    padding-top: 0;
  }
  .list-proposal-right-panel
    .project_search
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-lg-9.search_grid {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
    padding-right: 0;
  }

  .list-proposal-right-panel
    .project_search
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-lg-3 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
    justify-content: center;
    margin-top: 20px;
  }

  .list-proposal-right-panel
    .project_search
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-lg-3
    .myProposal_tabs {
    width: 100%;
  }

  .list-proposal-right-panel
    .project_search
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-lg-3
    .myProposal_tabs
    .togg_btn_tab {
    max-width: 100%;
  }

  .list-proposal-right-panel
    .project_search
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-lg-3
    .myProposal_tabs
    .togg_btn_tab
    .d-flex.c-pointer {
    width: 50%;
  }

  .client-accepted-card,
  .client-sent-card,
  .client-rejected-card,
  .accept-new-card {
    margin: 10px 0px;
    padding: 10px;
  }
  .mar-img-cs {
    /* height: 100%; */
    max-width: 125px;
    min-width: 125px;
    min-height: 125px;
    max-height: 125px;
  }

  .accpt_card_pro_right_cont {
    padding-left: 2px;
    padding-top: 0;
    margin-top: 0 !important;
  }

  .accept-card-name {
    font-size: 14px;
  }

  .from-now {
    font-size: 11px !important;
    margin: 0 !important;
  }
  .accept-card-value {
    color: #000;
    font-size: 13px;
    margin: 2px 0;
  }
  .project_search .MuiInputBase-root input[type='text'] {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    font-family: 'Poppins';
    padding-left: calc(1em + 27px);
  }

  .myProposal__tableWithoutAction
    .prod_service_tbl_row
    td:last-child
    > div
    .MuiButton-root {
    font-size: 13px;
  }
  .accept-new-card > div > .accpt_card_pro_right_cont,
  .client-accepted-card > div > .accpt_card_pro_right_cont {
    max-width: calc(100% - 125px);
  }
  .bookingForm_checkbox_parent {
    flex-direction: column;
    align-items: flex-start;
  }
  .bookingForm_checkbox_parent .bookingForm_checkbox_con {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .bookingForm_checkbox_parent .bookingForm_checkbox_con label {
    display: block;
  }
  .myProposal__tableWithoutAction table tr>td:not(:first-child){
    min-width: 120px;
  }
  .myProposal__tableWithoutAction table tr>td:first-child{
    min-width: 50px;
  }
}
@media only screen and (max-width: 500px) {
  p.all_req_desc {
    max-width: 200px;
  }
}

/* -------------------Toast CSS--------------- */
.toast-success {
  color: #4a9e5c;
  min-height: 50px;
  border-radius: 10px;
  border: 1px solid #a5a6af;
  background: #ffffff;
  font-weight: 500;
  font-size: 13px;
}

.toast-error {
  background: #ffffff;
  color: #e80606;
  min-height: 50px;
  border-radius: 10px;
  border: 1px solid #a5a6af;
  font-weight: 500;
  font-size: 13px;
}

.toast-warning {
  background: #ffffff;
  color: #e80606;
  min-height: 50px;
  border-radius: 10px;
  border: 1px solid #a5a6af;
  font-weight: 500;
  font-size: 13px;
}

.toast-info {
  background: #ffffff;
  color: #6b77e1;
  min-height: 50px;
  border-radius: 10px;
  border: 1px solid #a5a6af;
  font-weight: 500;
  font-size: 13px;
}
@media only screen and (max-width: 1400px) {
  .list-proposal-right-panel .search_grid > div,
  .list-proposal-right-panel .search_grid > div > div:last-child {
    max-width: 500px;
  }
  .sales_list_tb table tr > td:nth-child(2),
  .myProposal__tableWithoutAction table tr > td:nth-child(3) {
    min-width: 300px;
  }
  .myProposal__tableWithoutAction table {
    min-width: 900px;
  }
  .myProposal__tableWithoutAction {
    overflow: auto !important;
  }
}

@media only screen and (max-width: 1200px) {
  .myProjects__container {
    padding-top: 12px;
  }
}

@media only screen and (max-width: 900px) {
  .log_out_pop_Up .MuiDialog-container .MuiPaper-rounded {
    border-radius: 0px;
  }
  .myProjects__container .search_grid > div,
  .myProjects__container .search_grid > div > div:last-child,
  .list-proposal-right-panel .search_grid > div,
  .list-proposal-right-panel .search_grid > div > div:last-child {
    max-width: 100%;
  }
  .myProposal_tabs_grid {
    justify-content: center !important;
    margin-top: 16px !important;
  }
  .myProposal_tabs_grid .myProposal_tabs {
    width: 60%;
  }
  .myProposal_tabs_grid .myProposal_tabs .togg_btn_tab {
    max-width: 100%;
  }
  .myProposal_tabs_grid .myProposal_tabs .togg_btn_tab > div {
    width: 50%;
  }
  .res_confrim_logout {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .bookingForm_checkbox_parent > .bookingForm_checkbox_con {
    width: 45%;
  }
}

@media only screen and (max-width: 599px) {
  .login__rightPanel,
  .register__rightPanel {
    width: 100%;
    top: 0 !important;
  }
  .login__rightPanel > div,
  .register__rightPanel > div {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 0px;
  }
  .MuiDialog-root
    .MuiDialog-container
    .MuiPaper-rounded
    .MuiDialogContent-root {
    padding: 8px !important;
  }
  .MuiDialog-root .MuiDialog-container .MuiPaper-rounded .MuiDialogTitle-root {
    padding-left: 8px !important;
  }

  .register__rightPanel > div > .MuiGrid-container {
    height: 85% !important;
  }
  .register__myAutocomplete--singleSelect img {
    width: 18px;
  }
  .register__myAutocomplete--singleSelect .MuiAutocomplete-input,
  .MuiAutocomplete-option {
    font-size: 13px;
  }
  .project_main_container {
    padding: 0px;
  }
  .list-proposal-right-panel {
    margin-top: 0px;
  }
  .invoice-banner {
    padding: 0px !important;
    margin: 0px;
  }

  .client-view-appt-form {
    margin: 0px !important;
  }
  .compare_selector_subheading {
    width: 100%;
  }
  .acc-pro-full-cotain {
    margin-inline: 0px;
  }
  .client-view-appt-form {
    padding: 16px !important;
  }
  .profileContainer {
    padding: 0px !important;
  }
  .compare_selector_btn {
    padding: 0px;
  }
  .log_out_pop_Up .MuiButton-root {
    min-width: 100px;
  }
  .accept-proposal-right-panel {
    height: auto;
  }
  .myProposal_tabs_grid .myProposal_tabs {
    width: 100%;
  }
  .myProposal__tableWithoutAction table {
    min-width: 600px;
  }
  .myProposal__tableWithoutAction table tr > td:nth-child(2) {
    min-width: 200px;
  }
  .phoneInput__fs > div > .react-tel-input > input{
    font-size: 13px !important;
    height: 40px !important;
  }
}

@media only screen and (max-width: 400px) {
  .popup__btns--400 {
    flex-direction: column-reverse;
    gap: 8px;
  }
}

.uploaded_pdf_showBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hw-readMore {
  color: #7349e6 !important;
  text-decoration-line: underline !important;
  text-decoration-color: #b195ff !important;
  font-weight: 400;
  font-size: 13px;
  cursor: pointer;
}

.error-border {
  border: 1px solid red !important; /* Ensures the red border overrides default styles */
  border-radius: 20px;
}

.error-border-container {
  border: 1px solid red !important; /* Style the dropdown container if necessary */
  border-radius: 20px;
}

.stripe_payment_form {
  border: 1px solid #e4dbfd;
  box-shadow: none !important;
  border-radius: 10px;
  background: #fff !important;
  padding: 30px;
  margin: 10px 0px 0px 10px;
}

.drag-drop-thumbnail-res {
  object-fit: cover;
  padding: 4px;
  width: 100%;
  height: 150px;
  max-width: 150px;
  display: block;
}

@media screen and (max-width: 576px) {
  .registerPage__container > .MuiGrid-item {
    padding: 34px 0 0 0;
    height: 100%;
  }
  .registerPage__container,
  .loginPage__container {
    height: calc(
      100vh - env(safe-area-inset-bottom, 80px)
    ) !important; /* Fallback for older browsers */
    height: 100dvh !important; /* Dynamic Viewport Height */
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 480px) {
  .loginPage__container > .MuiGrid-item {
    padding-bottom: 0;
    padding-top: 32px;
    height: 100%;
  }
  .register__rightPanel > div {
    width: 100%;
  }
  .publicLayout__rightComponent {
    margin-inline: 16px;
  }
}

.prod_description {
  width: 100%;
  word-break: break-word;
  padding: 10px 8px;
  font-size: 14px;
  line-height: 18px;
  white-space: pre-line;
  color: #000;
}

.prod_description .readMore {
  color: #6b77e1;
  text-decoration-line: underline;
  text-decoration-color: #b195ff;
  padding: 20px !important;
}
.notify_bar_menu {
  padding: 8px 16px !important;
}
.lisiting_header > .infinite-scroll-component__outerdiv >.infinite-scroll-component{
  margin-bottom: 28px;
}
.invProductInfoTbl table tr>th{
  min-width: 100px;
}
.invProductInfoTbl table tr>td{
  min-width: 100px;
}
.invProductInfoTbl table tr>td:first-child{
  min-width: 150px;
}
.invProductInfoTbl table tr>td:nth-child(2){
  min-width: 200px;
}
.lisiting_header > .infinite-scroll-component__outerdiv >.infinite-scroll-component>div > .notify_list_content_head{
  width: calc(100% - 44px);
}
.lisiting_header > .infinite-scroll-component__outerdiv >.infinite-scroll-component>div > div:last-child p{
  max-width: 100% !important;
}
@media screen and (max-width: 1535px) {
  .invProductInfoTbl table tr>th{
    min-width: 150px;
  }
  .invProductInfoTbl table tr>td{
    min-width: 150px;
  }
  .invProductInfoTbl table tr>td:first-child{
    min-width: 200px;
  }
  .invProductInfoTbl table tr>td:nth-child(2){
    min-width: 300px;
  }
}
.apptForm__datePicker > div:first-child p{
white-space: normal;
}

@media screen and (max-width:600px){
  .MuiAutocomplete-listbox .MuiAutocomplete-option {
    min-height: 30px !important;
    font-size: 13px !important;
}
.customAutocomplete .MuiAutocomplete-root .MuiInputBase-root{
  padding: 6px 9px;
}
.list-proposal-right-panel{
  height: unset ;
  overflow-y: unset;
}
.lisiting_header > .infinite-scroll-component__outerdiv >.infinite-scroll-component>div > div:last-child p {
  white-space: normal;
}
.inviteType_options > label{
  margin-right: 0;
  margin-left: 0;
}
.bookingForm_checkbox_parent > .bookingForm_checkbox_con {
  width: 100%;
}
.bookingForm_checkbox_parent {
  gap: 8px;
}
.appt-req-back-btn > button{
  padding-top: 0px;
}
.acptPrpsl__backBtn > button{
  padding: 0px;
}
.viewProject__backBtn > button{
  padding: 0px 0px 8px 0px;
  justify-content: start;
}
}
@media screen and (max-width:450px){
.hw-resSidebar > div > div .MuiTypography-root{
  max-width: 200px;
}
}
/* ------------helper classes css start---------------*/
.cap_first_letter{
  text-transform: lowercase;
}
.cap_first_letter::first-letter{
  text-transform: capitalize;
}
.text-capital{
  text-transform: capitalize !important;
}
.word-break{
  word-break: break-word;
}
.table__wordBreak table tr>td{
  word-break: break-word;
}
.table__resFontSize table tr > th,
.table__resFontSize table tr > th p,
.table__resFontSize table tr > th div,
.table__resFontSize table tr > th span {
  font-size: 15px !important;
}
.table__resFontSize table tr > td,
.table__resFontSize table tr > td p,
.table__resFontSize table tr > td div,
.table__resFontSize table tr > td span {
  font-size: 15px !important;
}
@media only screen and (max-width: 1750px) {
  .table__resFontSize table tr > th,
  .table__resFontSize table tr > th p,
  .table__resFontSize table tr > th div,
  .table__resFontSize table tr > th span,
  .table__resFontSize table tr > th button {
    font-size: 14px !important;
  }
  .table__resFontSize table tr > td,
  .table__resFontSize table tr > td p,
  .table__resFontSize table tr > td div,
  .table__resFontSize table tr > td span,
  .table__resFontSize table tr > td button {
    font-size: 14px !important;
  }
}
@media only screen and (max-width: 767px) {
  .table__resFontSize table tr > th,
  .table__resFontSize table tr > th p,
  .table__resFontSize table tr > th div,
  .table__resFontSize table tr > th span {
    font-size: 13px !important;
  }
  .table__resFontSize table tr > td,
  .table__resFontSize table tr > td p,
  .table__resFontSize table tr > td div,
  .table__resFontSize table tr > td span {
    font-size: 13px !important;
  }
}

/* ------------helper classes css end---------------*/
.myProposal__tableWithoutAction table tr>td:nth-child(2) div,
.invProductInfoTbl table tr>td:first-child div{
  text-transform: lowercase;
}
.myProposal__tableWithoutAction table tr>td:nth-child(2) div::first-letter,
.invProductInfoTbl table tr>td:first-child div::first-letter{
  text-transform: capitalize;
} 

.accpt_card_pro_right_cont{
  width: calc(100% - 205px) !important;
}

.accpt_card_pro_right_cont > div:nth-child(2) > p{
padding-right: 16px;
}

@media only screen and (max-width: 1750px){
  .accpt_card_pro_right_cont{
    width: calc(100% - 175px) !important;
    }
}

.acpt-header .acpt-title{
  width: 33.33%;
}

@media only screen and (max-width: 767px){
  .accpt_card_pro_right_cont{
    width: calc(100% - 145px) !important;
    }
    .acpt-header{
      flex-direction: column;
    }
    .acpt-header .acpt-title{
      width: 100%;
    }
    .acpt-header .acpt-title .col-add-ic{
      flex-direction: column;
      gap: 4px;
      width: 90% !important;
    }
}

.projectStatus_actions svg{
  cursor: pointer;
}
@media only screen and (max-width: 600px){
  .bookingForm_checkbox_parent{
    gap: 0px;
  }
  .bookingForm_checkbox_parent > .bookingForm_checkbox_con{
    margin-top: 0px;
  }
}


.myProposal__tableWithoutAction table tr>td:nth-child(3){
  word-break: break-word;
}
.invProductInfoTbl table tr>td:nth-child(2){
  word-break: break-word;
}
/* ----------------accept proposal optional item button css start---------------------------- */
.myProposal__tableWithoutAction .optionalItem__Box{
  flex-wrap: wrap;
  justify-content: center !important;
}
.myProposal__tableWithoutAction .optionalItem__Box > p{
  padding-inline: 8px;
  max-width: 100%;
}
.myProposal__tableWithoutAction .optionalItem__Box .addOptionalItem__button{
  min-width: 75px;
  padding: 4px 8px;
}
.myProposal__tableWithoutAction .optionalItem__Box .delOptionalItem__button{
  min-width: 90px;
  padding: 4px 8px;
}
.myProposal__tableWithoutAction table tr>td:last-child{
  min-width: 150px !important;
}
/* ----------------accept proposal optional item button css end---------------------------- */
/* ----------------invoice section css start---------------------------- */
.paymtReq__buttons{
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  align-items: center;
}
.paymtReq__buttons > div{
  padding: 0;
  width: fit-content;
}
.paymtReq__buttons > div button{
  max-height: 55px;
}
@media only screen and (max-width: 1535px){
  .pmtRqtDetails__box .invc-req-amount{
    padding: 8px;
  }
  .pmtRqtDetails__box .invc-req-amount p{
    font-size: 13px;
  }
  .pmtRqtDetails__box .invc-req-amount img,
  .paymtReq__buttons button img{
    width: 24px;
  }
  .pmtRqtDetails__box .invc-req-amount>div{
    margin-left: 4px;
  }
  .paymtReq__buttons > div:first-child button{
    min-width: 150px !important;
  }
  .paymtReq__buttons > div:last-child button{
    min-width: 210px !important;
  }
  .paymtReq__buttons button{
    font-size: 13px;
  }
}
@media only screen and (max-width: 600px){
  .pmtRqtDetails__box .invc-req-amount>div{
    margin-left: 12px;
  }
}

@media only screen and (max-width: 500px){
  .paymtReq__buttons{
    flex-direction: column !important;
  }
  .paymtReq__buttons > div{
    width: 100%;
  }
}
/* ----------------invoice section css end---------------------------- */

/* --------------------ACCEPT PROPOSAL PRODUCT DESCRIPTION TABLE CSS------------  */

.data_tb_cs.myProposal__tableWithoutAction .prod_service_tbl_row tr td:nth-of-type(3) .accpt_prop_descp__box {
  min-width: inherit !important;
  overflow: visible !important;
  text-overflow: inherit !important;
  text-align: left;
}

.data_tb_cs.myProposal__tableWithoutAction .prod_service_tbl_row tr td ul {
  padding-left: 20px;
}

.data_tb_cs.myProposal__tableWithoutAction .prod_service_tbl_row tr td ol {
  padding-left: 20px;
}

.data_tb_cs.myProposal__tableWithoutAction .prod_service_tbl_row tr td:nth-of-type(3) .accpt_prop_descp__box img {
  display: block;
}

@media only screen and (max-width:767px){
  .data_tb_cs.myProposal__tableWithoutAction .prod_service_tbl_row tr th {
    max-width: inherit !important;
    min-width: inherit;
}
.data_tb_cs.myProposal__tableWithoutAction .prod_service_tbl_row tr td {
  max-width: inherit !important;
}
}

/* --------------------ACCEPT PROPOSAL PRODUCT DESCRIPTION TABLE CSS------------  */


/* --------------------EDIT INVOICE PRODUCT DESCRIPTION TABLE CSS------------  */

.data_tb_cs.sales_list_tb .client-head1 tr td:nth-of-type(3) .accpt_prop_descp__box {
  min-width: inherit !important;
  overflow: visible !important;
  text-overflow: inherit !important;
  text-align: left;
}

.data_tb_cs.sales_list_tb .client-head1 tr td ul {
  padding-left: 20px;
}

.data_tb_cs.sales_list_tb .client-head1 tr td ol {
  padding-left: 20px;
}

.data_tb_cs.sales_list_tb .client-head1 tr td:nth-of-type(3) .accpt_prop_descp__box img {
  display: block;
}

@media only screen and (max-width:767px){
  .data_tb_cs.sales_list_tb .client-head1 tr th {
    max-width: inherit !important;
    min-width: inherit;
}
.data_tb_cs.sales_list_tb .client-head1 tr td {
  max-width: inherit !important;
}
}

/* --------------------EDIT INVOICE PRODUCT DESCRIPTION TABLE CSS------------  */


/* -------------------- invoice product info  TABLE CSS start------------  */

      .invProductInfoTbl .client-head1 tr td:nth-child(2){
        text-align: left !important ;
    }
      .invProductInfoTbl .client-head1 tr th:nth-child(2){
        text-align: left !important ;
    }

/* -------------------- invoice product info  TABLE CSS end------------  */



/* -------------------- homeowner myProposal__tableWithoutAction  TABLE CSS start------------  */

   .myProposal__tableWithoutAction .prod_service_tbl_row tr th:nth-child(3){
    text-align: left !important ;
   }

/* -------------------- homeowner myProposal__tableWithoutAction  TABLE CSS start------------  */


